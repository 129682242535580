import { Container, Col } from "react-bootstrap";
import "./visionStatement.css";

const VisionStatement = () => {
  return (
    <section className="visionStatement">
      <Container className="visionStatementRow">
        <Col>
          <h3>
            Simplifying Investment for{" "}
            <span className="highlightedText">NRI</span>
          </h3>
          <Col>
            <img
              className={`nriInvestImage`}
              src="./images/nriInvest.png"
              alt="NRI Investment"
            />
          </Col>
        </Col>
        <Col>
          <Col className="visionStatementContent">
            At Ambassador, our mission is clear: to empower overseas Indians to
            invest in their motherland with the same ease and efficiency as
            residents in India. We offer a robust platform designed to take the
            complexity out of the financial system, allowing NRIs and OCIs to
            diversify their portfolios and maximize their returns.
          </Col>
          <Col className="my-4">
            <Col className="arrowRightSvg  mb-5">
              <a href="/about-us" className="cta">
                <span>Know more about us</span>
                <svg width="13px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </a>
            </Col>
          </Col>
        </Col>
      </Container>
    </section>
  );
};

export default VisionStatement;
