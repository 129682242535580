import AmInvestCalculatorList from "../../../modules/calculatorListings/amInvestCalculatorList";
import { Helmet } from "react-helmet";

const AmInvestCalulators = () => {
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ambassadorinvestments.com/calculators"
        />
      </Helmet>
      <AmInvestCalculatorList />
    </>
  );
};

export default AmInvestCalulators;
