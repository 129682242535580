import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import ReactPaginate from "react-paginate";

axios.defaults.withCredentials = true;

function AngelInvestments() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [selectedStages, setSelectedStages] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedRisks, setSelectedRisks] = useState([]);
  const [filteredInvestments, setFilteredInvestments] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [filteredInvestmentsCount, setFilteredInvestmentsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Dummy filter options
  const [stageOptions, setStageOptions] = useState([
    "Seed",
    "Series A",
    "Pre-seed",
  ]);
  const [typeOptions, setTypeOptions] = useState([
    "Individual Opportunity",
    "Basket",
    "angle_company", // Added "angle_company" to match backend data
  ]);
  const [riskOptions, setRiskOptions] = useState(["Low", "Medium", "High"]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await axios.get(`${baseUrl}/angel-companies/filters`);
        setStageOptions(
          response.data.stages.length > 0 ? response.data.stages : stageOptions
        );
        setTypeOptions(
          response.data.types.length > 0 ? response.data.types : typeOptions
        );
        setRiskOptions(
          response.data.risks.length > 0 ? response.data.risks : riskOptions
        );
      } catch (error) {
        console.error("Error fetching filter options:", error);
      }
    };

    fetchFilterOptions();
  }, [baseUrl]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const selectedStage = urlParams.get("stage");
    const selectedType = urlParams.get("type");
    const selectedRisk = urlParams.get("risk");

    if (selectedStage) setSelectedStages([decodeURIComponent(selectedStage)]);
    if (selectedType) setSelectedTypes([decodeURIComponent(selectedType)]);
    if (selectedRisk) setSelectedRisks([decodeURIComponent(selectedRisk)]);

    const page = urlParams.get("page");
    setCurrentPage(page ? parseInt(page, 10) - 1 : 0);
  }, [location.search]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/angel-companies`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setInvestments(response.data);
          setFilteredInvestmentsCount(response.data.length);
        } else {
          setInvestments([]);
          setFilteredInvestmentsCount(0);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setInvestments([]);
        setFilteredInvestmentsCount(0);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let filteredOpportunities = investments.length > 0 ? investments : [];

    if (selectedStages.length > 0) {
      filteredOpportunities = filteredOpportunities.filter((investment) =>
        selectedStages.includes(investment.funding_stage)
      );
    }

    if (selectedTypes.length > 0) {
      filteredOpportunities = filteredOpportunities.filter((investment) =>
        selectedTypes.includes(investment.type)
      );
    }

    if (selectedRisks.length > 0) {
      filteredOpportunities = filteredOpportunities.filter((investment) =>
        selectedRisks.includes(investment.risk)
      );
    }

    if (searchQuery) {
      filteredOpportunities = filteredOpportunities.filter((investment) =>
        investment.company_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    setFilteredInvestments(filteredOpportunities);
    setFilteredInvestmentsCount(filteredOpportunities.length);
  }, [selectedStages, selectedTypes, selectedRisks, investments, searchQuery]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    navigate(`${location.pathname}?page=${selected + 1}`);
    window.scrollTo(0, 0);
  };

  const handleCheckboxChange = useCallback((e, setSelectedState) => {
    const value = e.target.value;
    setSelectedState((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  }, []);

  const clearAllFilters = () => {
    setSelectedStages([]);
    setSelectedTypes([]);
    setSelectedRisks([]);
    setSearchQuery("");
  };

  const removeFilter = (filter, setSelectedState) => {
    setSelectedState((prev) => prev.filter((item) => item !== filter));
  };

  const SelectedFilters = ({
    selectedStages,
    selectedTypes,
    selectedRisks,
    removeFilter,
  }) => {
    const filterItems = [
      {
        title: "Stages",
        items: selectedStages,
        setSelectedState: setSelectedStages,
      },
      {
        title: "Types",
        items: selectedTypes,
        setSelectedState: setSelectedTypes,
      },
      {
        title: "Risks",
        items: selectedRisks,
        setSelectedState: setSelectedRisks,
      },
    ];

    return (
      <div className="selected-filters">
        {filterItems.map(
          (filter) =>
            filter.items.length > 0 && (
              <div className="selectedFilterIs" key={filter.title}>
                {filter.items.map((item) => (
                  <span key={item} className="selected-filter-item">
                    {item}{" "}
                    <button
                      className="removeFilter"
                      onClick={() =>
                        removeFilter(item, filter.setSelectedState)
                      }
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
            )
        )}
      </div>
    );
  };

  const renderInvestments = useMemo(() => {
    const startIdx = currentPage * itemsPerPage;
    const investmentsToDisplay = filteredInvestments.slice(
      startIdx,
      startIdx + itemsPerPage
    );

    return investmentsToDisplay.map((investment) => (
      <Link
        to={`/angel-investments/${investment.id}`}
        className="screener-list"
        key={investment.id}
      >
        <div className="sceener-data">
          <div className="screener-info">
            <div className="d-flex">
              <div>
                <img
                  src={
                    investment.logo_url ||
                    "https://img.icons8.com/clouds/100/rocket.png"
                  }
                  alt={investment.company_name}
                  style={{
                    width: "75px",
                    height: "auto",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div className="d-flex flexCol">
                <span className={`stockName`}>{investment.company_name}</span>
                <div className="d-flex">
                  <p className="budget">{investment.funding_stage}</p>
                  <p className="budget">{investment.type}</p>
                  <p className="budget">{investment.risk}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-data">
          <div className="MY-data rate">
            <span className="value">INR {investment.min_investment} lakhs</span>
            <span>Min Investment</span>
          </div>
          <div className="MY-data rate">
            <span className="value">{investment.targeted_returns}%</span>
            <span>Targeted Returns</span>
          </div>
          <div className="MY-data rate">
            <span className="value">{investment.time_frame} months</span>
            <span>Time Frame</span>
          </div>
        </div>
      </Link>
    ));
  }, [currentPage, filteredInvestments]);

  const SidebarFilter = ({ title, options, selectedOptions, handleChange }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [filterSearchQuery, setFilterSearchQuery] = useState("");

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(filterSearchQuery.toLowerCase())
    );

    return (
      <div className="filter-section">
        <h4 onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          {title}{" "}
          <span className="accordionCustomArrow">{isOpen ? "▲" : "▼"}</span>
        </h4>
        {isOpen && (
          <>
            <input
              type="text"
              placeholder={`Search ${title}`}
              value={filterSearchQuery}
              onChange={(e) => setFilterSearchQuery(e.target.value)}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            {filteredOptions.map((option) => (
              <div key={option} className="filterName">
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={(e) => handleChange(e)}
                />
                <label>{option}</label>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <section className="angelInvestmentsListPage">
      <div className="filter-sidebar">
        <div
          style={{
            position: "sticky",
            top: "0",
            backgroundColor: "#fff",
            zIndex: "1",
          }}
        >
          <span className="spanText">Filters</span>
          <button onClick={clearAllFilters} className="clearAllButton">
            Clear All
          </button>
        </div>
        <SidebarFilter
          title="Stage"
          options={stageOptions}
          selectedOptions={selectedStages}
          handleChange={(e) => handleCheckboxChange(e, setSelectedStages)}
        />
        <SidebarFilter
          title="Type"
          options={typeOptions}
          selectedOptions={selectedTypes}
          handleChange={(e) => handleCheckboxChange(e, setSelectedTypes)}
        />
        <SidebarFilter
          title="Risk"
          options={riskOptions}
          selectedOptions={selectedRisks}
          handleChange={(e) => handleCheckboxChange(e, setSelectedRisks)}
        />
      </div>
      <div className="investmentsListAndSearch">
        <div className="search-container mt-3 mb-2">
          <input
            type="text"
            placeholder="Search angel investments..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <span className="main-title investmentCounts">
            {filteredInvestmentsCount === 0
              ? "No Angel Investments Found"
              : `${filteredInvestmentsCount} Angel Investments Available`}
          </span>
        </div>
        <div className="investments-list">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "10px",
              }}
            >
              <Button style={{ backgroundColor: "#416052" }} disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
            </div>
          ) : filteredInvestmentsCount === 0 ? null : (
            <>
              <SelectedFilters
                selectedStages={selectedStages}
                selectedTypes={selectedTypes}
                selectedRisks={selectedRisks}
                removeFilter={removeFilter}
              />

              {renderInvestments}

              <div className="pagination-container">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(filteredInvestmentsCount / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  initialPage={currentPage}
                  forcePage={currentPage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default AngelInvestments;
