import { useState, useEffect } from "react";
import axios from "axios";
import "./offerBanner.css";
import Cookies from "js-cookie";

const OfferBanner = ({ onClose }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [showBanner, setShowBanner] = useState(false);
  const [bannerContent, setBannerContent] = useState("");

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/offerBanner`);
        const { showOfferBanner, bannerContent } = response.data;
        const bannerHiddenUntil = Cookies.get("bannerHiddenUntil");
        if (
          showOfferBanner &&
          (!bannerHiddenUntil || new Date() >= new Date(bannerHiddenUntil))
        ) {
          setShowBanner(true);
          setBannerContent(bannerContent);
        }
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchBannerData();
  }, []);

  const closeBanner = () => {
    setShowBanner(false);
    const hiddenUntil = new Date();
    hiddenUntil.setHours(hiddenUntil.getHours() + 24);
    Cookies.set("bannerHiddenUntil", hiddenUntil);
    onClose();
  };

  if (!showBanner) return null;

  return (
    <div className="offerBanner">
      <div className="bannerContent">{bannerContent}</div>
      <button className="closeButton" onClick={closeBanner}>
        X
      </button>
    </div>
  );
};

export default OfferBanner;
