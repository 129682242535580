import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AmInvestCard from "../../components/cards/amInvestCards";
import Equity from "../../assets/images/equity.png";
import Debt from "../../assets/images/debt.png";
import Hybrid from "../../assets/images/hybrid.png";
import Solution from "../../assets/images/solutionOriented.png";
import "./stockModule.css";

const fundCategories = [
  {
    title: "Equity",
    image: Equity,
    link: "/mutualFunds",
    description: "Ready to ride the waves of the stock market?",
  },
  {
    title: "Debt",
    image: Debt,
    link: "/mutualFunds",
    description: "Safe and Steady wins the race",
  },
  {
    title: "Hybrid",
    image: Hybrid,
    link: "/mutualFunds",
    description: "Can't decide? Get the best of both worlds!",
  },
  {
    title: "Solution Oriented",
    image: Solution,
    link: "/mutualFunds",
    description: "Tailored for your dreams!",
  },
];

const AmInvestStockBundle = () => {
  return (
    <section className="stockBundles">
      <Container>
        <h2 className="text-center mb-5 mainHeading">Select Your Goal</h2>
        <Row className="fundsCardRow">
          {fundCategories.map(({ title, image, link, description }, index) => (
            <Col md={3} key={index}>
              <AmInvestCard
                type="fundsCard"
                image={image}
                pillText={title}
                title={title}
                description={description}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default AmInvestStockBundle;
