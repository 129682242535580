import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Chart from "chart.js/auto";
import { Container, Row, Col, Table, Spinner } from "react-bootstrap";
import Select from "react-select";
import Cookies from "js-cookie";
import "./compareFunds.css";

const CompareFunds = () => {
  const [mutualFunds, setMutualFunds] = useState([]);
  const [selectedFunds, setSelectedFunds] = useState([null, null, null]);
  const [navData, setNavData] = useState([]);
  const [returns, setReturns] = useState([]);
  const [peRatios, setPeRatios] = useState([]);
  const [dividends, setDividends] = useState([]);
  const [trustees, setTrustees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const chartRef = useRef(null);
  const canvasRef = useRef(null);
  const previousSelectedFunds = useRef([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchMutualFunds = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/mutualfunds`);
        setMutualFunds(response.data.mutualFunds);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMutualFunds();
  }, [baseUrl]);

  const handleFundSelect = (index, selectedOption) => {
    const selectedFund = mutualFunds.find(
      (fund) => fund.id === (selectedOption ? selectedOption.value : null)
    );
    const newSelectedFunds = [...selectedFunds];
    newSelectedFunds[index] = selectedFund || null;
    setSelectedFunds(newSelectedFunds);
  };

  const handleRemoveFund = (index) => {
    const newSelectedFunds = [...selectedFunds];
    newSelectedFunds[index] = null;
    setSelectedFunds(newSelectedFunds);
  };

  useEffect(() => {
    const fetchNAVData = async () => {
      const validSelectedFunds = selectedFunds.filter((fund) => fund !== null);

      if (
        validSelectedFunds.length > 0 &&
        JSON.stringify(validSelectedFunds) !==
          JSON.stringify(previousSelectedFunds.current)
      ) {
        setLoading(true);
        try {
          const fundDataPromises = validSelectedFunds.map((fund) => {
            const token = Cookies.get("token");
            const config = {
              headers: {
                WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
              },
            };
            return axios.get(`${baseUrl}/new_nav_history/${fund.id}`, config);
          });

          const fundDataResponses = await Promise.all(fundDataPromises);
          const navHistories = fundDataResponses.map(
            (response) => response.data
          );

          setNavData(navHistories);

          const fundDetailsPromises = validSelectedFunds.map((fund) => {
            const token = Cookies.get("token");
            const config = {
              headers: {
                WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
              },
            };
            return axios.get(`${baseUrl}/funds-details/${fund.id}`, config);
          });

          const fundDetailsResponses = await Promise.all(fundDetailsPromises);
          const fundDetails = fundDetailsResponses.map(
            (response) => response.data
          );

          setPeRatios(
            fundDetails.map(
              (detail) => detail.ratio["ARF-InterimNetExpenseRatio"] || "N/A"
            )
          );
          setDividends(
            fundDetails.map((detail) => detail.returns["DP-Return1Yr"] || "N/A")
          );
          setTrustees(fundDetails.map((detail) => detail.trustees || []));
          calculateReturns(fundDetails.map((detail) => detail.returns || {}));
          previousSelectedFunds.current = validSelectedFunds;
        } catch (error) {
          console.error("Error fetching fund details:", error);
        }
        setLoading(false);
        setDataReady(true);
      }
    };

    fetchNAVData();
  }, [selectedFunds, baseUrl]);

  const calculateReturns = (returnsData) => {
    const returns = returnsData.map((returnData) => ({
      return1y: returnData["DP-Return1Yr"]
        ? returnData["DP-Return1Yr"].toFixed(2)
        : "N/A",
      return3y: returnData["DP-Return3Yr"]
        ? returnData["DP-Return3Yr"].toFixed(2)
        : "N/A",
      return5y: returnData["DP-Return5Yr"]
        ? returnData["DP-Return5Yr"].toFixed(2)
        : "N/A",
    }));
    setReturns(returns);
  };

  const verticalLinePlugin = {
    id: "verticalLine",
    afterDraw: (chart, args, options) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.element.x;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "rgba(255, 0, 0, 0.5)";
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  useEffect(() => {
    const renderChart = () => {
      if (!canvasRef.current || navData.length === 0) return;

      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const ctx = canvasRef.current.getContext("2d");

      const datasets = navData.map((fundData, index) => {
        const navs = fundData.map((item) => item.nav).reverse();

        const color = index === 0 ? "red" : index === 1 ? "blue" : "green";

        return {
          label:
            selectedFunds.filter((fund) => fund !== null)[index]?.name || "",
          data: navs,
          borderColor: color,
          backgroundColor: color,
          borderWidth: 2,
          pointRadius: 0,
          fill: false,
        };
      });

      const dates = navData[0]
        ?.map((item) => new Date(item.date).toLocaleDateString("en-GB"))
        .reverse();

      chartRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: dates,
          datasets,
        },
        options: {
          responsive: true,
          scales: {
            x: {
              display: true,
              grid: {
                display: true,
              },
              ticks: {
                callback: function (value, index, values) {
                  return index % Math.floor(dates.length / 10) === 0
                    ? dates[index]
                    : "";
                },
              },
            },
            y: {
              display: true,
              grid: {
                display: true,
              },
              ticks: {
                callback: function (value, index, values) {
                  return value.toFixed(2);
                },
              },
            },
          },
          plugins: {
            tooltip: {
              mode: "index",
              intersect: false,
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  label += context.parsed.y.toFixed(2);
                  return label;
                },
                title: function (context) {
                  return `DATE: ${context[0].label}`;
                },
              },
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              titleFont: {
                family: "Arial",
                size: 14,
                weight: "bold",
                style: "normal",
              },
              bodyFont: {
                family: "Arial",
                size: 12,
                weight: "normal",
                style: "normal",
              },
              titleColor: "#fff",
              bodyColor: "#fff",
              borderColor: "#fff",
              borderWidth: 1,
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
        },
        plugins: [verticalLinePlugin],
      });
    };

    if (dataReady) {
      renderChart();
      setDataReady(false);
    }
  }, [navData, selectedFunds, dataReady]);

  const fundOptions = mutualFunds.map((fund) => ({
    value: fund.id,
    label: fund.name,
  }));

  return (
    <section className="compareFundsSction">
      <Container>
        <Row className="fundsInput">
          <Col>
            <h2>Compare Mutual Funds</h2>
            <Row className="mb-3">
              {[0, 1, 2].map((index) => (
                <Col key={index}>
                  <Select
                    options={fundOptions}
                    value={
                      selectedFunds[index]
                        ? {
                            value: selectedFunds[index].id,
                            label: selectedFunds[index].name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleFundSelect(index, selectedOption)
                    }
                    isClearable
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="fundsChart">
          <Col>
            {loading ? (
              <div className="d-flex justify-content-center my-4">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <canvas id="compareFundsChart" ref={canvasRef}></canvas>
            )}
          </Col>
        </Row>
        <Row className="fundsCompare">
          <Col>
            {returns.length > 0 && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Parameter</th>
                    {selectedFunds
                      .filter((fund) => fund !== null)
                      .map((fund, index) => (
                        <th key={index}>{fund.name}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1Y Return</td>
                    {returns.map((returnData, index) => (
                      <td key={index}>{returnData.return1y}%</td>
                    ))}
                  </tr>
                  <tr>
                    <td>3Y Return</td>
                    {returns.map((returnData, index) => (
                      <td key={index}>{returnData.return3y}%</td>
                    ))}
                  </tr>
                  <tr>
                    <td>5Y Return</td>
                    {returns.map((returnData, index) => (
                      <td key={index}>{returnData.return5y}%</td>
                    ))}
                  </tr>
                  <tr>
                    <td>PE Ratio</td>
                    {peRatios.map((peRatio, index) => (
                      <td key={index}>{peRatio}</td>
                    ))}
                  </tr>
                  <tr>
                    <td>Dividend</td>
                    {dividends.map((dividend, index) => (
                      <td key={index}>{dividend}</td>
                    ))}
                  </tr>
                  <tr>
                    <td>Trustees</td>
                    {trustees.map((trusteeList, index) => (
                      <td key={index}>
                        {trusteeList.map((trustee) => trustee.name).join(", ")}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CompareFunds;
