import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const UserPolicy = () => {
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ambassadorinvestments.com/user-policy"
        />
      </Helmet>
      <Container>
        <>
          <h1>Privacy Policy</h1>
          <p>
            We, AMBASSADOR FINTECH PVT LTD, the creators of this Privacy Policy
            ensure our firm commitment to your privacy vis-Ã -vis the protection
            of your priceless information. In order to endow you with our
            uninterrupted use of services, we may collect and, in some
            circumstances, disclose information about you. To enhance better
            protection of your privacy we provide this notice explaining our
            information practices and the choices you can make about the way
            your information is collected and used.
          </p>
          <p>
            This privacy policy should be read in accordance with any agreement
            you have with AMBASSADOR FINTECH PVT LTD, including Terms &amp;
            Conditions for your investment account and the Know Your Customer
            (KYC) process.
          </p>
          <p>
            If you have any questions or concerns regarding this privacy policy,
            you should contact our Customer Support Desk at
            customer.support@ambassadorinvestments.com
          </p>
          <h2>OVERVIEW</h2>
          <p>
            We commit to respecting your online privacy data. We further
            recognize your need for appropriate protection and management of any
            personally identifiable information ("Personal Information") you
            share with us. Information that is considered personal about you by
            us includes, but is not limited to, your name, address, email
            address, portfolio details or other contact information.
          </p>
          <p>
            In order to use this website, you are required to register yourself
            by providing the following information which includes, but is not
            limited to:
          </p>
          <p>1. Name</p>
          <p>2. PAN Number</p>
          <p>3. Phone number</p>
          <p>4. Email address</p>
          <p>5. Date of Birth</p>
          <p>6. Bank account details</p>
          <p>7. Pictures of self and documents required for KYC.</p>
          <p>
            If you are using our portfolio tracker services, then you also
            choose to share your consolidated investment statement.
          </p>
          <h2>
            NOTIFICATION OF MODIFICATIONS AND CHANGES TO THE TERMS &amp;
            CONDITIONS AND PRIVACY POLICY
          </h2>
          <p>
            We reserve the right to change the Terms and Privacy Policy from
            time to time as seen fit, without any intimation to you, and your
            continued use of the site will signify your acceptance of any
            amendment to these terms. You are therefore advised to re-read the
            Terms of Service on a regular basis. Should it be that you do not
            accept any of the modifications or amendments to the Terms, you may
            terminate your use of this website immediately.
          </p>
          <h2>COOKIES POLICY</h2>
          <p>
            We use data collection devices such as "cookies" on certain pages of
            our Websites. "Cookies" are small files sited on your hard drive
            that assist us in providing customized services. We also offer
            certain features that are only available through the use of a
            "cookie". Cookies can also help us provide information, which is
            targeted to your interests. Cookies may be used whether you register
            with us or not.
          </p>
          <h2>DISCLAIMER</h2>
          <p>
            We cannot ensure that all of your private communications and other
            personal information (including sensitive information like credit
            card information and bank account number) will never be disclosed in
            ways not otherwise described in this Privacy Policy. Therefore,
            although we are committed to protecting your privacy, we do not
            promise, and you should not expect, that your personal information
            will always remain private. As a user of the Site, you understand
            and agree that you assume all responsibility and risk for your use
            of the Site, the internet generally, and the documents you post or
            access and for your conduct on and off the Site.
          </p>
          <h2>FATCA &amp; CRS Declaration</h2>
          <p>
            I/We acknowledge and confirm that the information provided above
            is/are true and correct to the best of my/our knowledge and belief.
            In case any of the above specified information is found to be false
            or untrue or misleading or misrepresenting, I/We am/are aware that
            I/We may liable for it. I/We hereby authorize you to disclose,
            share, remit in any form, mode or manner, all / any of the
            information provided by me/ us, including all changes, updates to
            such information as and when provided by me/ us to Mutual Fund, its
            Sponsor, Asset Management Company, trustees, their employees / RTAs
            ('the Authorized Parties') or any Indian or foreign governmental or
            statutory or judicial authorities / agencies including but not
            limited to the Financial Intelligence Unit-India (FIU-IND), the tax
            / revenue authorities in India or outside India wherever it is
            legally required and other investigation agencies without any
            obligation of advising me/us of the same. Further, I/We, authorize
            to share the given information to other SEBI Registered
            Intermediaries to facilitate single submission / updation &amp; for
            other relevant purposes. I/We also undertake to keep you informed in
            writing about any changes / modification to the above information in
            future and also undertake to provide any other additional
            information as may be required at your end.
          </p>
          <p>
            <strong />
          </p>
          <p>
            <strong>Minor Investment:</strong> <strong />
          </p>
          <p>
            I/We hereby confirm that payment for investment is from the bank
            account of the minor, parent or legal guardian of the minor, or from
            a joint account of the minor with parent or legal guardian. I/We
            acknowledge that in case the bank details provided are not of the
            minor, parent or legal guardian of the minor or of a joint account
            of the minor with parent or legal guardian, the application is
            liable to be rejected and the money paid will be refunded without
            interest
          </p>
          <p>
            <strong>Refund Policy</strong> <strong />
          </p>
          <p>
            AMBASSADOR FINTECH PVT LTD (referred as AMBASSADOR MF) offers
            multiple products on their platform and all products listed under a
            particular category may not have the same refund policy. For all
            products, the refund policy “IF” provided on the product page shall
            prevail over the general refund policy. Do refer the respective
            product refund policy on the product page for any exceptions to this
            return policy and the table below.
          </p>
          <h2>1. Mutual Funds:</h2>
          <p>
            For transactions which have not been reported to the AMCs, the
            refund is done as per the payment gateway refund policy. Usually it
            takes 3-7 business days for refund. The refund duration also depends
            on the payment mode used to make the transaction. No charges are
            deducted from the refund amount and the refund amount is refunded in
            the payment source used during transaction unless in exceptional
            cases.
          </p>
          <p>
            For transaction sent to AMC, the AMC refunds directly to the bank
            account submitted by user on the BW platform. In case of issue of
            NEFT/RTGS, AMC issues a cheque in the name of the user and it is
            sent to user’s address as per the KYC records.
          </p>
        </>
      </Container>
    </>
  );
};

export default UserPolicy;
