import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const domain =
    window.location.hostname === "localhost"
      ? undefined
      : ".ambassadorinvestments.com";
  const validateToken = async (token) => {
    try {
      const response = await axios.post(
        `${baseUrl}/validate-token`,
        { token },
        {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        }
      );
      if (response.data.valid) {
        setIsLoggedIn(true);
      } else {
        clearCookiesAndRedirect();
      }
    } catch (error) {
      console.error("Token validation failed:", error);
      logout();
    } finally {
      setIsLoading(false);
    }
  };

  const login = (token) => {
    Cookies.set("token", token, { domain });
    setIsLoggedIn(true);
  };

  const clearCookiesAndRedirect = () => {
    Cookies.remove("token", { domain });
    Cookies.remove("profileImageUrl");
    setIsLoggedIn(false);
    setIsLoading(false);
  };

  const logout = () => {
    clearCookiesAndRedirect();
  };

  useEffect(() => {
    const tokenFromCookie = Cookies.get("token");
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get("token");

    if (tokenFromUrl) {
      Cookies.set("token", tokenFromUrl, { domain });
      window.history.replaceState({}, document.title, window.location.pathname);
      validateToken(tokenFromUrl);
    } else if (tokenFromCookie) {
      validateToken(tokenFromCookie);
    } else {
      clearCookiesAndRedirect();
      setIsLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, isLoading, login, logout, validateToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
