import axios from "axios";
import { clearCookiesAndRedirect } from "../helper/errorIntercepter";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      clearCookiesAndRedirect();
    }
    return Promise.reject(error);
  }
);
