import { useState, useEffect, useRef, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Modal,
} from "react-bootstrap";
import validator from "validator";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUser,
  faUserCircle,
  faFlag,
  faFile,
  faCircleXmark,
  faFileImage,
} from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Webcam from "react-webcam";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { renderFilePreview } from "../helper/filePreview";

const ProfileProcess = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userData, setUserData] = useState("");
  const [email, setEmails] = useState("");
  const [isValidEmail, setValidEmail] = useState(false);
  const [countries, setCountries] = useState([]);
  const [occupationType, setOccupationType] = useState([]);
  const [incomeRange, setIncomeRange] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNomineeData, setNomineeData] = useState(false);
  const [userbankdata, setUserbankdata] = useState({});
  const [nomineeForms, setNomineeForms] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [passportFrontPreview, setPassportFrontPreview] = useState(null);
  const [kycStatus, setKycStatus] = useState(null);
  const [kycPassportFront, setKycPassportFront] = useState(null);
  const [kycPassportBack, setKycPassportBack] = useState(null);
  const [kycPan, setKycPan] = useState(null);
  const [kycIndianAddressProof, setKycIndianAddressProof] = useState(null);
  const [kycForeignAddressProof, setKycForeignAddressProof] = useState(null);
  const [kycCheque, setKycCheque] = useState(null);
  const [kycTaxIdentification, setKycTaxIdentification] = useState(null);
  const [includeKycStep, setIncludeKycStep] = useState(false);
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [image, setImage] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const [userkycdata, setUserkycdata] = useState({});
  const [presignedUrls, setPresignedUrls] = useState({});
  const [PhotoData, setPhotoData] = useState({
    PhotoData: "",
  });
  const [kycData, setKycData] = useState({
    pan: "",
    dob: "",
  });
  const [pan, setPan] = useState("");
  const [kycErrors, setKycErrors] = useState({});
  const [formData, setFormData] = useState({
    Gender: "",
    MaritalStatus: "",
    Citizenship: "",
    OccupationType: "",
    CityOfBirth: "",
    Income: "",
    IdentityType: "",
    IdentityNumber: "",
    Document: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    ZipCode: "",
    Country: "",
    MobileNumber: "",
    DateOfBirth: "",
    isWhatsappNumber: false,
    whatsappNumber: "",
  });
  const [formData1, setFormData1] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    ResidentialStatus: "",
  });
  const [formBankData, setFormBankData] = useState({
    memberCode: "",
    bankName: "",
    ifscCode: "",
    accountType: "",
    accountNumber: "",
  });

  const residentialStatus = [
    { id: 1, name: "Overseas Citizenship of India" },
    { id: 2, name: "Non-Resident Indian" },
    { id: 3, name: "Indian Citizen" },
  ];

  const genderOptions = [
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
  ];

  const maritalOptions = [
    { id: 1, name: "Married" },
    { id: 2, name: "Unmarried" },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${baseUrl}/getUserData`, config);
        if (response.data) {
          setUserData(response.data);
          setKycStatus(response.data.members?.KYCStatus || 0);
          setIncludeKycStep(response.data.members?.KYCStatus !== 1);
          setEmails(response.data.members?.Email || "");
          setFormData({
            Gender: response.data.memberProfile?.Gender || "",
            MaritalStatus: response.data.memberProfile?.MaritalStatus || "",
            Citizenship: response.data.memberProfile?.Citizenship || "",
            OccupationType: response.data.memberProfile?.OccupationType || "",
            CityOfBirth: response.data.memberProfile?.CityOfBirth || "",
            Income: response.data.memberProfile?.Income || "",
            IdentityType: response.data.memberProfile?.IdentityType || "",
            IdentityNumber: response.data.memberProfile?.IdentityNumber || "",
            Document: response.data.memberProfile?.Document || "",
            Address1: response.data.memberProfile?.Address1 || "",
            Address2: response.data.memberProfile?.Address2 || "",
            City: response.data.memberProfile?.City || "",
            State: response.data.memberProfile?.State || "",
            ZipCode: response.data.memberProfile?.ZipCode || "",
            Country: response.data.memberProfile?.Country || "",
            MobileNumber: response.data.memberProfile?.MobileNumber || "",
            DateOfBirth:
              response.data?.memberProfile?.DateOfBirth?.slice(0, 10) || "",
          });
          setFormData1({
            FirstName: response.data.members?.FirstName || "",
            MiddleName: response.data.members?.MiddleName || "",
            LastName: response.data.members?.LastName || "",
            ResidentialStatus: response.data.members?.ResidentialStatus || "",
          });
          setFormBankData({
            memberCode: response.data.members?.MemberCode || "",
            bankName: response.data.bankDetails?.Branch?.Name || "",
            ifscCode: response.data.bankDetails?.Branch?.IFSCCode || "",
            accountType: response.data.bankDetails?.AccountType || "",
            accountNumber: response.data.bankDetails?.AccountNumber || "",
          });
          setUserbankdata(response.data.bankDetails || {});
          setNomineeForms(response.data.nomineeDetails || []);
          setNomineeData(response.data.nomineeDetails?.length > 0);
          setLoading(false);
        }

        if (kycStatus === 3) {
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    const fetchOptions = async () => {
      try {
        const countriesResponse = await axios.get(`${baseUrl}/countries`);
        setCountries(countriesResponse.data);
      } catch (error) {
        console.error(
          "Error fetching countries data:",
          error.response?.data || error.message
        );
      }

      try {
        const incomeResponse = await axios.get(`${baseUrl}/income`);
        setIncomeRange(incomeResponse.data);
      } catch (error) {
        console.error(
          "Error fetching income data:",
          error.response?.data || error.message
        );
      }

      try {
        const occupationResponse = await axios.get(
          `${baseUrl}/occupation_type`
        );

        setOccupationType(occupationResponse.data);
      } catch (error) {
        console.error(
          "Error fetching occupation type data:",
          error.response?.data || error.message
        );
      }
    };

    fetchData();
    fetchOptions();
  }, [baseUrl, navigate]);

  const fetchPresignedUrls = async (documents) => {
    const token = Cookies.get("token");
    const urls = {};

    for (const [docType, docPaths] of Object.entries(documents)) {
      if (Array.isArray(docPaths)) {
        urls[docType] = await Promise.all(
          docPaths.map(async (path) => {
            if (typeof path == "string") {
              const response = await axios.get(
                `${baseUrl}/generate-presigned-url?Path=${encodeURIComponent(
                  path.split("ambassador.docs/")[1]
                )}`,
                {
                  headers: {
                    WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
                  },
                }
              );

              return response.data.url;
            } else {
              console.error(`Path is not a string: ${path}`);
              return null;
            }
          })
        );
      }
    }

    return urls;
  };

  useEffect(() => {
    const fetchDataWithPresignedUrls = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };

        const response = await axios.get(`${baseUrl}/getKycData`, config);
        const kycData = response.data;

        // Fetch presigned URLs
        const presignedUrls = await fetchPresignedUrls(kycData);

        setUserkycdata(kycData);
        setPresignedUrls(presignedUrls);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching KYC data:", error);
      }
    };

    fetchDataWithPresignedUrls();
  }, [baseUrl]);

  useEffect(() => {
    if (userkycdata?.Photo && userkycdata.Photo[0]) {
      setImageSrc(userkycdata.Photo[0]);
    }
  }, [userkycdata]);

  useEffect(() => {
    fetchIncomeRange();
    // eslint-disable-next-line
  }, [baseUrl]);
  const fetchIncomeRange = async () => {
    try {
      const response = await fetch(`${baseUrl}/income`);
      const data = await response.json();
      setIncomeRange(data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchKycStatus = async (pan, dob) => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };

      const source = "csdl";
      const detailed = false;

      const response = await axios.post(
        `${baseUrl}/check-kyc-status`,
        { source, pan_no: pan, dob, detailed },
        config
      );

      const kycCheckResponse = response.data.message.includes("1") ? 1 : 0;
      return kycCheckResponse;
    } catch (error) {
      throw new Error("Failed to fetch KYC status", error);
    }
  };

  const handleKycInputChange = (e) => {
    const { name, value } = e.target;
    setKycData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (isSubmitted) validateKycField(name, value);
  };

  const validateKycField = (name, value) => {
    let errorMsg = "";
    if (!value) errorMsg = "This field is required";
    setKycErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };

  const isKycFormValid = () => {
    return kycData.pan && kycData.dob && !kycErrors.pan && !kycErrors.dob;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (isSubmitted) validateField(name, value);
  };

  const handleFirstNameChange = (event) => {
    const newFirstName = event.target.value;
    if (!/[^a-zA-Z\s]/.test(newFirstName)) {
      setFormData1((prevData) => ({
        ...prevData,
        FirstName: newFirstName,
      }));
      setUserData((prevUserData) => ({
        ...prevUserData,
        members: {
          ...prevUserData.members,
          FirstName: newFirstName,
        },
      }));
    }
  };

  const handleMiddleNameChange = (event) => {
    const newMiddleName = event.target.value;
    if (!/[^a-zA-Z\s]/.test(newMiddleName)) {
      setFormData1((prevData) => ({
        ...prevData,
        MiddleName: newMiddleName,
      }));
      setUserData((prevUserData) => ({
        ...prevUserData,
        members: {
          ...prevUserData.members,
          MiddleName: newMiddleName,
        },
      }));
    }
  };

  const handleKycNext = async (e) => {
    const token = Cookies.get("token");

    e.preventDefault();
    const config = {
      headers: {
        WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
      },
    };
    try {
      setIsSubmitted(true);

      if (!isKycFormValid()) {
        console.error("KYC form is invalid");
        return;
      }
      setPan(kycData.pan);
      await axios.post(
        `${baseUrl}/submitPanNumber`,
        {
          PanNumber: kycData.pan,
        },
        config
      );

      const kycCheckResponse = await fetchKycStatus(kycData.pan, kycData.dob);

      if (kycCheckResponse === 1) {
        window.location.reload();

        setIncludeKycStep(false);
        updateStepHash(steps.findIndex((step) => step.key === "basic-profile"));
        scrollToTop();
      } else {
        toast.error("No KYC Record found, Please complete kyc process");
        setIncludeKycStep(true);

        updateStepHash(steps.findIndex((step) => step.key === "basic-profile"));
        scrollToTop();
      }
    } catch (error) {
      console.error("Error during KYC check:", error);
    }
  };

  const handleSkip = () => {
    navigate("/dashboard");
  };

  const handleLastNameChange = (event) => {
    const newLastName = event.target.value;
    if (!/[^a-zA-Z\s]/.test(newLastName)) {
      setFormData1((prevData) => ({
        ...prevData,
        LastName: newLastName,
      }));
      setUserData((prevUserData) => ({
        ...prevUserData,
        members: {
          ...prevUserData.members,
          LastName: newLastName,
        },
      }));
    }
  };

  const changeHandlerResidentialStatus = (event) => {
    const selectedOption = event.target.value;
    setFormData1((prevData) => ({
      ...prevData,
      ResidentialStatus: selectedOption,
    }));
    setUserData((prevUserData) => ({
      ...prevUserData,
      members: {
        ...prevUserData.members,
        ResidentialStatus: selectedOption,
      },
    }));
  };

  const validateField = (name, value) => {
    let errorMsg = "";
    switch (name) {
      case "FirstName":
      case "LastName":
        if (!value) errorMsg = "This field is required";
        if (/[^a-zA-Z\s]/.test(value))
          errorMsg = "Name should not contain special characters";
        break;
      case "DateOfBirth":
        if (!value) {
          errorMsg = "This field is required";
        } else {
          const today = new Date();
          const birthDate = new Date(value);
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDifference = today.getMonth() - birthDate.getMonth();
          if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          if (age < 18) errorMsg = "Age must be at least 18 years";
        }
        break;
      case "Gender":
      case "MaritalStatus":
      case "CityOfBirth":
      case "Citizenship":
      case "OccupationType":
      case "Income":
        if (!value) errorMsg = "This field is required";
        break;
      case "MobileNumber":
        if (!value) errorMsg = "This field is required";
        if (!validator.isMobilePhone(value)) errorMsg = "Invalid mobile number";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(e);
    if (isSubmitted) validateField(name, value);
  };

  const handleWhatsappNumberChange = (value) => {
    setFormData((prevData) => ({ ...prevData, whatsappNumber: value || "" }));
  };

  const handleSwitchChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      isWhatsappNumber: checked,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    const maxSize = 5 * 1024 * 1024;

    if (!file) {
      setSelectedFile(null);
      setFileErrorMessage("Please select a file.");
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      setSelectedFile(null);
      setFileErrorMessage(
        "Please select a valid file (JPG, JPEG, PDF, and PNG)."
      );
      return;
    }

    if (file.size > maxSize) {
      setSelectedFile(null);
      setFileErrorMessage("File size exceeds 5MB limit.");
      return;
    }

    setSelectedFile(file);
    setFileErrorMessage("");
    setFormData((prevData) => ({
      ...prevData,
      Document: file.name,
    }));
    setUserData((prevUserData) => ({
      ...prevUserData,
      memberProfile: {
        ...prevUserData.memberProfile,
        Document: file.name,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsSubmitted(true);

    const requiredFieldsFormData1 = [
      "FirstName",
      "LastName",
      "ResidentialStatus",
    ];
    const requiredFieldsFormData = [
      "DateOfBirth",
      "Gender",
      "MaritalStatus",
      "CityOfBirth",
      "Citizenship",
      "OccupationType",
      "Income",
      "MobileNumber",
    ];

    let hasErrors = false;

    requiredFieldsFormData1.forEach((field) => {
      const value = formData1[field];
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "This field is required",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
    });

    requiredFieldsFormData.forEach((field) => {
      const value = formData[field];
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "This field is required",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
    });

    if (!formData.isWhatsappNumber && !formData.whatsappNumber) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        whatsappNumber: "This field is required",
      }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        whatsappNumber: "",
      }));
    }

    if (hasErrors) {
      setIsSubmitting(false);
      return false;
    }

    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };

      const formDataWithWhatsapp = {
        ...formData,
        whatsappNumber: formData.isWhatsappNumber
          ? formData.MobileNumber
          : formData.whatsappNumber,
      };

      await axios.post(`${baseUrl}/insertMembers`, formData1, config);
      await axios.post(
        `${baseUrl}/insertMemberProfile`,
        formDataWithWhatsapp,
        config
      );

      if (selectedFile) {
        await uploadFile(selectedFile, "IDENTITY");
      }

      toast.success("Profile Updated!");
      setIsSubmitting(false);

      return true;
    } catch (error) {
      console.error("Form submission error:", error);
      setIsSubmitting(false);
      return false;
    }
  };

  const uploadFile = async (file, fileType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", fileType);
    try {
      const token = Cookies.get("token");

      const response = await fetch(`${baseUrl}/upload-document`, {
        method: "POST",
        body: formData,
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsSubmitting(false);
    }
  };

  const handleSubmitBankData = async (e) => {
    e.preventDefault();

    if (
      !formBankData.bankName ||
      !formBankData.ifscCode ||
      !formBankData.accountType ||
      !formBankData.accountNumber
    ) {
      toast.error("Please fill in all required fields.");
      setIsSubmitting(false);
      return false;
    }

    try {
      const token = Cookies.get("token");
      const response = await fetch(`${baseUrl}/submitBankData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
        body: JSON.stringify(formBankData),
      });

      if (response.ok) {
        toast.success("Bank Details Submitted Successfully");
        setIsSubmitting(false);
        return true;
      } else {
        const responseData = await response.json();
        if (response.status === 400) {
          toast.error(responseData.error);
        } else {
          toast.error("An error occurred while submitting form data");
        }
        setIsSubmitting(false);
        return false;
      }
    } catch (error) {
      toast.error("An error occurred while submitting form data");
      setIsSubmitting(false);
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormBankData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBothChanges = (e) => {
    handleChange(e);
  };

  const removeNomineeForm = (indexToRemove) => {
    setNomineeForms(nomineeForms.filter((_, index) => index !== indexToRemove));
  };

  useEffect(() => {
    if (userData && userData.members) {
      const defaultNomineeForm = {
        memberCode: userData.members.MemberCode,
        nomineeName: "",
        relation: "",
        dateOfBirth: "",
        percentage: "",
      };
      setNomineeForms([defaultNomineeForm]);
    }
  }, [userData]);

  const handleNomineeChange = (index, e) => {
    const { name, value } = e.target;
    const updatedNomineeForms = [...nomineeForms];
    updatedNomineeForms[index][name] = value;
    setNomineeForms(updatedNomineeForms);
  };

  const handleNomineeSubmit = async (e) => {
    e.preventDefault();
    try {
      for (const form of nomineeForms) {
        if (
          !form.nomineeName ||
          !form.relation ||
          !form.dateOfBirth ||
          !form.percentage
        ) {
          toast.error("All Nominee fields are required");
          setIsSubmitting(false);
          return false;
        }
      }

      const totalPercentage = nomineeForms.reduce(
        (total, form) => total + parseFloat(form.percentage || 0),
        0
      );
      if (totalPercentage !== 100) {
        toast.error("Total/ Combined percentage must be 100");
        setIsSubmitting(false);
        return false;
      }

      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };
      await Promise.all(
        nomineeForms.map((formNomineeData) =>
          axios.post(`${baseUrl}/submitNomineeData`, formNomineeData, config)
        )
      );
      toast.success("All nominee details submitted successfully.");
      setIsSubmitting(false);
      return true;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.error);
        } else {
          console.error("Error submitting nominee forms:", error.message);
        }
      } else {
        console.error("Error submitting nominee forms:", error.message);
      }
      setIsSubmitting(false);
      return false;
    }
  };

  const handleNomineeDetailsNext = async (e) => {
    e.preventDefault();
    const isNomineeDetailsSubmitted = await handleNomineeSubmit(e);
    if (isNomineeDetailsSubmitted) {
      updateStepHash(currentStep + 1);
      scrollToTop();
      await updateProfileStatus();
    } else {
      toast.error("Nominee details submission failed. Please try again.");
    }
  };

  const addNomineeForm = () => {
    if (nomineeForms.length < 5) {
      const newNomineeForm = {
        memberCode: userData.members.MemberCode,
        nomineeName: "",
        relation: "",
        dateOfBirth: "",
        percentage: "",
      };
      setNomineeForms([...nomineeForms, newNomineeForm]);
    }
  };

  const handleBasicInfoNext = async (e) => {
    e.preventDefault();

    const isBasicInfoSubmitted = await handleSubmit(e);
    if (isBasicInfoSubmitted) {
      updateStepHash(currentStep + 1);
      scrollToTop();
    } else {
      toast.error("Basic Info submission failed. Please try again.");
    }
  };

  const handleBankDetailsNext = async (e) => {
    e.preventDefault();
    const isBankDetailsSubmitted = await handleSubmitBankData(e);
    if (isBankDetailsSubmitted) {
      updateStepHash(currentStep + 1);
      scrollToTop();
    } else {
      toast.error("Bank details submission failed. Please try again.");
    }
  };

  const cameraopen = (e) => {
    e.preventDefault();
    setShowCameraModal(true);
  };

  const capture = (e) => {
    e.preventDefault();
    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) {
      toast.error("No image captured. Camera not available.");
      return;
    }
    setImage(imageSrc);
    const blobData = base64ToBlob(imageSrc);
    setImageSrc(blobData);
    setShowButtons(true);
  };

  const base64ToBlob = (base64Data) => {
    const base64String = base64Data.split(",")[1];
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: "image/jpeg" });
  };
  const retake = (e) => {
    e.preventDefault();
    setImageSrc(null);
    setShowButtons(false);
  };

  const handleSubmitWebcam = async (e) => {
    e.preventDefault();
    try {
      const photoUrl = await uploadFile(imageSrc, "PHOTO");
      setPhotoData((prevData) => ({
        ...prevData,
        PhotoData: photoUrl,
      }));

      const dataToSubmit = {
        PhotoFile: photoUrl,
      };
      const token = Cookies.get("token");

      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };
      await axios.post(`${baseUrl}/submitPhotoFileData`, dataToSubmit, config);
      setShowButtons(false);
      toast.success("Photo Uploaded Successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [selectedPassportFront, setSelectedPassportFront] = useState(null);
  const [PassportFrontErrorMessage, setPassportFrontErrorMessage] =
    useState("");

  const [PassportData, setPassportData] = useState({
    PassportNumber: "",
    PassportFrontFile: "",
    PassportBackFile: "",
  });

  const handlePassportNumber = (event) => {
    const newPassportNumber = event.target.value;
    setPassportData((prevData) => ({
      ...prevData,
      PassportNumber: newPassportNumber,
    }));
  };

  const [selectedPassportBack, setSelectedPassportBack] = useState(null);
  const [PassportBackErrorMessage, setPassportBackErrorMessage] = useState("");
  const [selectedPandiv, setSelectedPandiv] = useState(null);
  const [PandivErrorMessage, setPandivErrorMessage] = useState("");

  const handleShowPreview = (fileUrl) => {
    setModalContent(fileUrl);
    setShowPreviewModal(true);
  };
  const handlePassportFrontChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedPassportFront(file);
      setPassportFrontErrorMessage("");
    } else {
      setSelectedPassportFront(null);
      setPassportFrontErrorMessage("Please select a file.");
    }
  };

  const handlePassportBackChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedPassportBack(file);
      setPassportBackErrorMessage("");
    } else {
      setSelectedPassportBack(null);
      setPassportBackErrorMessage("Please select a file.");
    }
  };

  const submitPassportData = async () => {
    setIsSubmitting(true);

    try {
      const frontFileUrl = await uploadFile(
        selectedPassportFront,
        "PASSPORT_FRONT"
      );
      const backFileUrl = await uploadFile(
        selectedPassportBack,
        "PASSPORT_BACK"
      );

      setPassportData((prevData) => ({
        ...prevData,
        PassportFrontFile: frontFileUrl,
        PassportBackFile: backFileUrl,
      }));

      const dataToSubmit = {
        PassportNumber: PassportData.PassportNumber,
        PassportFrontFile: frontFileUrl,
        PassportBackFile: backFileUrl,
      };
      const token = Cookies.get("token");

      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };
      await axios.post(`${baseUrl}/submitPassportData`, dataToSubmit, config);
      toast.success("Passport Updated Successfully!");
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Failed to update passport data");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (
      PassportData.PassportNumber.length >= 8 &&
      selectedPassportFront &&
      selectedPassportBack
    ) {
      submitPassportData();
    }
  }, [
    PassportData.PassportNumber,
    selectedPassportFront,
    selectedPassportBack,
  ]);

  const [PandivData, setPandivData] = useState({
    PanNumber: "",
    PandivFile: "",
  });
  useEffect(() => {
    if (PandivData.PanNumber.length >= 10 && selectedPandiv) {
      submitPandivData();
    }
    // eslint-disable-next-line
  }, [PandivData.PanNumber, selectedPandiv]);

  const handlePandiv = (event) => {
    const newPanNumber = event.target.value;

    setPandivData((prevData) => ({
      ...prevData,
      PanNumber: newPanNumber,
    }));
  };

  const handlePandivChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    const maxSize = 5 * 1024 * 1024;

    if (!file) {
      setSelectedPandiv(null);
      setPandivErrorMessage("Please select a file.");
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      setSelectedPandiv(null);
      setPandivErrorMessage(
        "Please select a valid file (JPG, JPEG, PDF, and PNG)."
      );
      return;
    }

    if (file.size > maxSize) {
      setSelectedPandiv(null);
      setPandivErrorMessage("File size exceeds 5MB limit.");
      return;
    }

    setSelectedPandiv(file);
    setPandivErrorMessage("");
  };

  const submitPandivData = async () => {
    try {
      const pandivUrl = await uploadFile(selectedPandiv, "PANdiv");

      setPandivData((prevData) => ({
        ...prevData,
        PandivFile: pandivUrl,
      }));

      const dataToSubmit = {
        PanNumber: PandivData.PanNumber,
        PandivFile: pandivUrl,
      };
      const token = Cookies.get("token");

      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };
      await axios.post(`${baseUrl}/submitPancardData`, dataToSubmit, config);
      toast.success("Pan Updated Successfully!");
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Failed to update PAN data");
    }
  };

  const [selectedIndianAddressProof, setSelectedIndianAddressProof] =
    useState(null);
  const [IndianAddressProofErrorMessage, setIndianAddressProofErrorMessage] =
    useState("");

  const [IndianAddressProofData, setIndianAddressProofData] = useState({
    IndianAddressProofFile: "",
  });
  useEffect(() => {
    if (selectedIndianAddressProof) {
      submitIndianAddressProofFileData();
    }
    // eslint-disable-next-line
  }, [selectedIndianAddressProof]);
  const handleIndianAddressProofdChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    const maxSize = 5 * 1024 * 1024;

    if (!file) {
      setSelectedIndianAddressProof(null);
      setIndianAddressProofErrorMessage("Please select a file.");
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      setSelectedIndianAddressProof(null);
      setIndianAddressProofErrorMessage(
        "Please select a valid file (JPG, JPEG, PDF, and PNG)."
      );
      return;
    }

    if (file.size > maxSize) {
      setSelectedIndianAddressProof(null);
      setIndianAddressProofErrorMessage("File size exceeds 5MB limit.");
      return;
    }

    setSelectedIndianAddressProof(file);
    setIndianAddressProofErrorMessage("");
  };

  const submitIndianAddressProofFileData = async () => {
    try {
      const indianAddressProofUrl = await uploadFile(
        selectedIndianAddressProof,
        "INDIANADDRESSPROOF"
      );

      setIndianAddressProofData((prevData) => ({
        ...prevData,
        IndianAddressProofFile: indianAddressProofUrl,
      }));

      const dataToSubmit = {
        IndianAddressProofFile: indianAddressProofUrl,
      };
      const token = Cookies.get("token");

      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };
      await axios.post(
        `${baseUrl}/submitIndianAddressProofFileData`,
        dataToSubmit,
        config
      );
      toast.success("Indian Address Proof Updated Successfully!");
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Failed to update Indian address proof data");
    }
  };

  const [selectedForeignAddressProof, setSelectedForeignAddressProof] =
    useState(null);
  const [ForeignAddressProofErrorMessage, setForeignAddressProofErrorMessage] =
    useState("");

  const [ForeignAddressProofData, setForeignAddressProofData] = useState({
    ForeignAddressProofFile: "",
  });
  useEffect(() => {
    if (selectedForeignAddressProof) {
      submitForeignAddressProofFileData();
    }
    // eslint-disable-next-line
  }, [selectedForeignAddressProof]);
  const handleForeignAddressProofdChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    const maxSize = 5 * 1024 * 1024;

    if (!file) {
      setSelectedForeignAddressProof(null);
      setForeignAddressProofErrorMessage("Please select a file.");
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      setSelectedForeignAddressProof(null);
      setForeignAddressProofErrorMessage(
        "Please select a valid file (JPG, JPEG, PDF, and PNG)."
      );
      return;
    }

    if (file.size > maxSize) {
      setSelectedForeignAddressProof(null);
      setForeignAddressProofErrorMessage("File size exceeds 5MB limit.");
      return;
    }

    setSelectedForeignAddressProof(file);
    setForeignAddressProofErrorMessage("");
  };

  const submitForeignAddressProofFileData = async () => {
    try {
      const foreignAddressProofUrl = await uploadFile(
        selectedForeignAddressProof,
        "FOREIGNADDRESSPROOF"
      );
      setForeignAddressProofData((prevData) => ({
        ...prevData,
        ForeignAddressProofFile: foreignAddressProofUrl,
      }));

      const dataToSubmit = {
        ForeignAddressProofFile: foreignAddressProofUrl,
      };
      const token = Cookies.get("token");

      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };
      await axios.post(
        `${baseUrl}/submitForeignAddressProofFileData`,
        dataToSubmit,
        config
      );
      toast.success("Foreign Address Proof Updated Successfully!");
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Failed to update foreign address proof data");
    }
  };

  const [selectedCheque, setSelectedCheque] = useState(null);
  const [ChequeErrorMessage, setChequeErrorMessage] = useState("");

  const [ChequeData, setChequeData] = useState({
    NRINREChequeFile: "",
  });
  useEffect(() => {
    if (selectedCheque) {
      submitChequeData();
    }
    // eslint-disable-next-line
  }, [selectedCheque]);
  const handleChequeChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    const maxSize = 5 * 1024 * 1024;

    if (!file) {
      setSelectedCheque(null);
      setChequeErrorMessage("Please select a file.");
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      setSelectedCheque(null);
      setChequeErrorMessage(
        "Please select a valid file (JPG, JPEG, PDF, and PNG)."
      );
      return;
    }

    if (file.size > maxSize) {
      setSelectedCheque(null);
      setChequeErrorMessage("File size exceeds 5MB limit.");
      return;
    }

    setSelectedCheque(file);
    setChequeErrorMessage("");
  };

  const submitChequeData = async () => {
    try {
      const NRINREChequeUrl = await uploadFile(
        selectedCheque,
        "NRI/NRE_CHEQUE"
      );
      setChequeData((prevData) => ({
        ...prevData,
        NRINREChequeFile: NRINREChequeUrl,
      }));

      const dataToSubmit = {
        NRINREChequeFile: NRINREChequeUrl,
      };
      const token = Cookies.get("token");

      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };
      await axios.post(`${baseUrl}/submitChequeData`, dataToSubmit, config);
      toast.success("NRI/NRE Cheque Updated Successfully!");
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Failed to update cheque data");
    }
  };

  const [TaxIndentification, setTaxtIdentification] = useState({
    TaxIdentificationNumber: "",
  });

  const isKycDocumentFormValid = () => {
    return (
      imageSrc &&
      (userkycdata?.Passport?.[0]?.PassportNumber ||
        PassportData.PassportNumber) &&
      (userkycdata?.Passport?.[0]?.PassportFrontFile ||
        selectedPassportFront) &&
      (userkycdata?.Passport?.[0]?.PassportBackFile || selectedPassportBack) &&
      (userkycdata?.Pandiv?.[0]?.PanNumber || PandivData.PanNumber) &&
      (userkycdata?.Pandiv?.[0]?.PandivFile || selectedPandiv) &&
      (userkycdata?.IndianAddressProof?.length > 0 ||
        selectedIndianAddressProof) &&
      (userkycdata?.ForeignAddressProof?.length > 0 ||
        selectedForeignAddressProof) &&
      (userkycdata?.Cheque?.length > 0 || selectedCheque) &&
      (userkycdata?.TaxIdentification?.[0] ||
        TaxIndentification.TaxIdentificationNumber.length >= 11)
    );
  };

  const handleTaxIdentificationNumber = (event) => {
    const newTaxIdentificationNumber = event.target.value;
    setTaxtIdentification((prevData) => ({
      ...prevData,
      TaxIdentificationNumber: newTaxIdentificationNumber,
    }));
  };

  const handleTaxIdentificationNumberBlur = async (event) => {
    if (TaxIndentification.TaxIdentificationNumber.length === 11) {
      await submitTaxIdentificationNumber();
    } else {
      toast.error("Invalid Tax Identification Number");
    }
  };

  const submitTaxIdentificationNumber = async (e) => {
    try {
      const dataToSubmit = {
        TaxIdentificationNumber: TaxIndentification.TaxIdentificationNumber,
      };
      const token = Cookies.get("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${baseUrl}/submitTaxIdentificationNumber`,
        dataToSubmit,
        config
      );

      if (response.status === 200) {
        toast.success("Tax Identification Number Updated Successfully!");
      } else {
        toast.error(response.data.error || "An error occurred");
      }
    } catch (error) {
      console.error("Form submission error:", error);
      const errorMessage = error.response?.data?.error || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const handleKycDocumentUpload = (e) => {
    e.preventDefault();

    if (isKycDocumentFormValid()) {
      updateStepHash(currentStep + 1);
      scrollToTop();
    } else {
      toast.error(
        "Please ensure all required documents are uploaded and fields are filled."
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const baseSteps = [
    {
      key: "kyc-check",
      title: "KYC Status",
      icon: faFlag,
      content: (
        <Form onSubmit={handleKycNext}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formPan">
                <Form.Label>PAN*</Form.Label>
                <Form.Control
                  type="text"
                  name="pan"
                  value={kycData.pan}
                  onChange={(e) => {
                    setKycData({ ...kycData, pan: e.target.value });
                    handleKycInputChange(e);
                  }}
                  isInvalid={!!kycErrors.pan}
                  required
                  minLength={10}
                  maxLength={10}
                />
                <Form.Control.Feedback type="invalid">
                  {kycErrors.pan}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formDob">
                <Form.Label>Date of Birth*</Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  value={kycData.dob}
                  onChange={handleKycInputChange}
                  isInvalid={!!kycErrors.dob}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {kycErrors.dob}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Button
              className="base-button"
              type="submit"
              disabled={!isKycFormValid()}
            >
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </Button>
          </Row>
        </Form>
      ),
    },
    {
      key: "basic-profile",
      title: "Profile",
      icon: faUserCircle,
      content: (
        <Form onSubmit={handleBasicInfoNext}>
          <Row className="mb-form">
            <Col md={12}>
              <Form.Label>Full Name*</Form.Label>
            </Col>
            <Col md={4}>
              <Form.Control
                type="text"
                name="FirstName"
                value={formData1.FirstName || ""}
                onChange={handleFirstNameChange}
                placeholder="First Name"
                maxLength={50}
                isInvalid={!!errors.FirstName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.FirstName}
              </Form.Control.Feedback>
            </Col>
            <Col md={4}>
              <Form.Control
                type="text"
                name="MiddleName"
                value={formData1.MiddleName}
                onChange={handleMiddleNameChange}
                placeholder="Middle Name"
                maxLength={50}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="text"
                name="LastName"
                value={formData1.LastName || ""}
                onChange={handleLastNameChange}
                placeholder="Last Name"
                maxLength={50}
                isInvalid={!!errors.LastName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.LastName}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} md={6} controlId="formGridResidentialStatus">
              <Form.Label>Residential Status*</Form.Label>
              <Form.Control
                as="select"
                name="ResidentialStatus"
                value={formData1.ResidentialStatus || ""}
                onChange={changeHandlerResidentialStatus}
                required
                isInvalid={!!errors.ResidentialStatus}
              >
                <option value="">Select</option>
                {residentialStatus.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.ResidentialStatus}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="formGridEmail">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                name="Email"
                value={email}
                readOnly
                // isInvalid={!isValidEmail}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.Email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <h4>PERSONAL INFO</h4>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridGender">
              <Form.Label>Gender*</Form.Label>
              <Form.Control
                as="select"
                name="Gender"
                value={formData.Gender}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.Gender}
              >
                <option value="">Select</option>
                {genderOptions.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.Gender}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridDateOfBirth">
              <Form.Label>Date Of Birth*</Form.Label>
              <Form.Control
                type="date"
                name="DateOfBirth"
                value={formData.DateOfBirth}
                placeholder="Enter your date of birth"
                onChange={handleInputChange}
                isInvalid={!!errors.DateOfBirth}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.DateOfBirth}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridMaritalStatus">
              <Form.Label>Marital Status*</Form.Label>
              <Form.Control
                as="select"
                name="MaritalStatus"
                value={formData.MaritalStatus}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.MaritalStatus}
              >
                <option value="">Select</option>
                {maritalOptions.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.MaritalStatus}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCityOfBirth">
              <Form.Label>City Of Birth*</Form.Label>
              <Form.Control
                type="text"
                name="CityOfBirth"
                value={formData.CityOfBirth}
                onChange={handleInputChange}
                placeholder="Enter Your City of Birth"
                maxLength={50}
                isInvalid={!!errors.CityOfBirth}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.CityOfBirth}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridCitizenship">
              <Form.Label>Citizenship*</Form.Label>
              <Form.Control
                as="select"
                name="Citizenship"
                value={formData.Citizenship}
                onChange={handleFieldChange}
                required
                isInvalid={!!errors.Citizenship}
              >
                <option value="">Select</option>
                {countries.map((country) => (
                  <option key={country.CountryId} value={country.CountryId}>
                    {country.Name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.Citizenship}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridOccupationType">
              <Form.Label>Occupation Type*</Form.Label>
              <Form.Control
                as="select"
                name="OccupationType"
                value={formData.OccupationType}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.OccupationType}
              >
                <option value="">Select</option>
                {occupationType.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.OccupationType}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridMobileNumber">
              <Form.Label>Mobile Number*</Form.Label>
              <PhoneInput
                country="IN"
                name="MobileNumber"
                className={`form-control ${
                  errors.MobileNumber ? "is-invalid" : ""
                }`}
                value={formData.MobileNumber}
                minLength={10}
                maxLength={15}
                required
                onChange={(value) =>
                  handleInputChange({
                    target: { name: "MobileNumber", value },
                  })
                }
              />
              <Form.Check
                type="checkbox"
                id="whatsappCheckbox"
                className="whatsappCheckboxText"
                label="This is my WhatsApp number"
                checked={formData.isWhatsappNumber}
                onChange={handleSwitchChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.MobileNumber}
              </Form.Control.Feedback>
            </Form.Group>
            {!formData.isWhatsappNumber && (
              <Form.Group as={Col} controlId="formGridWhatsappNumber">
                <Form.Label>WhatsApp Number</Form.Label>
                <PhoneInput
                  country="IN"
                  name="whatsappNumber"
                  className={`form-control ${
                    errors.whatsappNumber ? "is-invalid" : ""
                  }`}
                  value={formData.whatsappNumber}
                  minLength={10}
                  maxLength={15}
                  onChange={handleWhatsappNumberChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.whatsappNumber}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridIncome">
              <Form.Label>Income*</Form.Label>
              <Form.Control
                as="select"
                name="Income"
                value={formData.Income}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.Income}
              >
                <option value="">Select</option>
                {incomeRange.map((option) => (
                  <option
                    key={option.IncomeRangeId}
                    value={option.IncomeRangeId}
                  >
                    {option.IncomeRange}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.Income}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridIdentityType">
              <Form.Label>Identity Type*</Form.Label>
              <Form.Control
                as="select"
                name="IdentityType"
                value={formData.IdentityType}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.IdentityType}
              >
                <option value="">Select</option>
                <option value="Passport">Passport</option>
                {/* <option value="Voter ID">Voter ID</option>
                <option value="Aadhaar">Aadhaar</option> */}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.IdentityType}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridIdentityNumber">
              <Form.Label>Identity Number*</Form.Label>
              <Form.Control
                type="text"
                name="IdentityNumber"
                value={formData.IdentityNumber}
                onChange={handleInputChange}
                placeholder="Enter Your Identity Number"
                maxLength={20}
                required
                isInvalid={!!errors.IdentityNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.IdentityNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridDocument">
              <Form.Label>Document*</Form.Label>
              <Form.Control
                type="file"
                name="Document"
                onChange={handleFileChange}
                required={!userData?.memberProfile?.Document}
                isInvalid={!!fileErrorMessage}
                disabled={!!userData?.memberProfile?.Document}
              />
              <Form.Control.Feedback type="invalid">
                {fileErrorMessage}
              </Form.Control.Feedback>
              {userData?.memberProfile?.Document && (
                <span className="badge bg-success">Submitted</span>
              )}
            </Form.Group>
          </Row>
          <h4>CONTACT INFO</h4>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridAddress1">
              <Form.Label>Address*</Form.Label>
              <Form.Control
                type="text"
                name="Address1"
                value={formData.Address1}
                onChange={handleInputChange}
                placeholder="Enter your address Line 1"
                maxLength={100}
                required
                isInvalid={!!errors.Address1}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Address1}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAddress2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                name="Address2"
                value={formData.Address2}
                onChange={handleInputChange}
                placeholder="Enter your address Line 2"
                maxLength={100}
              />
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>City*</Form.Label>
              <Form.Control
                type="text"
                name="City"
                value={formData.City}
                onChange={handleInputChange}
                placeholder="Enter your city"
                maxLength={50}
                required
                isInvalid={!!errors.City}
              />
              <Form.Control.Feedback type="invalid">
                {errors.City}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>State*</Form.Label>
              <Form.Control
                type="text"
                name="State"
                value={formData.State}
                onChange={handleInputChange}
                placeholder="Enter your state"
                required
                isInvalid={!!errors.State}
              />
              <Form.Control.Feedback type="invalid">
                {errors.State}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridZipCode">
              <Form.Label>Zip Code*</Form.Label>
              <Form.Control
                type="text"
                name="ZipCode"
                value={formData.ZipCode}
                onChange={handleInputChange}
                placeholder="Enter your zip code"
                minLength={6}
                maxLength={6}
                required
                isInvalid={!!errors.ZipCode}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ZipCode}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCountry">
              <Form.Label>Country*</Form.Label>
              <Form.Control
                as="select"
                name="Country"
                value={formData.Country}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.Country}
              >
                <option value="">Select</option>
                {countries.map((country) => (
                  <option key={country.CountryId} value={country.CountryId}>
                    {country.Name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.Country}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Button className="base-button" type="submit">
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </Button>
          </Row>
        </Form>
      ),
    },
    {
      key: "kyc-document-upload",
      title: "KYC Document",
      icon: faFile,
      content: (
        <Form onSubmit={handleKycDocumentUpload}>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              <Button style={{ backgroundColor: "#416052" }} disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
            </div>
          )}
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formPassportNumber">
              <Form.Label>Passport Number</Form.Label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Passport Number"
                onChange={handlePassportNumber}
                value={
                  userkycdata?.Passport &&
                  userkycdata.Passport[0]?.PassportNumber
                }
                maxLength={9}
                required
                readOnly={
                  userkycdata?.Passport &&
                  userkycdata.Passport[0]?.PassportNumber
                    ? true
                    : false
                }
              />
            </Form.Group>
          </Row>

          <Row className="mb-form">
            <Form.Group as={Col} controlId="formPassport">
              <Form.Label>Upload Passport Front</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={handlePassportFrontChange}
                required={!userkycdata?.Passport?.[0]?.PassportFrontFile}
                disabled={Boolean(
                  userkycdata?.Passport?.[0]?.PassportFrontFile
                )}
              />
              {!isSubmitting && selectedPassportFront && (
                <Button
                  variant="link"
                  onClick={() =>
                    handleShowPreview(
                      URL.createObjectURL(selectedPassportFront)
                    )
                  }
                >
                  <FontAwesomeIcon icon={faFileImage} /> Preview
                </Button>
              )}
              {!isSubmitting &&
                userkycdata?.Passport?.[0]?.PassportFrontFile &&
                presignedUrls.Passport &&
                presignedUrls.Passport[0] && (
                  <Button
                    variant="link"
                    onClick={() => handleShowPreview(presignedUrls.Passport[0])}
                  >
                    <FontAwesomeIcon icon={faFileImage} /> Preview
                  </Button>
                )}
            </Form.Group>
            <Form.Group as={Col} controlId="formPassport">
              <Form.Label>Upload Passport Back</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={handlePassportBackChange}
                required={!userkycdata?.Passport?.[0]?.PassportBackFile}
                disabled={Boolean(userkycdata?.Passport?.[0]?.PassportBackFile)}
              />
              {!isSubmitting && selectedPassportBack && (
                <Button
                  variant="link"
                  onClick={() =>
                    handleShowPreview(URL.createObjectURL(selectedPassportBack))
                  }
                >
                  <FontAwesomeIcon icon={faFileImage} /> Preview
                </Button>
              )}
              {!isSubmitting &&
                userkycdata?.Passport?.[0]?.PassportBackFile &&
                presignedUrls.Passport &&
                presignedUrls.Passport[1] && (
                  <Button
                    variant="link"
                    onClick={() => handleShowPreview(presignedUrls.Passport[1])}
                  >
                    <FontAwesomeIcon icon={faFileImage} /> Preview
                  </Button>
                )}
            </Form.Group>
          </Row>

          <Row className="mb-form">
            <Form.Group as={Col} controlId="formPAN">
              <Form.Label>PAN* &nbsp;</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter PAN Number"
                onChange={handlePandiv}
                value={userkycdata?.PanCard?.[0]?.PanNumber || pan}
                maxLength={10}
                required
                readOnly={Boolean(userkycdata?.PanCard?.[0]?.PanNumber)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formPassport">
              <Form.Label>
                Upload Pan Card{" "}
                <span className="text-muted small">
                  (The file size must be less than 5 MB. Accepted formats are
                  JPG, JPEG, PDF, and PNG.)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={handlePandivChange}
                required={!userkycdata?.PanCard?.[0]?.PancardFile}
                disabled={Boolean(userkycdata?.PanCard?.[0]?.PancardFile)}
              />
              {!isSubmitting && selectedPandiv && (
                <Button
                  variant="link"
                  onClick={() => handleShowPreview(selectedPandiv)}
                >
                  <FontAwesomeIcon icon={faFileImage} /> Preview
                </Button>
              )}
              {!isSubmitting &&
                userkycdata?.PanCard?.[0]?.PancardFile &&
                presignedUrls.PanCard &&
                presignedUrls.PanCard[0] && (
                  <Button
                    variant="link"
                    onClick={() => handleShowPreview(presignedUrls.PanCard[0])}
                  >
                    <FontAwesomeIcon icon={faFileImage} /> Preview
                  </Button>
                )}
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formIndianAddressProof">
              <Form.Label>
                Indian Address Proof* &nbsp;
                <span className="text-muted small">
                  (The file size must be less than 5 MB. Accepted formats are
                  JPG, JPEG, PDF, and PNG.)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={handleIndianAddressProofdChange}
                required={!userkycdata?.IndianAddressProof?.length}
                disabled={Boolean(userkycdata?.IndianAddressProof?.length)}
              />
              {!isSubmitting && selectedIndianAddressProof && (
                <Button
                  variant="link"
                  onClick={() => handleShowPreview(selectedIndianAddressProof)}
                >
                  <FontAwesomeIcon icon={faFileImage} /> Preview
                </Button>
              )}
              {!isSubmitting &&
                userkycdata?.IndianAddressProof?.length &&
                presignedUrls.IndianAddressProof &&
                presignedUrls.IndianAddressProof[0] && (
                  <Button
                    variant="link"
                    onClick={() =>
                      handleShowPreview(presignedUrls.IndianAddressProof[0])
                    }
                  >
                    <FontAwesomeIcon icon={faFileImage} /> Preview
                  </Button>
                )}
            </Form.Group>
            <Form.Group as={Col} controlId="formForeignAddressProof">
              <Form.Label>
                Foreign Address Proof* &nbsp;
                <span className="text-muted small">
                  (The file size must be less than 5 MB. Accepted formats are
                  JPG, JPEG, PDF, and PNG.)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={handleForeignAddressProofdChange}
                required={!userkycdata?.ForeignAddressProof?.length}
                disabled={Boolean(userkycdata?.ForeignAddressProof?.length)}
              />
              {!isSubmitting && selectedForeignAddressProof && (
                <Button
                  variant="link"
                  onClick={() => handleShowPreview(selectedForeignAddressProof)}
                >
                  <FontAwesomeIcon icon={faFileImage} /> Preview
                </Button>
              )}
              {userkycdata?.ForeignAddressProof?.length &&
                presignedUrls.ForeignAddressProof &&
                presignedUrls.ForeignAddressProof[0] && (
                  <Button
                    variant="link"
                    onClick={() =>
                      handleShowPreview(presignedUrls.ForeignAddressProof[0])
                    }
                  >
                    <FontAwesomeIcon icon={faFileImage} /> Preview
                  </Button>
                )}
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formCheque">
              <Form.Label>
                NRO/NRE Cheque* &nbsp;
                <span className="text-muted small">
                  (The file size must be less than 5 MB. Accepted formats are
                  JPG, JPEG, PDF, and PNG.)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={handleChequeChange}
                required={!userkycdata?.Cheque?.length}
                disabled={Boolean(userkycdata?.Cheque?.length)}
              />
              {!isSubmitting && selectedCheque && (
                <Button
                  variant="link"
                  onClick={() => handleShowPreview(selectedCheque)}
                >
                  <FontAwesomeIcon icon={faFileImage} /> Preview
                </Button>
              )}
              {userkycdata?.Cheque?.length &&
                presignedUrls.Cheque &&
                presignedUrls.Cheque[0] && (
                  <Button
                    variant="link"
                    onClick={() => handleShowPreview(presignedUrls.Cheque[0])}
                  >
                    <FontAwesomeIcon icon={faFileImage} /> Preview
                  </Button>
                )}
            </Form.Group>
            <Form.Group as={Col} controlId="formTaxIdentificationNumber">
              <Form.Label>
                Tax Identification Number* &nbsp;
                <span className="text-muted small">
                  (The Tax Identification Number must be 11 digits.)
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Tax Identification Number"
                onChange={handleTaxIdentificationNumber}
                value={
                  userkycdata?.TaxIdentification?.[0] ||
                  TaxIndentification.TaxIdentificationNumber
                }
                maxLength={11}
                required
                readOnly={Boolean(userkycdata?.TaxIdentification?.length)}
                onBlur={handleTaxIdentificationNumberBlur}
              />
            </Form.Group>
          </Row>
          <Row>
            <Button className="base-button" type="submit">
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </Button>
          </Row>
        </Form>
      ),
    },
    {
      key: "bank-details",
      title: "Bank Detials",
      icon: faBuilding,
      content: (
        <Form onSubmit={handleBankDetailsNext}>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridBankName">
              <Form.Label>Bank Name*</Form.Label>
              <Form.Control
                type="text"
                name="bankName"
                value={formBankData.bankName}
                onChange={handleBothChanges}
                placeholder="Enter Your Bank Name"
                maxLength={50}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridIfscCode">
              <Form.Label>IFSC Code*</Form.Label>
              <Form.Control
                type="text"
                name="ifscCode"
                value={formBankData.ifscCode}
                onChange={handleBothChanges}
                placeholder="Enter Your IFSC Code"
                maxLength={11}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridAccountType">
              <Form.Label>Account Type*</Form.Label>
              <Form.Control
                as="select"
                name="accountType"
                value={formBankData.accountType}
                onChange={handleBothChanges}
                required
              >
                <option value="">Select</option>
                <option value="savings">Savings</option>
                <option value="current">Current</option>
                <option value="nre">NRE</option>
                <option value="nro">NRO</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAccountNumber">
              <Form.Label>Account Number*</Form.Label>
              <Form.Control
                type="text"
                name="accountNumber"
                value={formBankData.accountNumber}
                onChange={handleBothChanges}
                placeholder="Enter Your Account Number"
                maxLength={20}
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Button className="base-button" type="submit">
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </Button>
          </Row>
        </Form>
      ),
    },
    {
      key: "nominee-details",
      title: "Add Nominee",
      icon: faUser,
      content: (
        <Form onSubmit={handleNomineeDetailsNext}>
          <Row className="mb-form">
            {nomineeForms.map((form, index) => (
              <React.Fragment key={index}>
                <Col md={3}>
                  <Form.Label>Nominee Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomineeName"
                    value={form.nomineeName}
                    onChange={(e) => handleNomineeChange(index, e)}
                    placeholder="Enter Nominee Name"
                    maxLength={50}
                    required
                  />
                </Col>
                <Col md={3}>
                  <Form.Label>Relation*</Form.Label>
                  <Form.Control
                    type="text"
                    name="relation"
                    value={form.relation}
                    onChange={(e) => handleNomineeChange(index, e)}
                    placeholder="Enter Relation"
                    maxLength={50}
                    required
                  />
                </Col>
                <Col md={3}>
                  <Form.Label>Date of Birth*</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateOfBirth"
                    value={form.dateOfBirth}
                    onChange={(e) => handleNomineeChange(index, e)}
                    required
                  />
                </Col>
                <Col md={2}>
                  <Form.Label>Percentage*</Form.Label>
                  <Form.Control
                    type="number"
                    name="percentage"
                    value={form.percentage}
                    onChange={(e) => handleNomineeChange(index, e)}
                    placeholder="Enter Percentage"
                    min={0}
                    max={100}
                    required
                  />
                </Col>
                <Col md={1} className="d-flex">
                  <Button
                    className="deleteButton"
                    onClick={() => removeNomineeForm(index)}
                  >
                    X
                  </Button>
                </Col>
              </React.Fragment>
            ))}
          </Row>
          <Button
            variant="primary"
            className="mb-4 addNominee"
            onClick={addNomineeForm}
            disabled={nomineeForms.length >= 5}
          >
            Add Nominee +
          </Button>
          <Row>
            <Button className="base-button" type="submit">
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </Button>
          </Row>
        </Form>
      ),
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const kycTrueSteps = [
    {
      key: "basic-profile",
      title: "Profile",
      icon: faUserCircle,
      content: (
        <Form onSubmit={handleBasicInfoNext}>
          <Row className="mb-form">
            <Col md={12}>
              <Form.Label>Full Name*</Form.Label>
            </Col>
            <Col md={4}>
              <Form.Control
                type="text"
                name="FirstName"
                value={formData1.FirstName || ""}
                onChange={handleFirstNameChange}
                placeholder="First Name"
                maxLength={50}
                isInvalid={!!errors.FirstName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.FirstName}
              </Form.Control.Feedback>
            </Col>
            <Col md={4}>
              <Form.Control
                type="text"
                name="MiddleName"
                value={formData1.MiddleName}
                onChange={handleMiddleNameChange}
                placeholder="Middle Name"
                maxLength={50}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="text"
                name="LastName"
                value={formData1.LastName || ""}
                onChange={handleLastNameChange}
                placeholder="Last Name"
                maxLength={50}
                isInvalid={!!errors.LastName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.LastName}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} md={6} controlId="formGridResidentialStatus">
              <Form.Label>Residential Status*</Form.Label>
              <Form.Control
                as="select"
                name="ResidentialStatus"
                value={formData1.ResidentialStatus || ""}
                onChange={changeHandlerResidentialStatus}
                required
                isInvalid={!!errors.ResidentialStatus}
              >
                <option value="">Select</option>
                {residentialStatus.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.ResidentialStatus}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="formGridEmail">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                name="Email"
                value={email}
                readOnly
                // isInvalid={!isValidEmail}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.Email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <h4>Personal Info</h4>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridGender">
              <Form.Label>Gender*</Form.Label>
              <Form.Control
                as="select"
                name="Gender"
                value={formData.Gender}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.Gender}
              >
                <option value="">Select</option>
                {genderOptions.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.Gender}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridDateOfBirth">
              <Form.Label>Date Of Birth*</Form.Label>
              <Form.Control
                type="date"
                name="DateOfBirth"
                value={formData.DateOfBirth}
                placeholder="Enter your date of birth"
                onChange={handleInputChange}
                isInvalid={!!errors.DateOfBirth}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.DateOfBirth}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridMaritalStatus">
              <Form.Label>Marital Status*</Form.Label>
              <Form.Control
                as="select"
                name="MaritalStatus"
                value={formData.MaritalStatus}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.MaritalStatus}
              >
                <option value="">Select</option>
                {maritalOptions.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.MaritalStatus}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCityOfBirth">
              <Form.Label>City Of Birth*</Form.Label>
              <Form.Control
                type="text"
                name="CityOfBirth"
                value={formData.CityOfBirth}
                onChange={handleInputChange}
                placeholder="Enter Your City of Birth"
                maxLength={50}
                isInvalid={!!errors.CityOfBirth}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.CityOfBirth}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridCitizenship">
              <Form.Label>Citizenship*</Form.Label>
              <Form.Control
                as="select"
                name="Citizenship"
                value={formData.Citizenship}
                onChange={handleFieldChange}
                required
                isInvalid={!!errors.Citizenship}
              >
                <option value="">Select</option>
                {countries.map((country) => (
                  <option key={country.CountryId} value={country.CountryId}>
                    {country.Name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.Citizenship}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridOccupationType">
              <Form.Label>Occupation Type*</Form.Label>
              <Form.Control
                as="select"
                name="OccupationType"
                value={formData.OccupationType}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.OccupationType}
              >
                <option value="">Select</option>
                {occupationType.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.OccupationType}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridMobileNumber">
              <Form.Label>Mobile Number*</Form.Label>
              <PhoneInput
                country="IN"
                name="MobileNumber"
                className={`form-control ${
                  errors.MobileNumber ? "is-invalid" : ""
                }`}
                value={formData.MobileNumber}
                minLength={10}
                maxLength={15}
                required
                onChange={(value) =>
                  handleInputChange({
                    target: { name: "MobileNumber", value },
                  })
                }
              />
              <Form.Check
                type="checkbox"
                id="whatsappCheckbox"
                className="whatsappCheckboxText"
                label="This is my WhatsApp number"
                checked={formData.isWhatsappNumber}
                onChange={handleSwitchChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.MobileNumber}
              </Form.Control.Feedback>
            </Form.Group>
            {!formData.isWhatsappNumber && (
              <Form.Group as={Col} controlId="formGridWhatsappNumber">
                <Form.Label>WhatsApp Number</Form.Label>
                <PhoneInput
                  country="IN"
                  name="whatsappNumber"
                  className={`form-control ${
                    errors.whatsappNumber ? "is-invalid" : ""
                  }`}
                  value={formData.whatsappNumber}
                  minLength={10}
                  maxLength={15}
                  onChange={handleWhatsappNumberChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.whatsappNumber}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridIncome">
              <Form.Label>Income*</Form.Label>
              <Form.Control
                as="select"
                name="Income"
                value={formData.Income}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.Income}
              >
                <option value="">Select</option>
                {incomeRange.map((option) => (
                  <option
                    key={option.IncomeRangeId}
                    value={option.IncomeRangeId}
                  >
                    {option.IncomeRange}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.Income}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridIdentityType">
              <Form.Label>Identity Type*</Form.Label>
              <Form.Control
                as="select"
                name="IdentityType"
                value={formData.IdentityType}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.IdentityType}
              >
                <option value="">Select</option>
                <option value="Passport">Passport</option>
                <option value="Voter ID">Voter ID</option>
                <option value="Aadhaar">Aadhaar</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.IdentityType}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridIdentityNumber">
              <Form.Label>Identity Number*</Form.Label>
              <Form.Control
                type="text"
                name="IdentityNumber"
                value={formData.IdentityNumber}
                onChange={handleInputChange}
                placeholder="Enter Your Identity Number"
                maxLength={20}
                required
                isInvalid={!!errors.IdentityNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.IdentityNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridDocument">
              <Form.Label>Document*</Form.Label>
              <Form.Control
                type="file"
                name="Document"
                onChange={handleFileChange}
                required={!userData?.memberProfile?.Document}
                isInvalid={!!fileErrorMessage}
                disabled={!!userData?.memberProfile?.Document}
              />
              <Form.Control.Feedback type="invalid">
                {fileErrorMessage}
              </Form.Control.Feedback>
              {userData?.memberProfile?.Document && (
                <span className="badge bg-success">Submitted</span>
              )}
            </Form.Group>
          </Row>
          <h4>Contact Info</h4>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridAddress1">
              <Form.Label>Address*</Form.Label>
              <Form.Control
                type="text"
                name="Address1"
                value={formData.Address1}
                onChange={handleInputChange}
                placeholder="Enter your address Line 1"
                maxLength={100}
                required
                isInvalid={!!errors.Address1}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Address1}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAddress2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                name="Address2"
                value={formData.Address2}
                onChange={handleInputChange}
                placeholder="Enter your address Line 2"
                maxLength={100}
              />
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>City*</Form.Label>
              <Form.Control
                type="text"
                name="City"
                value={formData.City}
                onChange={handleInputChange}
                placeholder="Enter your city"
                maxLength={50}
                required
                isInvalid={!!errors.City}
              />
              <Form.Control.Feedback type="invalid">
                {errors.City}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>State*</Form.Label>
              <Form.Control
                type="text"
                name="State"
                value={formData.State}
                onChange={handleInputChange}
                placeholder="Enter your state"
                required
                isInvalid={!!errors.State}
              />
              <Form.Control.Feedback type="invalid">
                {errors.State}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridZipCode">
              <Form.Label>Zip Code*</Form.Label>
              <Form.Control
                type="text"
                name="ZipCode"
                value={formData.ZipCode}
                onChange={handleInputChange}
                placeholder="Enter your zip code"
                minLength={6}
                maxLength={6}
                required
                isInvalid={!!errors.ZipCode}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ZipCode}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCountry">
              <Form.Label>Country*</Form.Label>
              <Form.Control
                as="select"
                name="Country"
                value={formData.Country}
                onChange={handleInputChange}
                required
                isInvalid={!!errors.Country}
              >
                <option value="">Select</option>
                {countries.map((country) => (
                  <option key={country.CountryId} value={country.CountryId}>
                    {country.Name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.Country}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Button className="base-button" type="submit">
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </Button>
          </Row>
        </Form>
      ),
    },
    {
      key: "bank-details",
      title: "Bank Details",
      icon: faBuilding,
      content: (
        <Form onSubmit={handleBankDetailsNext}>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridBankName">
              <Form.Label>Bank Name*</Form.Label>
              <Form.Control
                type="text"
                name="bankName"
                value={formBankData.bankName}
                onChange={handleBothChanges}
                placeholder="Enter Your Bank Name"
                maxLength={50}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridIfscCode">
              <Form.Label>IFSC Code*</Form.Label>
              <Form.Control
                type="text"
                name="ifscCode"
                value={formBankData.ifscCode}
                onChange={handleBothChanges}
                placeholder="Enter Your IFSC Code"
                maxLength={11}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-form">
            <Form.Group as={Col} controlId="formGridAccountType">
              <Form.Label>Account Type*</Form.Label>
              <Form.Control
                as="select"
                name="accountType"
                value={formBankData.accountType}
                onChange={handleBothChanges}
                required
              >
                <option value="">Select</option>
                <option value="savings">Savings</option>
                <option value="current">Current</option>
                <option value="nre">NRE</option>
                <option value="nro">NRO</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridAccountNumber">
              <Form.Label>Account Number*</Form.Label>
              <Form.Control
                type="text"
                name="accountNumber"
                value={formBankData.accountNumber}
                onChange={handleBothChanges}
                placeholder="Enter Your Account Number"
                maxLength={20}
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Button className="base-button" type="submit">
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </Button>
          </Row>
        </Form>
      ),
    },
    {
      key: "nominee-details",
      title: "Add Nominee",
      icon: faUser,
      content: (
        <Form onSubmit={handleNomineeDetailsNext}>
          <Row className="mb-form">
            {nomineeForms.map((form, index) => (
              <React.Fragment key={index}>
                <Col md={3}>
                  <Form.Label>Nominee Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomineeName"
                    value={form.nomineeName}
                    onChange={(e) => handleNomineeChange(index, e)}
                    placeholder="Enter Nominee Name"
                    maxLength={50}
                    required
                  />
                </Col>
                <Col md={3}>
                  <Form.Label>Relation*</Form.Label>
                  <Form.Control
                    type="text"
                    name="relation"
                    value={form.relation}
                    onChange={(e) => handleNomineeChange(index, e)}
                    placeholder="Enter Relation"
                    maxLength={50}
                    required
                  />
                </Col>
                <Col md={3}>
                  <Form.Label>Date of Birth*</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateOfBirth"
                    value={form.dateOfBirth}
                    onChange={(e) => handleNomineeChange(index, e)}
                    required
                  />
                </Col>
                <Col md={2}>
                  <Form.Label>Percentage*</Form.Label>
                  <Form.Control
                    type="number"
                    name="percentage"
                    value={form.percentage}
                    onChange={(e) => handleNomineeChange(index, e)}
                    placeholder="Enter Percentage"
                    min={0}
                    max={100}
                    required
                  />
                </Col>
                <Col md={1}>
                  <Button
                    variant="danger"
                    className="mt-4 pt-4"
                    onClick={() => removeNomineeForm(index)}
                  >
                    Remove
                  </Button>
                </Col>
              </React.Fragment>
            ))}
          </Row>
          <Button
            variant="primary"
            className="mb-4"
            onClick={addNomineeForm}
            disabled={nomineeForms.length >= 5}
          >
            Add Nominee
          </Button>
          <Row>
            <Button className="base-button" type="submit">
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Next"
              )}
            </Button>
          </Row>
        </Form>
      ),
    },
  ];
  const steps = useMemo(() => {
    if (kycStatus !== 1) {
      return baseSteps;
    } else {
      return kycTrueSteps;
    }
  }, [baseSteps, kycStatus, kycTrueSteps]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const stepIndex = steps.findIndex((step) => step.key === hash);
    if (stepIndex !== -1) {
      setCurrentStep(stepIndex);
    } else {
      setCurrentStep(0);
    }
  }, [steps]);

  const updateStepHash = (stepIndex) => {
    const step = steps[stepIndex];
    if (step) {
      window.location.hash = step.key;
      setCurrentStep(stepIndex);
    }
  };

  const updateProfileStatus = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };
      await axios.post(`${baseUrl}/kyc-initiated`, {}, config);
    } catch (error) {
      toast.error("Error updating profile status:", error);
    }
  };

  return (
    <Container className="profile-process-container">
      <Row>
        <Col>
          <Button variant="link" className="skip-button" onClick={handleSkip}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </Button>
        </Col>
      </Row>
      <Row className={`step-progess-row`}>
        <Col>
          <ul className="step-progress">
            {steps.map((step, index) => (
              <li
                key={step.key}
                className={index === currentStep ? "active" : ""}
                // onClick={() => updateStepHash(index)}
              >
                <FontAwesomeIcon icon={step.icon} /> {step.title}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <TransitionGroup>
            <CSSTransition
              key={steps[currentStep].key}
              timeout={500}
              classNames="step-content"
            >
              <div className="step-content">
                {steps[currentStep].key === "kyc-document-upload" && (
                  <Row className="mb-form align-items-center">
                    <Col md={12}>
                      <Form.Label>Photo*</Form.Label>
                    </Col>
                    <Col md={6}>
                      <div className="row justify-content-center">
                        {!showCamera && (
                          <div>
                            {userkycdata?.Photo &&
                            userkycdata.Photo[0]?.length > 0 ? (
                              <div className="submitted-text">
                                <Button
                                  className="btn btn-block"
                                  style={{
                                    backgroundColor: "#416052",
                                    width: "275px",
                                  }}
                                  disabled
                                >
                                  Submitted
                                </Button>
                              </div>
                            ) : (
                              <>
                                <Button
                                  onClick={cameraopen}
                                  className="btn btn-block"
                                  style={{
                                    backgroundColor: "#416052",
                                    width: "275px",
                                    fontWeight: "800",
                                  }}
                                >
                                  Capture Image
                                </Button>

                                <br />
                                <span style={{ fontWeight: 800 }}>
                                  Please make sure you have a white paper with
                                  your name on it.
                                </span>
                              </>
                            )}
                          </div>
                        )}
                        {showCamera ? (
                          imageSrc ? (
                            <div className="row justify-content-center">
                              <img src={image} alt="Captured" />
                              <Button
                                onClick={retake}
                                className="btn mt-2 btn-block"
                                style={{
                                  width: "125px",
                                  backgroundColor: "#416052",
                                  fontWeight: "800",
                                }}
                              >
                                Recapture
                              </Button>{" "}
                              &nbsp;
                              <Button
                                onClick={handleSubmitWebcam}
                                className="btn mt-2 btn-block"
                                style={{
                                  width: "125px",
                                  backgroundColor: "#416052",
                                  fontWeight: "800",
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          ) : (
                            <div className="row justify-content-center">
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  transform: "scaleX(-1)",
                                }}
                              />
                              <Button
                                onClick={capture}
                                className="btn mt-2 btn-block"
                                style={{
                                  width: "125px",
                                  backgroundColor: "#416052",
                                  fontWeight: "800",
                                }}
                              >
                                Capture
                              </Button>
                            </div>
                          )
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                )}
                {steps[currentStep].content}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Col>
      </Row>
      <Modal
        show={showPreviewModal}
        onHide={() => setShowPreviewModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>File Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={modalContent} width="100%" height="500px" />
        </Modal.Body>
      </Modal>

      <Modal
        show={showCameraModal}
        onHide={() => setShowCameraModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Capture Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {image ? (
            <div className="text-center">
              <img src={image} alt="Captured" />
              <Button
                onClick={retake}
                className="btn mt-2 btn-block"
                style={{
                  width: "125px",
                  backgroundColor: "#416052",
                  fontWeight: "800",
                }}
              >
                Recapture
              </Button>
              &nbsp;
              <Button
                onClick={handleSubmitWebcam}
                className="btn mt-2 btn-block"
                style={{
                  width: "125px",
                  backgroundColor: "#416052",
                  fontWeight: "800",
                }}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="text-center">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{
                  width: "100%",
                  height: "auto",
                  transform: "scaleX(-1)",
                }}
              />
              <Button
                onClick={capture}
                className="btn mt-2 btn-block"
                style={{
                  width: "125px",
                  backgroundColor: "#416052",
                  fontWeight: "800",
                }}
              >
                Capture
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ProfileProcess;
