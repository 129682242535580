import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

function BankAccount() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [userbankdata, setUserbankdata] = useState({});
  const [formBankData, setFormBankData] = useState({
    bankName: "",
    ifscCode: "",
    accountType: "",
    accountNumber: "",
  });

  useEffect(() => {
    const fetchBankData = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${baseUrl}/getBankData`, config);
        setUserbankdata(response.data);
        if (response.data?.bankDetails) {
          setFormBankData({
            bankName: response.data.bankDetails.Branch?.Name || "",
            ifscCode: response.data.bankDetails.Branch?.IFSCCode || "",
            accountType: response.data.bankDetails.AccountType || "",
            accountNumber: response.data.bankDetails.AccountNumber || "",
          });
        }
      } catch (error) {
        toast.error("Failed to fetch bank data. Please log in again.");
      } finally {
        setLoading(false);
      }
    };
    fetchBankData();
  }, [baseUrl, navigate]);

  const allDetailsPresent =
    formBankData.bankName &&
    formBankData.ifscCode &&
    formBankData.accountType &&
    formBankData.accountNumber;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormBankData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmitBankData = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (
      !formBankData.bankName ||
      !formBankData.ifscCode ||
      !formBankData.accountType ||
      !formBankData.accountNumber
    ) {
      toast.error("Please fill in all required fields.");
      setSubmitting(false);
      return;
    }

    try {
      const token = Cookies.get("token");
      const response = await fetch(`${baseUrl}/submitBankData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
        body: JSON.stringify(formBankData),
      });

      if (response.ok) {
        toast.success("Bank Details Submitted Successfully");
        setSubmitting(false);
        return true;
      } else {
        const responseData = await response.json();
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        }

        if (response.status === 400) {
          toast.error(responseData.error);
        } else {
          toast.error("An error occurred while submitting form data");
        }
        setSubmitting(false);
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
      toast.error("An error occurred while submitting form data");
      setSubmitting(false);
    }
  };

  return (
    <Container>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <Button disabled style={{ backgroundColor: "#416052" }}>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <div>
          <Row className="mb-form">
            <div className="form-data">
              <Form onSubmit={handleSubmitBankData}>
                <Row className="main-title">
                  <Col md={12}>
                    <h2>BANK ACCOUNT</h2>
                  </Col>
                </Row>
                <Row className="mb-form">
                  <Col md={6}>
                    <Form.Label>Bank Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="bankName"
                      value={formBankData.bankName}
                      onChange={handleChange}
                      placeholder="Enter your Bank Account Name"
                      minLength={5}
                      maxLength={50}
                      required
                      readOnly={allDetailsPresent}
                    ></Form.Control>
                  </Col>
                  <Col md={6}>
                    <Form.Label>IFSC Code*</Form.Label>
                    <Form.Control
                      type="text"
                      name="ifscCode"
                      value={formBankData.ifscCode}
                      onChange={handleChange}
                      placeholder="Enter your IFSC Code"
                      minLength={11}
                      maxLength={12}
                      required
                      readOnly={allDetailsPresent}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row className="mb-form">
                  <Col md={6}>
                    <Form.Label>Account Type*</Form.Label>
                    <Form.Control
                      type="text"
                      name="accountType"
                      value={formBankData.accountType}
                      onChange={handleChange}
                      placeholder="Account Type"
                      required
                      readOnly={allDetailsPresent}
                    ></Form.Control>
                  </Col>
                  <Col md={6}>
                    <Form.Label>Account Number*</Form.Label>
                    <Form.Control
                      type="text"
                      name="accountNumber"
                      value={formBankData.accountNumber}
                      onChange={handleChange}
                      placeholder="Enter your Account Number"
                      minLength={9}
                      maxLength={18}
                      required
                      readOnly={allDetailsPresent}
                    ></Form.Control>
                  </Col>
                </Row>
                {/* <Button
                  type="submit"
                  className={`submitButton`}
                  variant="primary"
                  disabled={allDetailsPresent || submitting}
                >
                  {submitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Update"
                  )}
                </Button> */}
              </Form>
            </div>
          </Row>
        </div>
      )}
    </Container>
  );
}

export default BankAccount;
