import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import background from "../images/bgx.jpg";

const AccountConfirmationPage = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const navigate = useNavigate();

  const extractTokenFromUrl = () => {
    const currentUrl = window.location.href;
    const tokenIndex = currentUrl.indexOf("token?");
    const tokenPart = currentUrl.substring(tokenIndex + "token?".length);
    const ampersandIndex = tokenPart.indexOf("&");
    return ampersandIndex !== -1
      ? tokenPart.substring(0, ampersandIndex)
      : tokenPart;
  };

  useEffect(() => {
    const token = extractTokenFromUrl();
    if (token) {
      handleConfirmation(token);
    } else {
      toast.error("Invalid or missing token");
      navigate("/sign-in");
    }
  }, [navigate]);

  const handleConfirmation = async (token) => {
    try {
      const response = await axios.post(`${baseUrl}/verify_account`, { token });
      if (response.data.success) {
        toast.success("Account Verified Successfully. Please login.");
        setConfirmed(true);
        navigate("/sign-in");
      } else {
        toast.error("Account verification failed");
        navigate("/sign-in");
      }
    } catch (error) {
      toast.error("Account verification failed");
      navigate("/sign-in");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container
        style={{
          // backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        minHeight: "100vh",
        marginTop: "-20px",
        marginBottom: "-20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {confirmed && (
        <Row className="justify-content-center">
          <Col md={6}>
            <Card body className="form-data login shadow">
              <h3>Your account has been verified successfully.</h3>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AccountConfirmationPage;
