import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authContext";
import Cookies from "js-cookie";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, isLoading, validateToken } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      validateToken(token);
    }
  }, [validateToken]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
