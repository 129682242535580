import { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import validator from "validator";
import axios from "axios";
import { BiHide, BiShow } from "react-icons/bi";
import PhoneInput from "react-phone-number-input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "./authStyles.css";
import Cookies from "js-cookie";
import Loader from "../../../feature/loader/loader";

function Onboarding({ switchMode }) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [strength, setStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showIndicator, setShowIndicator] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const containerClasses = location.pathname.includes("/onboarding")
    ? "mt-5 pt-5"
    : "";

  const options = [
    {
      id: 1,
      value: "Overseas Citizenship of India",
      label: "Overseas Citizenship of India",
    },
    { id: 2, value: "Non-Resident Indian", label: "Non-Resident Indian" },
    { id: 3, value: "Indian Citizen", label: "Indian Citizen" },
  ];

  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    ResidentialStatus: "",
    Country: "",
    Email: "",
    Password: "",
    MobileNumber: "",
    isWhatsappNumber: false,
    whatsappNumber: "",
  });

  useEffect(() => {
    axios
      .get(`${baseUrl}/countries`)
      .then((response) =>
        setCountries(
          response.data.map((country) => ({
            value: country.CountryId,
            label: country.Name,
          }))
        )
      )
      .catch((error) => console.error("Error fetching countries:", error));
  }, [baseUrl]);

  useEffect(() => {
    const checkFormValidity = () => {
      const isValid =
        formData.FirstName?.trim() &&
        formData.LastName?.trim() &&
        validator.isEmail(formData.Email) &&
        !emailExists &&
        formData.Password &&
        strength >= 3 &&
        !formErrors.FirstName &&
        !formErrors.LastName;
      setFormValid(isValid);
    };
    checkFormValidity();
  }, [formData, emailExists, strength, formErrors]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (!value?.trim() && type !== "checkbox") {
      setFormErrors((prev) => ({ ...prev, [name]: "This field is required" }));
    } else {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleEmailChange = async (event) => {
    const email = event.target.value;
    setFormData((prev) => ({ ...prev, Email: email }));

    if (!validator.isEmail(email)) {
      setFormErrors((prev) => ({ ...prev, Email: "Invalid email address" }));
    } else {
      try {
        const response = await axios.get(
          `${baseUrl}/email-check?Email=${email}`
        );
        setEmailExists(response.data.EmailExists);
        setFormErrors((prev) => ({
          ...prev,
          Email: response.data.EmailExists ? "Email already exists" : "",
        }));
      } catch (error) {
        console.error("Error checking email:", error);
      }
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFormData((prev) => ({ ...prev, Country: selectedOption.value }));
  };

  const validateField = (name, value) => {
    let errorMsg = "";
    switch (name) {
      case "MobileNumber":
        if (!value) errorMsg = "This field is required";
        if (!validator.isMobilePhone(value)) errorMsg = "Invalid mobile number";
        break;
      case "whatsappNumber":
        if (!formData.isWhatsappNumber && !validator.isMobilePhone(value))
          errorMsg = "Invalid WhatsApp number";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };

  const handleWhatsappNumberChange = (value) => {
    setFormData((prevData) => ({ ...prevData, whatsappNumber: value || "" }));
  };

  const handleSwitchChange = (event) => {
    const { checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      isWhatsappNumber: checked,
      whatsappNumber: checked ? prevData.MobileNumber : prevData.whatsappNumber,
    }));
  };

  const calculatePasswordStrength = (password) => {
    let score = 0;
    if (password.length >= 8) score++;
    if (/\d/.test(password)) score++;
    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) score++;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) score++;
    setStrength(score);
    if (score >= 4) {
      setShowIndicator(false);
    } else {
      setShowIndicator(true);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showIndicator) {
        const strengthIndicator = document.getElementById("strength-indicator");
        if (strengthIndicator && !strengthIndicator.contains(event.target)) {
          setShowIndicator(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showIndicator]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);

    const requiredFields = [
      "FirstName",
      "LastName",
      "ResidentialStatus",
      "Country",
      "Email",
      "Password",
      "MobileNumber",
    ];

    let hasErrors = false;

    requiredFields.forEach((field) => {
      const value = formData[field];
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "This field is required",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
    });

    if (!formData.isWhatsappNumber && !formData.whatsappNumber) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        whatsappNumber: "This field is required",
      }));
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }

    try {
      const formDataWithWhatsapp = {
        ...formData,
        whatsappNumber: formData.isWhatsappNumber
          ? formData.MobileNumber
          : formData.whatsappNumber,
      };

      if (formValid) {
        setIsLoading(true);
        axios
          .post(`${baseUrl}/signup`, formDataWithWhatsapp)
          .then(async ({ data }) => {
            const userData = data.userData;
            sessionStorage.setItem("userData", JSON.stringify(userData));
            toast.success(
              "Account registered successfully. Please check your email for verification."
            );

            // Check KYC status
            const kycResponse = await axios.get(`${baseUrl}/getUserData`, {
              headers: { WRGSJGEUWCQN58EGV92D: `Bearer ${data.token}` },
            });
            const kycStatus = kycResponse.data.members?.KYCStatus || 0;

            if (kycStatus === 1 || kycStatus === 3) {
              navigate("/dashboard");
            } else {
              navigate("/profile-process#kyc-check");
            }
          })
          .catch((error) => {
            const errorMessage =
              error.response?.data?.error || "An unexpected error occurred";
            toast.error(` ${errorMessage}`);
            console.error("Error during registration:", error);
          })
          .finally(() => setIsLoading(false)); // Hide loader
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "An unexpected error occurred";
      toast.error(` ${errorMessage}`);
      setIsLoading(false); // Hide loader
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      navigate("/loading");
    }
  }, [navigate]);

  const [sessionsformData, setSessionsFormData] = useState({
    status: "",
    platform: "",
    deviceName: "",
    locationIp: "",
  });

  useEffect(() => {
    createAndSendUserSessionData();
  }, []);

  const formatDeviceName = () => {
    const userAgent = navigator.userAgent;
    const matches = userAgent.match(/\(([^)]+)\)/);
    return matches ? matches[1] : "Unknown";
  };

  const getIPAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      if (response.ok) {
        const data = await response.json();
        return data.ip;
      } else {
        console.error("Failed to fetch IP address, using default IP");
        return "0.0.0.0"; // Default IP address
      }
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "0.0.0.0"; // Default IP address
    }
  };

  const isAppEnvironment = () => {
    return typeof window.MyAppBridge !== "undefined";
  };

  const createAndSendUserSessionData = async () => {
    const status = "active";
    const platform = isAppEnvironment() ? "app" : "web";
    const deviceName = formatDeviceName();
    const locationIp = await getIPAddress();

    setSessionsFormData({
      status,
      platform,
      deviceName,
      locationIp,
    });
  };

  const handleLoginWithGoogle = async () => {
    try {
      await createAndSendUserSessionData();
      const { status, platform, deviceName, locationIp } = sessionsformData;
      const redirectUrl = `${baseUrl}/auth/google?status=${status}&platform=${platform}&deviceName=${deviceName}&locationIp=${locationIp}`;

      const newWindow = window.open(
        redirectUrl,
        "GoogleLogin",
        "width=600,height=600"
      );

      const receiveMessage = (event) => {
        if (event.origin !== baseUrl) return;
        if (event.data.success) {
          newWindow.close();
          toast.success("Login successful!");
        } else {
          toast.error("Google login failed.");
        }
        window.removeEventListener("message", receiveMessage);
      };

      window.addEventListener("message", receiveMessage);
    } catch (error) {
      console.error("Error in handleLogin:", error);
    }
  };

  const handleLoginClick = () => {
    if (switchMode) {
      switchMode("signin");
    } else {
      navigate("/sign-in");
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Container className={containerClasses}>
      <Row className="align-items-center">
        <h3 className={`text-center mt-4 formHeading`}>
          Unlock India's Potential
        </h3>
        <div body className="formComponent">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-form">
              <Col md={6} className={`formInputItem`}>
                <Form.Group className="mb-1" controlId="fname">
                  <Form.Label>
                    First Name<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="FirstName"
                    value={formData.FirstName}
                    onChange={handleInputChange}
                    isinvalid={!!formErrors.FirstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.FirstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-1" controlId="lname">
                  <Form.Label>
                    Last Name<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="LastName"
                    value={formData.LastName}
                    onChange={handleInputChange}
                    isinvalid={!!formErrors.LastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.LastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-form">
              <Col md={6}>
                <Form.Group
                  className="mb-1 react-select"
                  controlId="residential"
                >
                  <Form.Label>
                    Residential Status<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Select
                    options={options}
                    value={options.find(
                      (option) => option.value === formData.ResidentialStatus
                    )}
                    onChange={(option) =>
                      setFormData((prev) => ({
                        ...prev,
                        ResidentialStatus: option.value,
                      }))
                    }
                    name="ResidentialStatus"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-1 react-select" controlId="country">
                  <Form.Label>
                    Country<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Select
                    options={countries}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    name="Country"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-form p-0 m-0">
              <Col md={6}>
                <Form.Group controlId="formGridMobileNumber">
                  <Form.Label>Mobile Number*</Form.Label>
                  <PhoneInput
                    country="IN"
                    name="MobileNumber"
                    className={`form-control ${
                      errors.MobileNumber ? "is-invalid" : ""
                    }`}
                    value={formData.MobileNumber}
                    minLength={10}
                    maxLength={15}
                    required
                    onChange={(value) =>
                      handleInputChange({
                        target: { name: "MobileNumber", value },
                      })
                    }
                  />
                  <Form.Check
                    type="checkbox"
                    id="whatsappCheckbox"
                    className="whatsappCheckboxText"
                    label="This is my WhatsApp number"
                    checked={formData.isWhatsappNumber}
                    onChange={handleSwitchChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.MobileNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {!formData.isWhatsappNumber && (
                <Col md={6}>
                  <Form.Group controlId="formGridWhatsappNumber">
                    <Form.Label>WhatsApp Number</Form.Label>
                    <PhoneInput
                      country="IN"
                      name="whatsappNumber"
                      className={`form-control ${
                        errors.whatsappNumber ? "is-invalid" : ""
                      }`}
                      value={formData.whatsappNumber}
                      minLength={10}
                      maxLength={15}
                      onChange={handleWhatsappNumberChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.whatsappNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row className="mb-form">
              <Col md={12}>
                <Form.Group className="mb-1" controlId="email">
                  <Form.Label>
                    Email Address<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    required
                    name="Email"
                    value={formData.Email}
                    onChange={handleEmailChange}
                    isinvalid={!!formErrors.Email || emailExists}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.Email ||
                      (emailExists && "Email already exists")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-1 psw-filed" controlId="password">
                  <Form.Label>
                    Create Password<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      required
                      name="Password"
                      value={formData.Password}
                      onChange={(e) => {
                        handleInputChange(e);
                        calculatePasswordStrength(e.target.value);
                      }}
                      maxLength={50}
                    />
                    <span
                      className="psw-icon"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "38%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <BiShow size={30} />
                      ) : (
                        <BiHide size={30} />
                      )}
                    </span>
                    {formData.Password.length > 0 && showIndicator && (
                      <div
                        style={{
                          position: "absolute",
                          fontSize: "small",
                          top: "100%",
                          left: "4px",
                          right: "4px",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <div style={{ color: strength >= 3 ? "green" : "red" }}>
                          <span>
                            {strength === 0 && "Too short"}
                            {strength === 1 && "Weak"}
                            {strength === 2 && "Good"}
                            {strength === 3 && "Strong"}
                            {strength === 4 && "Very strong"}
                          </span>
                        </div>
                        <div style={{ color: "grey", fontSize: "10px" }}>
                          <strong>Strong Password Tips:</strong>
                          <ul>
                            <li
                              style={{
                                color:
                                  formData.Password.length >= 8
                                    ? "green"
                                    : "red",
                              }}
                            >
                              At least 8 characters
                            </li>
                            <li
                              style={{
                                color: /\d/.test(formData.Password)
                                  ? "green"
                                  : "red",
                              }}
                            >
                              At least one number
                            </li>
                            <li
                              style={{
                                color:
                                  /[a-z]/.test(formData.Password) &&
                                  /[A-Z]/.test(formData.Password)
                                    ? "green"
                                    : "red",
                              }}
                            >
                              At least one lowercase & one uppercase letter
                            </li>
                            <li
                              style={{
                                color: /[!@#$%^&*(),.?":{}|<>]/.test(
                                  formData.Password
                                )
                                  ? "green"
                                  : "red",
                              }}
                            >
                              At least one special character
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p className="small-text mt-3 text-center">
                  By clicking Create Account, I accept{" "}
                  <Link to="/terms&conditions">Terms</Link> and{" "}
                  <Link to="/user-policy">User Policy</Link>
                </p>
                <button
                  type="submit"
                  className={`submitButton d-block ${
                    !formValid ? "disabled" : ""
                  }`}
                  disabled={!formValid}
                >
                  Create Account
                </button>
              </Col>
            </Row>
            <Row>
              <div className="text-center">
                <p className={`d-flex justify-content-center m-1`}>or</p>
                <Col md={12} className="d-flex justify-content-center">
                  <div
                    className={`googleButton my-3`}
                    onClick={handleLoginWithGoogle}
                  >
                    <span>
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src="/images/googleIcon.svg"
                        alt="login with google"
                      />
                      Sign up with Google
                    </span>
                  </div>
                </Col>
              </div>
            </Row>
            <Row>
              <div className="text-center">
                <p className={`d-flex justify-content-center m-1`}>
                  Already have account?
                </p>
                <Col md={12} className="d-flex justify-content-center">
                  <Col className="arrowRightSvg" onClick={handleLoginClick}>
                    <span className={`toSignIn`}>Login to your account</span>
                    <svg width="13px" height="10px" viewBox="0 0 13 10">
                      <path d="M1,5 L11,5"></path>
                      <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                  </Col>
                </Col>
              </div>
            </Row>
          </Form>
        </div>
      </Row>
    </Container>
  );
}

export default Onboarding;
