import { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import AmInvestAccordion from "../../components/accordions/amInvestaccordion";
import "./faqSection.css";

const FaqsSection = () => {
  const [faqData, setFaqData] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch(`${baseUrl}/faqs`);
        if (response.ok) {
          const data = await response.json();
          setFaqData(data.faqs.slice(0, 5));
        } else {
          console.error("Failed to fetch FAQs");
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  return (
    <section className="py-5" style={{ background: "#ECF3F0" }}>
      <Container>
        <h3 className="faqSectionHeading">FAQs</h3>
        <AmInvestAccordion type="faq" data={faqData} />
        <div className="text-center mt-4">
          <Col className="exploreButton arrowRightSvg d-flex justify-content-center mb-5">
            <a href="/faqs" className="cta">
              <span>View More</span>
              <svg width="13px" height="10px" viewBox="0 0 13 10">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </a>
          </Col>{" "}
        </div>
      </Container>
    </section>
  );
};

export default FaqsSection;
