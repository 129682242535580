import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import background from "../images/bgx.jpg";

const VerifyForgotPassword = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const navigate = useNavigate();
  const [token, setToken] = useState();

  useEffect(() => {
    const currentUrl = window.location.href;
    const tokenIndex = currentUrl.indexOf("token?");
    const tokenPart = currentUrl.substring(tokenIndex + "token?".length);
    const ampersandIndex = tokenPart.indexOf("&");
    const token =
      ampersandIndex !== -1
        ? tokenPart.substring(0, ampersandIndex)
        : tokenPart;
    setToken(token);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmNewPassword") setConfirmNewPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }

    changePassword();
  };

  const changePassword = () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        newPassword: newPassword,
      }),
    };

    fetch(`${baseUrl}/verify_forgotpassword`, config)
      .then((response) => {
        if (response.ok) {
          toast.success("Password changed successfully");
          navigate("/sign-in");
        } else {
          toast.error("Failed to Forgot Password");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <Container
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        minHeight: "100vh",
        marginBottom: "-20px",
      }}
    >
      <>
        <Row className="justify-content-center">
          <Col md={4}>
            <Card body className="form-data login shadow">
              <Form onSubmit={handleSubmit}>
                <Row className="mb-form">
                  <Col md={12}>
                    <Form.Label>New Password</Form.Label>
                  </Col>
                  <Col md={12}>
                    <Form.Control
                      type="password"
                      name="newPassword"
                      value={newPassword}
                      onChange={handleChange}
                      maxLength={50}
                      minLength={8}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-form">
                  <Col md={12}>
                    <Form.Label>Confirm New Password</Form.Label>
                  </Col>
                  <Col md={12}>
                    <Form.Control
                      type="password"
                      name="confirmNewPassword"
                      value={confirmNewPassword}
                      onChange={handleChange}
                      maxLength={50}
                      minLength={8}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-form">
                  <Col md={12}>
                    <Button
                      type="submit"
                      className="btn btn-block"
                      style={{ backgroundColor: "#416052", width: "275px" }}
                    >
                      Forgot Password
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    </Container>
  );
};

export default VerifyForgotPassword;
