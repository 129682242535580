import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion";
import Onboarding from "../../components/pages/(auth)/onboarding";
import SignIn from "../../components/pages/(auth)/signIn";

const AuthModal = ({ show, onHide, mode, onSignInSuccess }) => {
  const [authMode, setAuthMode] = useState(mode);

  useEffect(() => {
    setAuthMode(mode);
  }, [mode]);

  const switchMode = (newMode) => {
    setAuthMode(newMode);
  };

  const handleSignIn = () => {
    if (onSignInSuccess) {
      onSignInSuccess();
    }
  };

  const renderForm = () => {
    switch (authMode) {
      case "onboarding":
        return <Onboarding switchMode={switchMode} />;
      case "signin":
        return <SignIn switchMode={switchMode} onSignIn={handleSignIn} />;
      default:
        return null;
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <AnimatePresence mode="wait">
        <motion.div
          key={authMode}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.2 }}
        >
          {renderForm()}
        </motion.div>
      </AnimatePresence>
    </Modal>
  );
};

export default AuthModal;
