import { useState, useEffect } from "react";
import "./calculators.css";

const LumpsumCalculator = () => {
  const [investment, setInvestment] = useState(100000);
  const [duration, setDuration] = useState(20);
  const [rateOfReturn, setRateOfReturn] = useState(10);
  const [maturityAmount, setMaturityAmount] = useState(0);
  const [estimatedReturns, setEstimatedReturns] = useState(0);

  useEffect(() => {
    const calculateLumpsum = () => {
      if (investment === 0 || duration === 0 || rateOfReturn === 0) {
        setMaturityAmount(0);
        setEstimatedReturns(0);
        return;
      }

      const maturity = investment * Math.pow(1 + rateOfReturn / 100, duration);
      setMaturityAmount(maturity.toFixed(2));
      setEstimatedReturns((maturity - investment).toFixed(2));
    };

    calculateLumpsum();
  }, [investment, duration, rateOfReturn]);

  return (
    <section className="lumpSumCalculator">
      <div className="inputSection">
        <div className="inputGroup">
          <div className="rangeInputGroup">
            <label className="labelTag">Investment</label>
            <div className="inputSymbol">₹</div>
            <input
              type="number"
              min="1000"
              max="10000000"
              value={investment}
              onChange={(e) => setInvestment(Number(e.target.value))}
            />
          </div>
          <input
            type="range"
            min="1000"
            max="10000000"
            value={investment}
            onChange={(e) => setInvestment(Number(e.target.value))}
          />
        </div>
        <div className="inputGroup">
          <div className="rangeInputGroup">
            <label className="labelTag">Expected return rate (p.a)</label>
            <div className="inputSymbol">%</div>
            <input
              type="number"
              min="1"
              max="30"
              value={rateOfReturn}
              onChange={(e) => setRateOfReturn(Number(e.target.value))}
            />
          </div>
          <input
            type="range"
            min="1"
            max="30"
            value={rateOfReturn}
            onChange={(e) => setRateOfReturn(Number(e.target.value))}
          />
        </div>
        <div className="inputGroup">
          <div className="rangeInputGroup">
            <label className="labelTag">Time period</label>
            <div className="inputSymbol">Yr</div>
            <input
              type="number"
              min="1"
              max="40"
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
            />
          </div>
          <input
            type="range"
            min="1"
            max="40"
            value={duration}
            onChange={(e) => setDuration(Number(e.target.value))}
          />
        </div>
      </div>
      <div className="resultSection">
        <div>
          <span className="amountsLabel">Invested amount:</span>{" "}
          <span className="amountValue">₹ {investment}</span>
        </div>
        <div>
          <span className="amountsLabel">Estimated returns:</span>{" "}
          <span className="amountValue">₹ {estimatedReturns}</span>
        </div>
        <div>
          <span className="amountsLabel">Total value:</span>{" "}
          <span className="amountValue">₹ {maturityAmount}</span>
        </div>
        {/* <div className="circularGraph">
          <svg
            width="200"
            height="200"
            viewBox="0 0 36 36"
            className="circular-chart"
          >
            <path
              className="circle-bg"
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="circle invested"
              strokeDasharray={`${graphData.invested}, 100`}
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="circle returns"
              strokeDasharray={`${graphData.returns}, 100`}
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>
          <div className="graphLabels">
            <span className="investedLabel">
              Invested: {graphData.invested.toFixed(2)}%
            </span>
            <span className="returnsLabel">
              Returns: {graphData.returns.toFixed(2)}%
            </span>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default LumpsumCalculator;
