import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const useUserData = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [userData, setUserData] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []); // Run only once on mount

  useEffect(() => {
    if (isLoggedIn) {
      const fetchDataAndPresignedUrl = async () => {
        try {
          const token = Cookies.get("token");
          const config = {
            headers: {
              WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
            },
          };
          const response = await axios.get(`${baseUrl}/getUserData`, config);
          setUserData((prevData) =>
            response.data !== prevData ? response.data : prevData
          );

          const url = response.data.members?.ProfilePicture;
          if (url) {
            const path = url.split("ambassador.docs/")[1];
            const cleanedPath = path.replace(/"$/, "");

            const presignedUrlResponse = await fetch(
              `${baseUrl}/generate-presigned-url?Path=${encodeURIComponent(
                cleanedPath
              )}`,
              {
                headers: config.headers,
              }
            );

            if (!presignedUrlResponse.ok) {
              throw new Error("Failed to fetch presigned URL");
            }
            const presignedUrlData = await presignedUrlResponse.json();
            setGeneratedUrl((prevUrl) =>
              presignedUrlData.url !== prevUrl ? presignedUrlData.url : prevUrl
            );
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };

      fetchDataAndPresignedUrl();
    }
  }, [isLoggedIn, baseUrl]);

  const memoizedUserData = useMemo(() => userData, [userData]);
  const memoizedGeneratedUrl = useMemo(() => generatedUrl, [generatedUrl]);

  return { userData: memoizedUserData, generatedUrl: memoizedGeneratedUrl };
};

export default useUserData;
