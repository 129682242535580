import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "./companyName.css";
import useUserData from "../../useEffect/useUserData";

function CompanyName() {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { id } = useParams();
  const [investment, setInvestment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("about");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [wishlistLoading, setWishlistLoading] = useState(true);
  const { userData } = useUserData();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    linkedinProfile: "",
  });
  const [linkedinError, setLinkedinError] = useState("");
  const [isInterested, setIsInterested] = useState(false);

  // Prefetch data if the user is logged in
  useEffect(() => {
    if (userData && isLoggedIn) {
      setFormData({
        firstName: userData.members.FirstName,
        lastName: userData.members.LastName,
        phone: userData.memberProfile.MobileNumber,
        email: userData.members.Email,
        linkedinProfile: "", // Leave LinkedIn empty
      });
    }
  }, [userData, isLoggedIn]);

  // Handle form input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Validate LinkedIn URL
  const validateLinkedIn = (url) => {
    const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/;
    return linkedInRegex.test(url);
  };

  // Handle form submission with LinkedIn validation
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateLinkedIn(formData.linkedinProfile)) {
      setLinkedinError("Please enter a valid LinkedIn profile URL.");
      return;
    }

    setLinkedinError(""); // Clear any previous error

    try {
      const response = await axios.post(
        `${baseUrl}/submit-interest`,
        {
          stockName: investment.company_name,
          memberId: userData?.memberProfile?.MemberId,
          linkedInProfile: formData.linkedinProfile,
        },
        {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 201) {
        setIsInterested(true);
        toast.success("Your interest has been submitted successfully!");
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error submitting interest:", error);
      toast.error("Failed to submit interest. Please try again.");
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fetch investment data
  useEffect(() => {
    const fetchInvestmentData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/angel-investments/${id}`);
        setInvestment(response.data);

        // Check if user is logged in and has a member ID
        if (isLoggedIn && userData?.memberProfile?.MemberId) {
          // Check if user is interested
          const interestResponse = await axios.get(
            `${baseUrl}/check-interest/${userData.memberProfile.MemberId}/${response.data.company_name}`,
            {
              headers: {
                WRGSJGEUWCQN58EGV92D: `Bearer ${Cookies.get("token")}`,
              },
            }
          );
          setIsInterested(interestResponse.data.isInterested);

          // Check if the investment is shortlisted
          const wishlistResponse = await axios.get(
            `${baseUrl}/wishlist/${userData.memberProfile.MemberId}`,
            {
              headers: {
                WRGSJGEUWCQN58EGV92D: `Bearer ${Cookies.get("token")}`,
              },
            }
          );
          const isWishlistedInvestment = wishlistResponse.data.some(
            (item) => item.asset_id === parseInt(id)
          );
          setIsWishlisted(isWishlistedInvestment);
        }

        setLoading(false);
      } catch (err) {
        setError("Failed to fetch investment data. Please try again later.");
        setLoading(false);
      }
    };

    fetchInvestmentData();
  }, [id, isLoggedIn, userData]);
  // Check if user is logged in and fetch wishlist data
  useEffect(() => {
    const checkLoginState = () => {
      const token = Cookies.get("token");
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };

    const fetchWishlist = async () => {
      if (isLoggedIn) {
        setWishlistLoading(true);
        try {
          const userId = Cookies.get("user_id");
          const token = Cookies.get("token");
          const config = {
            headers: {
              WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
            },
          };
          const response = await axios.get(
            `${baseUrl}/wishlist/${userId}`,
            config
          );
          setWishlist(response.data);

          // Check if the current investment is already wishlisted
          const isWishlistedInvestment = response.data.some(
            (item) => item.asset_id === parseInt(id)
          );
          setIsWishlisted(isWishlistedInvestment);
        } catch (error) {
          toast.error("Failed to fetch wishlist.");
        } finally {
          setWishlistLoading(false);
        }
      }
    };

    checkLoginState();
    fetchWishlist();
  }, [id, isLoggedIn]);

  const handleWishlistToggle = async () => {
    if (!isLoggedIn) {
      toast.error("Please log in to manage your wishlist.");
      return;
    }

    setWishlistLoading(true);
    try {
      const userId = userData?.memberProfile?.MemberId;
      if (!userId) {
        toast.error("User ID not found. Please try logging in again.");
        return;
      }

      const token = Cookies.get("token");
      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };

      let response;
      if (isWishlisted) {
        response = await axios.delete(
          `${baseUrl}/wishlist/${userId}/${id}`,
          config
        );
        if (response.status === 200) {
          setWishlist((prev) =>
            prev.filter((item) => item.asset_id !== parseInt(id))
          );
          setIsWishlisted(false);
          toast.success("Removed from shortlist.");
        }
      } else {
        response = await axios.post(
          `${baseUrl}/wishlist`,
          {
            memberId: userId,
            fundId: id,
            fundName: investment.company_name,
            asset_type: "angel_company",
          },
          config
        );
        if (response.status === 200) {
          setWishlist((prev) => [
            ...prev,
            {
              asset_id: parseInt(id),
              fund_name: investment.company_name,
              status: 1,
              asset_type: "angel_company",
            },
          ]);
          setIsWishlisted(true);
          toast.success("Added to shortlist.");
        }
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      toast.error(
        error.response?.data?.error ||
          "Failed to update shortlist. Please try again."
      );
    } finally {
      setWishlistLoading(false);
    }
  };

  if (loading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!investment) {
    return <div>No investment found.</div>;
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "about":
        return <p>{investment.about || "No information available."}</p>;
      case "whyBTS":
        return <p>{investment.why_bts || "No information available."}</p>;
      case "what":
        return <p>{investment.what || "No information available."}</p>;
      case "startupDetails":
        return investment.startup_details &&
          investment.startup_details.length > 0 ? (
          investment.startup_details.map((startup, index) => (
            <div key={index} className="mb-3">
              <h4>{startup.name}</h4>
              <p>
                <strong>Sector:</strong> {startup.sector}
              </p>
              <p>
                <strong>Fund Size:</strong> {startup.fund_size}
              </p>
              <p>{startup.description}</p>
            </div>
          ))
        ) : (
          <p>No startup details available.</p>
        );
      case "opportunity":
        return <p>{investment.opportunity || "No information available."}</p>;
      case "team":
        return <p>{investment.team || "No information available."}</p>;
      case "faqs":
        return (
          <p style={{ whiteSpace: "pre-line" }}>
            {investment.faqs || "No FAQs available."}
          </p>
        );
      case "process":
        return (
          <p style={{ whiteSpace: "pre-line" }}>
            {investment.process || "No process information available."}
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="company-detail-page mt-4">
      <Row className="align-items-center mb-4">
        <div style={{ width: "auto" }}>
          <img
            src={
              investment.logo_url ||
              "https://img.icons8.com/bubbles/100/company.png"
            }
            alt={investment.company_name}
            className="company-logo img-fluid"
          />
        </div>
        <Col xs={12} md={9}>
          <h1>{investment.company_name}</h1>
        </Col>
      </Row>

      <Card className="investment-overview mb-4">
        <Row>
          <Col
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={
                investment.banner_url || "https://via.placeholder.com/300x200"
              }
              alt="Investment Banner"
              className="banner-img img-fluid"
            />
          </Col>
          <Col xs={12} md={6}>
            <div className="overview-details p-3">
              <p>
                <strong>Funding Stage:</strong> {investment.funding_stage}
              </p>
              <p>
                <strong>Type:</strong> {investment.type}
              </p>
              <p>
                <strong>Minimum Investment:</strong> INR{" "}
                {investment.min_investment}
              </p>
              <p>
                <strong>Targeted Returns:</strong> {investment.targeted_returns}
                %
              </p>
              <p>
                <strong>Time Frame:</strong> {investment.time_frame} months
              </p>
              <p>
                <strong>Risk:</strong> {investment.risk}
              </p>
              {investment.total_fund_size && (
                <p>
                  <strong>Total Fund Size:</strong> {investment.total_fund_size}
                </p>
              )}
              {investment.participating_startups && (
                <p>
                  <strong>Participating Startups:</strong>{" "}
                  {investment.participating_startups}
                </p>
              )}
              {investment.per_startup_allocation && (
                <p>
                  <strong>Per Startup Allocation:</strong>{" "}
                  {investment.per_startup_allocation}
                </p>
              )}
              <p>
                <strong>Rating:</strong> {investment.rating}/5
              </p>
              <p>
                <strong>6 Month Return:</strong>{" "}
                {investment.return_percentage_6_months}%
              </p>
              <p>
                <strong>12 Month Return:</strong>{" "}
                {investment.return_percentage_12_months}%
              </p>
              <p>
                <strong>36 Month Return:</strong>{" "}
                {investment.return_percentage_36_months}%
              </p>
            </div>
          </Col>
        </Row>
      </Card>

      <div className="custom-tabs">
        <ul className="tab-list">
          <li
            className={`tab-item ${activeTab === "about" ? "active" : ""}`}
            onClick={() => setActiveTab("about")}
          >
            About
          </li>
          <li
            className={`tab-item ${activeTab === "whyBTS" ? "active" : ""}`}
            onClick={() => setActiveTab("whyBTS")}
          >
            Why BTS
          </li>
          <li
            className={`tab-item ${activeTab === "what" ? "active" : ""}`}
            onClick={() => setActiveTab("what")}
          >
            What
          </li>
          <li
            className={`tab-item ${
              activeTab === "startupDetails" ? "active" : ""
            }`}
            onClick={() => setActiveTab("startupDetails")}
          >
            Startup Details
          </li>
          <li
            className={`tab-item ${
              activeTab === "opportunity" ? "active" : ""
            }`}
            onClick={() => setActiveTab("opportunity")}
          >
            Opportunity
          </li>
          <li
            className={`tab-item ${activeTab === "team" ? "active" : ""}`}
            onClick={() => setActiveTab("team")}
          >
            The Team
          </li>
          <li
            className={`tab-item ${activeTab === "faqs" ? "active" : ""}`}
            onClick={() => setActiveTab("faqs")}
          >
            FAQs
          </li>
          <li
            className={`tab-item ${activeTab === "process" ? "active" : ""}`}
            onClick={() => setActiveTab("process")}
          >
            Process (How)
          </li>
        </ul>

        <div className="tab-content">{renderTabContent()}</div>
      </div>

      <div className="fixed-buttons">
        <OverlayTrigger
          placement="bottom"
          overlay={
            !isLoggedIn ? (
              <Tooltip id="tooltip-disabled">Please login to shortlist</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              className="me-2 interested-button"
              onClick={handleWishlistToggle}
              style={{
                background: isWishlisted ? "#193428" : "#ccc",
                color: isWishlisted ? "#fff" : "#000",
                cursor: !isLoggedIn || isWishlisted ? "not-allowed" : "pointer",
              }}
              disabled={wishlistLoading || !isLoggedIn || isWishlisted}
            >
              {isWishlisted ? "Shortlisted" : "Shortlist"}
            </Button>
          </span>
        </OverlayTrigger>
        <Button
          className="interested-button"
          onClick={isInterested ? null : handleShowModal}
          disabled={isInterested || !isLoggedIn}
          style={{
            background: isInterested ? "#193428" : "#ccc",
            color: isInterested ? "#fff" : "#000",
            cursor: isInterested || !isLoggedIn ? "not-allowed" : "pointer",
          }}
        >
          {isInterested ? "Already Interested" : "I'm Interested"}
        </Button>
      </div>
      {/* Modal for 'I'm Interested' */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>I'm Interested Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                disabled={isLoggedIn}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                disabled={isLoggedIn}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                disabled={isLoggedIn}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                disabled={isLoggedIn}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>LinkedIn Profile</Form.Label>
              <Form.Control
                type="text"
                name="linkedinProfile"
                value={formData.linkedinProfile}
                onChange={handleChange}
                placeholder="Enter LinkedIn URL"
                isInvalid={linkedinError}
              />
              <Form.Control.Feedback type="invalid">
                {linkedinError}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default CompanyName;
