import BlogListSection from "../../modules/blogListSection/blogListSection";
import { Helmet } from "react-helmet";

const BlogListPage = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://ambassadorinvestments.com/blogs" />
      </Helmet>
      <BlogListSection />
    </>
  );
};

export default BlogListPage;
