import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { BiHide, BiShow } from "react-icons/bi";

function ChangePassword() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    switch (name) {
      case "currentPassword":
        setCurrentPassword(value);
        break;
      case "newPassword":
        setNewPassword(value);
        break;
      case "confirmNewPassword":
        setConfirmNewPassword(value);
        break;
      default:
        break;
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }
    if (currentPassword === newPassword) {
      toast.error("New password must be different from the current password.");
      return;
    }

    try {
      await changePassword();
      toast.success("Password changed successfully");
      // Reset input fields after successful password change
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      const errorMessage = error.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const changePassword = async () => {
    const token = Cookies.get("token");
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
      },
      body: JSON.stringify({
        currentPassword,
        newPassword,
      }),
    };

    const response = await fetch(`${baseUrl}/change-password`, config);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to change password");
    }
  };

  return (
    <Container>
      <Row className="mb-form">
        <div className="form-data">
          <Form onSubmit={handleSubmit}>
            <Row className="main-title">
              <Col md={12}>
                <h2>CHANGE PASSWORD</h2>
              </Col>
            </Row>
            <Row className="mb-form">
              <Col md={12}>
                <Form.Label>Current Password</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type={showCurrentPassword ? "text" : "password"}
                    name="currentPassword"
                    value={currentPassword}
                    onChange={handleChange}
                    maxLength={50}
                    minLength={8}
                    required
                  />
                  <span
                    className="psw-icon"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {showCurrentPassword ? (
                      <BiShow size={30} />
                    ) : (
                      <BiHide size={30} />
                    )}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mb-form">
              <Col md={12}>
                <Form.Label>New Password</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    value={newPassword}
                    onChange={handleChange}
                    maxLength={50}
                    minLength={8}
                    required
                  />
                  <span
                    className="psw-icon"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {showNewPassword ? (
                      <BiShow size={30} />
                    ) : (
                      <BiHide size={30} />
                    )}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mb-form">
              <Col md={12}>
                <Form.Label>Confirm New Password</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type={showConfirmNewPassword ? "text" : "password"}
                    name="confirmNewPassword"
                    value={confirmNewPassword}
                    onChange={handleChange}
                    maxLength={50}
                    minLength={8}
                    required
                  />
                  <span
                    className="psw-icon"
                    onClick={() =>
                      setShowConfirmNewPassword(!showConfirmNewPassword)
                    }
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {showConfirmNewPassword ? (
                      <BiShow size={30} />
                    ) : (
                      <BiHide size={30} />
                    )}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mb-form">
              <Col md={12}>
                <Button type="submit" className="btn btn-block submitButton">
                  Change Password
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Row>
    </Container>
  );
}

export default ChangePassword;
