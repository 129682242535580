import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const KYCNotification = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchKycStatus = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${baseUrl}/api/kyc/status/:MemberId`,
          config
        );
        const { kycStatus } = response.data;
        if (kycStatus === "pending" || kycStatus === "inprogress") {
          setShowAlert(true);
          localStorage.setItem("KYCStatus", kycStatus);
        }
      } catch (error) {
        console.error("Error fetching KYC status:", error);
        // localStorage.removeItem("token");
      }
    };

    fetchKycStatus();
  }, [baseUrl]);

  const handleDismissAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      {showAlert && (
        <div className="container">
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <strong>Your KYC process is incomplete!</strong> Please{" "}
            <Link to="/my-account#kyc">click here</Link> to complete the KYC
            process.
            <button
              type="button"
              className="btn-close"
              onClick={handleDismissAlert}
              aria-label="Close"
            ></button>
          </div>
        </div>
      )}
    </>
  );
};

export default KYCNotification;
