import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import calculatorsData from "./calculatorsListData";
import "./amInvestCalculatorList.css";

const AmInvestCalculatorList = () => {
  const navigate = useNavigate();

  const handleCardClick = (title) => {
    const path = title.toLowerCase();
    navigate(`/calculators/${path}`);
  };

  return (
    <Container className="calculatorListContainer">
      <h1 className="calculatorListTitle">Calculators</h1>
      <Row>
        {calculatorsData.map((calculator, index) => (
          <Col key={index} md={3} className="mb-4">
            <Card
              className="calculatorCard"
              onClick={() => handleCardClick(calculator.title)}
            >
              <Card.Body>
                <Card.Title className="calculatorCardTitle">
                  {calculator.title}
                </Card.Title>
                <Card.Text className="calculatorCardDescription">
                  {calculator.description}
                </Card.Text>
                <Card.Img
                  variant="bottom"
                  src={calculator.image}
                  className="calculatorCardImage"
                />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default AmInvestCalculatorList;
