import { useState, useEffect, useRef, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage } from "@fortawesome/free-regular-svg-icons";

const KYC = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [userkycdata, setUserkycdata] = useState({});
  const [presignedUrls, setPresignedUrls] = useState({});
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    const fetchDataWithPresignedUrls = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };

        const response = await axios.get(`${baseUrl}/getKycData`, config);
        const kycData = response.data;

        const urls = {};

        for (const [docType, docPaths] of Object.entries(kycData)) {
          if (Array.isArray(docPaths)) {
            urls[docType] = await Promise.all(
              docPaths.map(async (path) => {
                if (typeof path === "string") {
                  const response = await axios.get(
                    `${baseUrl}/generate-presigned-url?Path=${encodeURIComponent(
                      path.split("ambassador.docs/")[1]
                    )}`,
                    config
                  );
                  return response.data.url;
                }
                return null;
              })
            );
          }
        }

        setUserkycdata(kycData);
        setPresignedUrls(urls);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching KYC data:", error);
      }
    };

    fetchDataWithPresignedUrls();
  }, [baseUrl]);

  const handleShowPreview = (fileUrl) => {
    setModalContent(fileUrl);
    setShowPreviewModal(true);
  };

  return (
    <Container id="kyc">
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <Button style={{ backgroundColor: "#416052" }} disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        </div>
      )}
      <Row className="mb-form">
        <Col>
          <div body className="form-data">
            <Form>
              <Row className="mb-form align-items-center">
                <Col md={12}>
                  <Form.Label>Photo*</Form.Label>
                </Col>
                <Col md={6}>
                  <div className="row justify-content-center">
                    <div>
                      {userkycdata?.Photo &&
                      userkycdata.Photo[0]?.length > 0 ? (
                        <div className="submitted-text">
                          <Button
                            className="btn btn-block"
                            style={{
                              backgroundColor: "#416052",
                              width: "275px",
                            }}
                            disabled
                          >
                            Submitted
                          </Button>
                        </div>
                      ) : (
                        <Button
                          className="btn btn-block"
                          style={{
                            backgroundColor: "#416052",
                            width: "275px",
                          }}
                        >
                          Upload Image
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
            <hr />
            <Form>
              <Row className="align-items-center">
                <Col md={12}>
                  <Col md={12}>
                    <Form.Label>
                      Passport* &nbsp;
                      <span className="text-muted small">
                        (The file size must be less than 5 MB. Accepted formats
                        are JPG, JPEG, PDF, and PNG.)
                      </span>
                    </Form.Label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Passport Number"
                      value={
                        userkycdata?.Passport &&
                        userkycdata.Passport[0]?.PassportNumber
                          ? userkycdata?.Passport &&
                            userkycdata.Passport[0]?.PassportNumber
                          : ""
                      }
                      maxLength={9}
                      required
                      readOnly={
                        userkycdata?.Passport &&
                        userkycdata.Passport[0]?.PassportNumber
                          ? true
                          : false
                      }
                    />
                  </Col>
                </Col>
              </Row>
              <Row className="mb-form align-items-center">
                <Col md={6}>
                  {userkycdata?.Passport &&
                  userkycdata.Passport[0]?.PassportFrontFile ? (
                    <div>
                      <div className="form-control">
                        <label className="input-file-label">
                          Upload Passport Front
                          <input
                            type="file"
                            accept=".pdf,.jpg,.jpeg,.png"
                            style={{ display: "none" }}
                            placeholder=""
                            required
                            disabled
                          />
                        </label>
                      </div>
                      <span
                        className="badge bg-success "
                        style={{ marginTop: "10px" }}
                      >
                        Submitted
                      </span>
                      <Button
                        variant="link"
                        onClick={() =>
                          handleShowPreview(presignedUrls.Passport[0])
                        }
                      >
                        <FontAwesomeIcon icon={faFileImage} /> Preview
                      </Button>
                    </div>
                  ) : (
                    <div className="form-control">
                      <label className="input-file-label">
                        Upload Passport Front
                        <input
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                          style={{ display: "none" }}
                          placeholder=""
                          required
                        />
                      </label>
                    </div>
                  )}
                </Col>
                <Col md={6}>
                  {userkycdata?.Passport &&
                  userkycdata.Passport[0]?.PassportBackFile ? (
                    <div>
                      <div className="form-control">
                        <label className="input-file-label">
                          Upload Passport Back
                          <input
                            type="file"
                            accept=".pdf,.jpg,.jpeg,.png"
                            style={{ display: "none" }}
                            placeholder=""
                            required
                            disabled
                          />
                        </label>
                      </div>
                      <span
                        className="badge bg-success "
                        style={{ marginTop: "10px" }}
                      >
                        Submitted
                      </span>
                      <Button
                        variant="link"
                        onClick={() =>
                          handleShowPreview(presignedUrls.Passport[1])
                        }
                      >
                        <FontAwesomeIcon icon={faFileImage} /> Preview
                      </Button>
                    </div>
                  ) : (
                    <div className="form-control">
                      <label className="input-file-label">
                        Upload Passport Back
                        <input
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                          style={{ display: "none" }}
                          placeholder=""
                          required
                        />
                      </label>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
            <hr />
            <Form>
              <Col md={12}>
                <Row className="mb-form align-items-center">
                  <Col md={12}>
                    <Form.Label>
                      Pan Details* &nbsp;
                      <span className="text-muted small">
                        (The file size must be less than 5 MB. Accepted formats
                        are JPG, JPEG, PDF, and PNG.)
                      </span>
                    </Form.Label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Pan Number"
                      value={
                        userkycdata?.Pandiv && userkycdata.Pandiv[0]?.PanNumber
                          ? userkycdata?.Pandiv &&
                            userkycdata.Pandiv[0]?.PanNumber
                          : ""
                      }
                      maxLength={10}
                      required
                      readOnly={
                        userkycdata?.Pandiv && userkycdata.Pandiv[0]?.PanNumber
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col md={6}>
                    {userkycdata?.Pandiv &&
                    userkycdata.Pandiv[0]?.PandivFile ? (
                      <div>
                        <div className="form-control">
                          <label className=" badge bg-success ">
                            Submitted
                            <input
                              type="file"
                              accept=".pdf,.jpg,.jpeg,.png"
                              style={{ display: "none" }}
                              placeholder=""
                              required
                              disabled
                            />
                          </label>
                        </div>
                        <Button
                          variant="link"
                          onClick={() =>
                            handleShowPreview(presignedUrls.Pandiv[0])
                          }
                        >
                          <FontAwesomeIcon icon={faFileImage} /> Preview
                        </Button>
                      </div>
                    ) : (
                      <div className="form-control">
                        <label className="input-file-label">
                          Upload Pan
                          <input
                            type="file"
                            accept=".pdf,.jpg,.jpeg,.png"
                            style={{ display: "none" }}
                            placeholder=""
                            required
                          />
                        </label>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Form>
            <hr />
            <Form>
              <Row className="mb-form align-items-center">
                <Col md={12}>
                  <Form.Label>
                    Indian Address Proof* &nbsp;
                    <span className="text-muted small">
                      (The file size must be less than 5 MB. Accepted formats
                      are JPG, JPEG, PDF, and PNG.)
                    </span>
                  </Form.Label>
                </Col>
                <Col md={6}>
                  {userkycdata?.IndianAddressProof &&
                  userkycdata?.IndianAddressProof.length > 0 ? (
                    <div>
                      <div className="form-control">
                        <label className="input-file-label">
                          Upload Indian Address Proof
                          <input
                            type="file"
                            accept=".pdf,.jpg,.jpeg,.png"
                            style={{ display: "none" }}
                            placeholder=""
                            required
                            disabled
                          />
                        </label>
                      </div>
                      <span
                        className="badge bg-success "
                        style={{ marginTop: "10px" }}
                      >
                        Submitted
                      </span>
                      <Button
                        variant="link"
                        onClick={() =>
                          handleShowPreview(presignedUrls.IndianAddressProof[0])
                        }
                      >
                        <FontAwesomeIcon icon={faFileImage} /> Preview
                      </Button>
                    </div>
                  ) : (
                    <div className="form-control">
                      <label className="input-file-label">
                        Upload Indian Address Proof
                        <input
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                          style={{ display: "none" }}
                          placeholder=""
                          required
                        />
                      </label>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
            <hr />
            <Form>
              <Row className="mb-form align-items-center">
                <Col md={12}>
                  <Form.Label>
                    Foreign Address Proof* &nbsp;
                    <span className="text-muted small">
                      (The file size must be less than 5 MB. Accepted formats
                      are JPG, JPEG, PDF, and PNG.)
                    </span>
                  </Form.Label>
                </Col>
                <Col md={6}>
                  {userkycdata?.ForeignAddressProof &&
                  userkycdata.ForeignAddressProof.length > 0 ? (
                    <div>
                      <div className="form-control">
                        <label className="input-file-label">
                          Upload Foreign Address Proof
                          <input
                            type="file"
                            accept=".pdf,.jpg,.jpeg,.png"
                            style={{ display: "none" }}
                            placeholder=""
                            required
                            disabled
                          />
                        </label>
                      </div>
                      <span
                        className="badge bg-success "
                        style={{ marginTop: "10px" }}
                      >
                        Submitted
                      </span>
                      <Button
                        variant="link"
                        onClick={() =>
                          handleShowPreview(
                            presignedUrls.ForeignAddressProof[0]
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faFileImage} /> Preview
                      </Button>
                    </div>
                  ) : (
                    <div className="form-control">
                      <label className="input-file-label">
                        Upload Foreign Address Proof
                        <input
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                          style={{ display: "none" }}
                          placeholder=""
                          required
                        />
                      </label>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
            <hr />
            <Form>
              <Row className="mb-form align-items-center">
                <Col md={12}>
                  <Form.Label>
                    NRO/NRE Cheque* &nbsp;
                    <span className="text-muted small">
                      (The file size must be less than 5 MB. Accepted formats
                      are JPG, JPEG, PDF, and PNG.)
                    </span>
                  </Form.Label>
                </Col>
                <Col md={6}>
                  {userkycdata?.Cheque && userkycdata?.Cheque.length > 0 ? (
                    <div>
                      <div className="form-control">
                        <label className="input-file-label">
                          Upload NRO/NRE Cheque
                          <input
                            type="file"
                            accept=".pdf,.jpg,.jpeg,.png"
                            style={{ display: "none" }}
                            placeholder=""
                            required
                            disabled
                          />
                        </label>
                      </div>
                      <span
                        className="badge bg-success "
                        style={{ marginTop: "10px" }}
                      >
                        Submitted
                      </span>
                      <Button
                        variant="link"
                        onClick={() =>
                          handleShowPreview(presignedUrls.Cheque[0])
                        }
                      >
                        <FontAwesomeIcon icon={faFileImage} /> Preview
                      </Button>
                    </div>
                  ) : (
                    <div className="form-control">
                      <label className="input-file-label">
                        Upload NRO/NRE Cheque
                        <input
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                          style={{ display: "none" }}
                          placeholder=""
                          required
                        />
                      </label>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
            <hr />
            <Form>
              <Row className="mb-form align-items-center">
                <Col md={12}>
                  <Form.Label>
                    Tax Identification Number* &nbsp;
                    <span className="text-muted small">
                      (The Tax Identification Number must be 11 digits.)
                    </span>
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <input
                    type="text"
                    value={
                      userkycdata?.TaxIdentification &&
                      userkycdata.TaxIdentification[0]
                        ? userkycdata?.TaxIdentification &&
                          userkycdata.TaxIdentification[0]
                        : ""
                    }
                    readOnly={
                      userkycdata?.TaxIdentification &&
                      userkycdata?.TaxIdentification.length > 0
                        ? true
                        : false
                    }
                    className="form-control"
                    required
                    placeholder="Enter Tax Identification Number"
                    maxLength={11}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
      <Modal
        show={showPreviewModal}
        onHide={() => setShowPreviewModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>File Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={modalContent} width="100%" height="500px" />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default KYC;
