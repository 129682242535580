import { useState, useEffect } from "react";
import "./calculators.css";

const ROICalculator = () => {
  const [investment, setInvestment] = useState(50000);
  const [returnAmount, setReturnAmount] = useState(70000);
  const [roi, setRoi] = useState(0);

  useEffect(() => {
    const calculateROI = () => {
      if (investment === 0) {
        setRoi(0);
        return;
      }

      const netProfit = returnAmount - investment;
      const roiPercentage = (netProfit / investment) * 100;
      setRoi(roiPercentage.toFixed(2));
    };

    calculateROI();
  }, [investment, returnAmount]);

  return (
    <section className="roiCalculator">
      <div className="inputSection">
        <div className="inputGroup">
          <div className="rangeInputGroup">
            <label className="labelTag">Investment</label>
            <div className="inputSymbol">₹</div>
            <input
              type="number"
              min="1000"
              max="10000000"
              value={investment}
              onChange={(e) => setInvestment(Number(e.target.value))}
            />
          </div>
          <input
            type="range"
            min="1000"
            max="10000000"
            value={investment}
            onChange={(e) => setInvestment(Number(e.target.value))}
          />
        </div>
        <div className="inputGroup">
          <div className="rangeInputGroup">
            <label className="labelTag">Return Amount</label>
            <div className="inputSymbol">₹</div>
            <input
              type="number"
              min="1000"
              max="10000000"
              value={returnAmount}
              onChange={(e) => setReturnAmount(Number(e.target.value))}
            />
          </div>
          <input
            type="range"
            min="1000"
            max="10000000"
            value={returnAmount}
            onChange={(e) => setReturnAmount(Number(e.target.value))}
          />
        </div>
      </div>
      <div className="resultSection">
        <div>
          <span className="amountsLabel">ROI:</span>{" "}
          <span className="amountValue">{roi}%</span>
        </div>
      </div>
    </section>
  );
};

export default ROICalculator;
