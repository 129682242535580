import { useState, useEffect } from "react";
import "./taxFilings.css";
import AuthModal from "../authModule/authModal";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const TaxServices = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [authMode, setAuthMode] = useState("signin");

  useEffect(() => {
    const token = Cookies.get("token");
    setIsLoggedIn(!!token);
  }, []);

  const handleOpenModal = (mode) => {
    setAuthMode(mode);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSignInSuccess = () => {
    setShowModal(false);
    setIsLoggedIn(true);
  };

  const openGoogleCalendarPopup = () => {
    window.open(
      "https://calendar.google.com/calendar/appointments/schedules/AcZssZ2auUzMN4H23zxECuXh2fHg4D8yt7uJ92GogV7Ka9U5IOYAfsOjV27Xn4J-dWYlGvlNMcBoanak?gv=true",
      "popup",
      "width=600,height=600"
    );
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ambassadorinvestments.com/tax-services"
        />
      </Helmet>
      <section className="taxServices">
        <div className="container">
          <h1 className="heading">Welcome to Our New Tax Services Tab!</h1>
          <h2 className="subheading">
            Expert CA Certified Tax Consultant for NRIs
          </h2>
          <p className="paragraph">
            We are thrilled to introduce our new Tax Services tab, designed
            exclusively for Overseas Indians. Our certified Chartered Accountant
            (CA) consultant is here to provide comprehensive tax solutions
            tailored to meet your unique needs. Whether you need assistance with
            PAN services, tax compliances, remittances, or advisory, we’ve got
            you covered.
          </p>
          <p className="paragraph">
            {isLoggedIn
              ? "Book a complimentary 30-minute consultation with our expert CA."
              : "To get started, simply log in and book a complimentary 30-minute consultation with our expert CA."}
          </p>

          <div className="services">
            <h3 className="sectionHeading">Our NRI Tax Services:</h3>

            <div className="serviceSection">
              <h4 className="subsectionHeading">PAN Services:</h4>
              <ul className="list">
                <li className="listItem">
                  Apply for New PAN: Get your Permanent Account Number
                  effortlessly.
                </li>
                <li className="listItem">
                  Link PAN with Aadhaar: Ensure compliance by linking your PAN
                  with Aadhaar.
                </li>
                <li className="listItem">
                  PAN Correction: Update any incorrect details in your PAN card.
                </li>
                <li className="listItem">
                  Reprint Lost PAN Card: Request a reprint if you've lost your
                  PAN card.
                </li>
                <li className="listItem">
                  Update PAN Citizenship or Reactivate PAN: Update your
                  citizenship status or reactivate a dormant PAN.
                </li>
              </ul>
            </div>

            <div className="serviceSection">
              <h4 className="subsectionHeading">Compliances:</h4>
              <ul className="list">
                <li className="listItem">
                  Income Tax Return (ITR) Filing: Hassle-free filing of your
                  income tax returns.
                </li>
                <li className="listItem">
                  File TDS Return on Purchase of Immovable Property in India:
                  Comply with TDS requirements when purchasing property in
                  India.
                </li>
                <li className="listItem">
                  Obtain Lower TDS Certificate: Reduce your TDS liability with
                  the right certifications.
                </li>
                <li className="listItem">
                  Income Tax Notices and Feedbacks: Assistance in responding to
                  tax notices and providing necessary feedback.
                </li>
                <li className="listItem">
                  Assistance in Payment of Taxes: Expert help in calculating and
                  paying your taxes correctly.
                </li>
              </ul>
            </div>

            <div className="serviceSection">
              <h4 className="subsectionHeading">Remittances:</h4>
              <ul className="list">
                <li className="listItem">
                  Form 15CA-15CB (NRO to NRE Transfers): Facilitate transfers
                  from Non-Resident Ordinary (NRO) to Non-Resident External
                  (NRE) accounts.
                </li>
                <li className="listItem">
                  Form 15CA-15CB (NRO to Outward Transfers): Manage outward
                  remittances from your NRO account efficiently.
                </li>
              </ul>
            </div>

            <div className="serviceSection">
              <h4 className="subsectionHeading">Advisory:</h4>
              <ul className="list">
                <li className="listItem">
                  Personalized advisory services to help you navigate the
                  complexities of the Indian tax system and optimize your tax
                  obligations.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="getTaxServices">
          <h3 className="sectionHeading">How to Get Started:</h3>
          <ol className="orderedList">
            <li className="orderedListItem">
              <strong>{isLoggedIn ? "Book a Consultation:" : "Log In:"}</strong>{" "}
              {isLoggedIn
                ? "Schedule a free 30-minute call with our CA certified tax consultant."
                : "Sign in to your account on our website."}
            </li>
            {!isLoggedIn && (
              <li className="orderedListItem">
                <strong>Book a Consultation:</strong> Schedule a free 30-minute
                call with our CA certified tax consultant.
              </li>
            )}
            <li className="orderedListItem">
              <strong>Get Expert Help:</strong> Receive professional advice and
              support tailored to your tax needs.
            </li>
          </ol>
          {isLoggedIn ? (
            <Button
              style={{ width: "100%" }}
              className="googleButtonCalendar"
              onClick={openGoogleCalendarPopup}
            >
              <span>
                <img
                  style={{ width: "24px", height: "24px" }}
                  src="/images/googleIcon.svg"
                  alt="login with google"
                />
              </span>{" "}
              Book Meeting
            </Button>
          ) : (
            <button
              className="submitButton"
              onClick={() => handleOpenModal("signin")}
            >
              Log In Now
            </button>
          )}
        </div>

        <AuthModal
          show={showModal}
          onHide={handleCloseModal}
          mode={authMode}
          onSignInSuccess={handleSignInSuccess}
        />
      </section>
    </>
  );
};

export default TaxServices;
