import { createContext, useState, useEffect, useContext } from "react";
import useUserData from "../useEffect/useUserData";
import userImg from "../images/avatar-1.png";

// Create the context
const UserImageContext = createContext();

// Create the provider
const UserImageProvider = ({ children }) => {
  const { generatedUrl } = useUserData();
  const [imageUrl, setImageUrl] = useState(userImg);

  useEffect(() => {
    if (generatedUrl) {
      setImageUrl(generatedUrl);
    }
  }, [generatedUrl]);

  const updateImageUrl = (newUrl) => {
    setImageUrl(newUrl);
  };

  return (
    <UserImageContext.Provider value={{ imageUrl, updateImageUrl }}>
      {children}
    </UserImageContext.Provider>
  );
};

// Custom hook to use the UserImageContext
const useUserImage = () => useContext(UserImageContext);

export { UserImageProvider, useUserImage };
