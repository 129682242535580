import { Spinner, Container, Row, Col } from "react-bootstrap";

const Loader = () => {
  return (
    <Container className="loader-page">
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Col md={6} className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <h1>Welcome</h1>
          <p>Please wait while we check your status...</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Loader;
