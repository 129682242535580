import { useState, useEffect } from "react";
import "./calculators.css";

const SipCalculator = () => {
  const [monthlyInvestment, setMonthlyInvestment] = useState(25000);
  const [duration, setDuration] = useState(10);
  const [rateOfReturn, setRateOfReturn] = useState(12);
  const [maturityAmount, setMaturityAmount] = useState(0);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [estimatedReturns, setEstimatedReturns] = useState(0);

  useEffect(() => {
    const calculateSIP = () => {
      if (monthlyInvestment === 0 || duration === 0 || rateOfReturn === 0) {
        setMaturityAmount(0);
        setInvestedAmount(0);
        setEstimatedReturns(0);
        return;
      }

      const i = rateOfReturn / 100 / 12;
      const n = duration * 12;
      const maturity =
        monthlyInvestment * ((Math.pow(1 + i, n) - 1) / i) * (1 + i);
      setMaturityAmount(maturity.toFixed(2));
      setInvestedAmount((monthlyInvestment * n).toFixed(2));
      setEstimatedReturns((maturity - monthlyInvestment * n).toFixed(2));
    };

    calculateSIP();
  }, [monthlyInvestment, duration, rateOfReturn]);

  const getGraphData = () => {
    const invested = maturityAmount
      ? (investedAmount / maturityAmount) * 100
      : 0;
    const returns = 100 - invested;
    return { invested, returns };
  };

  const graphData = getGraphData();

  return (
    <section className="sipCalculator">
      <div className="inputSection">
        <div className="inputGroup">
          <div className="rangeInputGroup">
            <label className="labelTag">Monthly investment</label>
            <div className="inputSymbol">₹</div>
            <input
              type="number"
              min="500"
              max="100000"
              value={monthlyInvestment}
              onChange={(e) => setMonthlyInvestment(Number(e.target.value))}
            />
          </div>
          <input
            type="range"
            min="500"
            max="100000"
            value={monthlyInvestment}
            onChange={(e) => setMonthlyInvestment(Number(e.target.value))}
          />
        </div>
        <div className="inputGroup">
          <div className="rangeInputGroup">
            <label className="labelTag">Expected return rate (p.a)</label>
            <div className="inputSymbol">%</div>
            <input
              type="number"
              min="1"
              max="30"
              value={rateOfReturn}
              onChange={(e) => setRateOfReturn(Number(e.target.value))}
            />
          </div>
          <input
            type="range"
            min="1"
            max="30"
            value={rateOfReturn}
            onChange={(e) => setRateOfReturn(Number(e.target.value))}
          />
        </div>
        <div className="inputGroup">
          <div className="rangeInputGroup">
            <label className="labelTag">Time period</label>
            <div className="inputSymbol">Yr</div>
            <input
              type="number"
              min="1"
              max="40"
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
            />
          </div>
          <input
            type="range"
            min="1"
            max="40"
            value={duration}
            onChange={(e) => setDuration(Number(e.target.value))}
          />
        </div>
      </div>
      <div className="resultSection">
        <div>
          <div>
            <span className={`amountsLabel`}> Invested amount:</span>{" "}
            <span className={`amountValue`}>₹ {investedAmount}</span>
          </div>
          <div>
            <span className={`amountsLabel`}>Estimated returns:</span>{" "}
            <span className={`amountValue`}>₹ {estimatedReturns}</span>
          </div>
          <div>
            <span className={`amountsLabel`}>Total value:</span>{" "}
            <span className={`amountValue`}>₹ {maturityAmount}</span>
          </div>
        </div>
        <div className="circularGraph">
          <svg
            width="200"
            height="200"
            viewBox="0 0 36 36"
            className="circular-chart"
          >
            <path
              className="circle-bg"
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="circle invested"
              strokeDasharray={`${graphData.invested}, 100`}
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="circle returns"
              strokeDasharray={`${graphData.returns}, 100`}
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>
          <div className="graphLabels">
            <span className="investedLabel">
              Invested: {graphData.invested.toFixed(2)}%
            </span>
            <span className="returnsLabel">
              Returns: {graphData.returns.toFixed(2)}%
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SipCalculator;
