import { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import validator from "validator";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

const BasicInfo = ({ baseUrl }) => {
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const [email, setEmails] = useState("");
  const [isValidEmail, setValidEmail] = useState(true);
  const [countries, setCountries] = useState([]);
  const [incomeRange, setIncomeRange] = useState([]);
  const [occupationType, setOccupationType] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const residentialStatus = [
    { id: 1, name: "Overseas Citizenship of India" },
    { id: 2, name: "Non-Resident Indian" },
    { id: 3, name: "Indian Citizen" },
  ];

  const genderOptions = [
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
  ];

  const maritalOptions = [
    { id: 1, name: "Married" },
    { id: 2, name: "Unmarried" },
  ];

  const Identity = [
    { id: 1, name: "Voter ID Card" },
    { id: 2, name: "Driving License" },
    { id: 3, name: "Aadhar Card" },
    { id: 4, name: "NREGA Job Card" },
  ];

  const [formData, setFormData] = useState({
    Gender: "",
    MaritalStatus: "",
    Citizenship: "",
    OccupationType: "",
    CityOfBirth: "",
    Income: "",
    IdentityType: "",
    IdentityNumber: "",
    Document: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    ZipCode: "",
    Country: "",
    MobileNumber: "",
    DateOfBirth: "",
    isWhatsappNumber: false,
    whatsappNumber: "",
  });

  const [formData1, setFormData1] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    ResidentialStatus: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${baseUrl}/getUserData`, config);
        setUserData(response.data);
        setEmails(response.data.members?.Email || "");
        setFormData({
          Gender: response.data.memberProfile?.Gender || "",
          MaritalStatus: response.data.memberProfile?.MaritalStatus || "",
          Citizenship: response.data.memberProfile?.Citizenship || "",
          OccupationType: response.data.memberProfile?.OccupationType || "",
          CityOfBirth: response.data.memberProfile?.CityOfBirth || "",
          Income: response.data.memberProfile?.Income || "",
          IdentityType: response.data.memberProfile?.IdentityType || "",
          IdentityNumber: response.data.memberProfile?.IdentityNumber || "",
          Document: response.data.memberProfile?.Document || "",
          Address1: response.data.memberProfile?.Address1 || "",
          Address2: response.data.memberProfile?.Address2 || "",
          City: response.data.memberProfile?.City || "",
          State: response.data.memberProfile?.State || "",
          ZipCode: response.data.memberProfile?.ZipCode || "",
          Country: response.data.memberProfile?.Country || "",
          MobileNumber: response.data.memberProfile?.MobileNumber || "",
          DateOfBirth:
            response.data?.memberProfile?.DateOfBirth?.slice(0, 10) || "",
        });
        setFormData1({
          FirstName: response.data.members?.FirstName || "",
          MiddleName: response.data.members?.MiddleName || "",
          LastName: response.data.members?.LastName || "",
          ResidentialStatus: response.data.members?.ResidentialStatus || "",
        });
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    const fetchOptions = async () => {
      try {
        const countriesResponse = await axios.get(`${baseUrl}/countries`);
        setCountries(countriesResponse.data);
      } catch (error) {
        console.error(
          "Error fetching countries data:",
          error.response?.data || error.message
        );
      }

      try {
        const incomeResponse = await axios.get(`${baseUrl}/income`);
        setIncomeRange(incomeResponse.data);
      } catch (error) {
        console.error(
          "Error fetching income data:",
          error.response?.data || error.message
        );
      }

      try {
        const occupationResponse = await axios.get(
          `${baseUrl}/occupation_type`
        );
        setOccupationType(occupationResponse.data);
      } catch (error) {
        console.error(
          "Error fetching occupation type data:",
          error.response?.data || error.message
        );
      }
    };

    fetchData();
    fetchOptions();
  }, [baseUrl]);

  useEffect(() => {
    const requiredFieldsFormData1 = [
      "FirstName",
      "LastName",
      "ResidentialStatus",
    ];
    const requiredFieldsFormData = [
      "DateOfBirth",
      "Gender",
      "MaritalStatus",
      "CityOfBirth",
      "Citizenship",
      "OccupationType",
      "Income",
      "MobileNumber",
    ];

    let isValid = true;

    requiredFieldsFormData1.forEach((field) => {
      if (!formData1[field]) {
        isValid = false;
      }
    });

    requiredFieldsFormData.forEach((field) => {
      if (!formData[field]) {
        isValid = false;
      }
    });

    setIsFormValid(isValid);
  }, [formData, formData1]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (isSubmitted) validateField(name, value);
  };

  const handleFirstNameChange = (event) => {
    const newFirstName = event.target.value;
    if (!/[^a-zA-Z\s]/.test(newFirstName)) {
      setFormData1((prevData) => ({
        ...prevData,
        FirstName: newFirstName,
      }));
      setUserData((prevUserData) => ({
        ...prevUserData,
        members: {
          ...prevUserData.members,
          FirstName: newFirstName,
        },
      }));
    }
  };

  const handleMiddleNameChange = (event) => {
    const newMiddleName = event.target.value;
    if (!/[^a-zA-Z\s]/.test(newMiddleName)) {
      setFormData1((prevData) => ({
        ...prevData,
        MiddleName: newMiddleName,
      }));
      setUserData((prevUserData) => ({
        ...prevUserData,
        members: {
          ...prevUserData.members,
          MiddleName: newMiddleName,
        },
      }));
    }
  };

  const handleLastNameChange = (event) => {
    const newLastName = event.target.value;
    if (!/[^a-zA-Z\s]/.test(newLastName)) {
      setFormData1((prevData) => ({
        ...prevData,
        LastName: newLastName,
      }));
      setUserData((prevUserData) => ({
        ...prevUserData,
        members: {
          ...prevUserData.members,
          LastName: newLastName,
        },
      }));
    }
  };

  const changeHandlerResidentialStatus = (event) => {
    const selectedOption = event.target.value;
    setFormData1((prevData) => ({
      ...prevData,
      ResidentialStatus: selectedOption,
    }));
    setUserData((prevUserData) => ({
      ...prevUserData,
      members: {
        ...prevUserData.members,
        ResidentialStatus: selectedOption,
      },
    }));
  };

  const validateField = (name, value) => {
    let errorMsg = "";
    switch (name) {
      case "FirstName":
      case "LastName":
        if (!value) errorMsg = "This field is required";
        if (/[^a-zA-Z\s]/.test(value))
          errorMsg = "Name should not contain special characters";
        break;
      case "Email":
        if (!value) errorMsg = "This field is required";
        if (!isValidEmail) errorMsg = "Invalid email address";
        break;
      case "DateOfBirth":
        if (!value) {
          errorMsg = "This field is required";
        } else {
          const today = new Date();
          const birthDate = new Date(value);
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDifference = today.getMonth() - birthDate.getMonth();
          if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          if (age < 18) errorMsg = "Age must be at least 18 years";
        }
        break;
      case "Gender":
      case "MaritalStatus":
      case "CityOfBirth":
      case "Citizenship":
      case "OccupationType":
      case "Income":
        if (!value) errorMsg = "This field is required";
        break;
      case "MobileNumber":
        if (!value) errorMsg = "This field is required";
        if (!validator.isMobilePhone(value)) errorMsg = "Invalid mobile number";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(e);
    if (isSubmitted) validateField(name, value);
  };

  const handleWhatsappNumberChange = (value) => {
    setFormData((prevData) => ({ ...prevData, whatsappNumber: value || "" }));
  };

  const handleSwitchChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      isWhatsappNumber: checked,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];
    const maxSize = 5 * 1024 * 1024;

    if (!file) {
      setSelectedFile(null);
      setFileErrorMessage("Please select a file.");
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      setSelectedFile(null);
      setFileErrorMessage(
        "Please select a valid file (JPG, JPEG, PDF, and PNG)."
      );
      return;
    }

    if (file.size > maxSize) {
      setSelectedFile(null);
      setFileErrorMessage("File size exceeds 5MB limit.");
      return;
    }
    setSelectedFile(file);
    setFileErrorMessage("");
    setFormData((prevData) => ({
      ...prevData,
      Document: file.name,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    const requiredFieldsFormData1 = [
      "FirstName",
      "LastName",
      "ResidentialStatus",
    ];
    const requiredFieldsFormData = [
      "DateOfBirth",
      "Gender",
      "MaritalStatus",
      "CityOfBirth",
      "Citizenship",
      "OccupationType",
      "Income",
      "MobileNumber",
    ];

    let hasErrors = false;

    // Validate fields in formData1
    requiredFieldsFormData1.forEach((field) => {
      const value = formData1[field];
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "This field is required",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
    });

    // Validate fields in formData
    requiredFieldsFormData.forEach((field) => {
      const value = formData[field];
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "This field is required",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
    });

    if (hasErrors) {
      return;
    }

    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };

      const formDataWithWhatsapp = {
        ...formData,
        whatsappNumber: formData.isWhatsappNumber
          ? formData.MobileNumber
          : formData.whatsappNumber,
      };

      await axios.post(`${baseUrl}/insertMembers`, formData1, config);
      await axios.post(
        `${baseUrl}/insertMemberProfile`,
        formDataWithWhatsapp,
        config
      );

      if (selectedFile) {
        const uploadResult = await uploadFile(selectedFile, "IDENTITY");
      }

      toast.success("Profile Updated!");
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  const uploadFile = async (file, fileType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", fileType);
    try {
      const token = Cookies.get("token");

      const response = await fetch(`${baseUrl}/upload-document`, {
        method: "POST",
        body: formData,
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const isReadOnly = !!userData.members;

  return (
    <div
      body
      className="form-data"
      id="basicInfo"
      style={{ textAlign: "left" }}
    >
      <h4>BASIC INFO</h4>

      <Form onSubmit={handleSubmit}>
        <Row className="mb-form">
          <Col md={12}>
            <Form.Label>Full Name*</Form.Label>
          </Col>
          <Col md={4}>
            <Form.Control
              type="text"
              name="FirstName"
              value={formData1.FirstName || ""}
              onChange={handleFirstNameChange}
              placeholder="First Name"
              maxLength={50}
              isInvalid={!!errors.FirstName}
              required
              readOnly={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.FirstName}
            </Form.Control.Feedback>
          </Col>
          <Col md={4}>
            <Form.Control
              type="text"
              name="MiddleName"
              value={formData1.MiddleName}
              onChange={handleMiddleNameChange}
              placeholder="Middle Name"
              maxLength={50}
              readOnly={isReadOnly}
            />
          </Col>
          <Col md={4}>
            <Form.Control
              type="text"
              name="LastName"
              value={formData1.LastName || ""}
              onChange={handleLastNameChange}
              placeholder="Last Name"
              maxLength={50}
              isInvalid={!!errors.LastName}
              required
              readOnly={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.LastName}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-form">
          <Form.Group as={Col} md={6} controlId="formGridResidentialStatus">
            <Form.Label>Residential Status*</Form.Label>
            <Form.Control
              as="select"
              name="ResidentialStatus"
              value={formData1.ResidentialStatus}
              onChange={changeHandlerResidentialStatus}
              required
              isInvalid={!!errors.ResidentialStatus}
              disabled={isReadOnly}
            >
              <option value="">Select</option>
              {residentialStatus.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.ResidentialStatus}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={6} controlId="formGridEmail">
            <Form.Label>Email*</Form.Label>
            <Form.Control
              type="email"
              name="Email"
              value={email}
              readOnly
              isInvalid={!isValidEmail}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.Email}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <h4>PERSONAL INFO</h4>
        <Row className="mb-form">
          <Form.Group as={Col} controlId="formGridGender">
            <Form.Label>Gender*</Form.Label>
            <Form.Control
              as="select"
              name="Gender"
              value={formData.Gender}
              onChange={handleInputChange}
              required
              isInvalid={!!errors.Gender}
              disabled={isReadOnly}
            >
              <option value="">Select</option>
              {genderOptions.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.Gender}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridDateOfBirth">
            <Form.Label>Date Of Birth*</Form.Label>
            <Form.Control
              type="date"
              name="DateOfBirth"
              value={formData.DateOfBirth}
              placeholder="Enter your date of birth"
              onChange={handleInputChange}
              isInvalid={!!errors.DateOfBirth}
              required
              readOnly={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.DateOfBirth}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-form">
          <Form.Group as={Col} controlId="formGridMaritalStatus">
            <Form.Label>Marital Status*</Form.Label>
            <Form.Control
              as="select"
              name="MaritalStatus"
              value={formData.MaritalStatus}
              onChange={handleInputChange}
              required
              isInvalid={!!errors.MaritalStatus}
              disabled={isReadOnly}
            >
              <option value="">Select</option>
              {maritalOptions.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.MaritalStatus}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCityOfBirth">
            <Form.Label>City Of Birth*</Form.Label>
            <Form.Control
              type="text"
              name="CityOfBirth"
              value={formData.CityOfBirth}
              onChange={handleInputChange}
              placeholder="Enter Your City of Birth"
              maxLength={50}
              isInvalid={!!errors.CityOfBirth}
              required
              readOnly={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.CityOfBirth}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-form">
          <Form.Group as={Col} controlId="formGridCitizenship">
            <Form.Label>Citizenship*</Form.Label>
            <Form.Control
              as="select"
              name="Citizenship"
              value={formData.Citizenship}
              onChange={handleFieldChange}
              required
              isInvalid={!!errors.Citizenship}
              disabled={isReadOnly}
            >
              <option value="">Select</option>
              {countries.map((country) => (
                <option key={country.CountryId} value={country.CountryId}>
                  {country.Name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.Citizenship}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridOccupationType">
            <Form.Label>Occupation Type*</Form.Label>
            <Form.Control
              as="select"
              name="OccupationType"
              value={formData.OccupationType}
              onChange={handleInputChange}
              required
              isInvalid={!!errors.OccupationType}
              disabled={isReadOnly}
            >
              <option value="">Select</option>
              {occupationType.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.OccupationType}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-form">
          <Form.Group as={Col} controlId="formGridMobileNumber">
            <Form.Label>Mobile Number*</Form.Label>
            <PhoneInput
              country="IN"
              name="MobileNumber"
              className={`form-control ${
                errors.MobileNumber ? "is-invalid" : ""
              }`}
              value={formData.MobileNumber}
              minLength={10}
              maxLength={15}
              required
              onChange={(value) =>
                handleInputChange({ target: { name: "MobileNumber", value } })
              }
              disabled={isReadOnly}
            />
            <Form.Check
              type="checkbox"
              id="whatsappCheckbox"
              className="whatsappCheckboxText"
              label="This is my WhatsApp number"
              checked={formData.isWhatsappNumber}
              onChange={handleSwitchChange}
              disabled={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.MobileNumber}
            </Form.Control.Feedback>
          </Form.Group>
          {!formData.isWhatsappNumber && (
            <Form.Group as={Col} controlId="formGridWhatsappNumber">
              <Form.Label>WhatsApp Number</Form.Label>
              <PhoneInput
                country="IN"
                name="whatsappNumber"
                className={`form-control ${
                  errors.whatsappNumber ? "is-invalid" : ""
                }`}
                value={formData.whatsappNumber}
                minLength={10}
                maxLength={15}
                onChange={handleWhatsappNumberChange}
                disabled={isReadOnly}
              />
              <Form.Control.Feedback type="invalid">
                {errors.whatsappNumber}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formGridIncome">
            <Form.Label>Income*</Form.Label>
            <Form.Control
              as="select"
              name="Income"
              value={formData.Income}
              onChange={handleInputChange}
              required
              isInvalid={!!errors.Income}
              disabled={isReadOnly}
            >
              <option value="">Select</option>
              {incomeRange.map((Income) => (
                <option key={Income.IncomeRangeId} value={Income.IncomeRangeId}>
                  {Income.IncomeRange}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.Income}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <h4 className={`mt-4`}>PROOF OF IDENTITY</h4>
        <Row className="mb-form">
          <Form.Group as={Col} md={4} controlId="formGridIdentityType">
            <Form.Label>Identity Type*</Form.Label>
            <Form.Control
              as="select"
              name="IdentityType"
              value={formData.IdentityType}
              onChange={handleInputChange}
              required
              isInvalid={!!errors.IdentityType}
              disabled={isReadOnly}
            >
              <option value="">Select</option>
              {Identity.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.IdentityType}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={4} controlId="formGridIdentityNumber">
            <Form.Label>{formData.IdentityType}*</Form.Label>
            <Form.Control
              type="text"
              name="IdentityNumber"
              value={formData.IdentityNumber}
              onChange={handleInputChange}
              minLength={8}
              maxLength={18}
              placeholder={`Enter ${
                formData.IdentityType
                  ? formData.IdentityType + " Number"
                  : "Number"
              }`}
              required
              isInvalid={!!errors.IdentityNumber}
              readOnly={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.IdentityNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={4} controlId="formGridDocument">
            <Form.Label>Upload Document *</Form.Label>
            {formData.Document ? (
              <div>
                <br />
                <span className="badge bg-success">Submitted</span>
              </div>
            ) : (
              <div className="form-control">
                <label className="input-file-label">
                  Upload File
                  <input
                    type="file"
                    accept=".pdf,.jpg,.png"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    required
                    disabled={isReadOnly}
                  />
                </label>
              </div>
            )}
            {fileErrorMessage && (
              <p style={{ color: "red" }}>{fileErrorMessage}</p>
            )}
          </Form.Group>
        </Row>

        <h4>CONTACT INFO</h4>
        <Row className="mb-form">
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label>Address*</Form.Label>
            <Form.Control
              type="text"
              name="Address1"
              value={formData.Address1}
              onChange={handleInputChange}
              placeholder="Enter your address Line 1"
              maxLength={100}
              required
              isInvalid={!!errors.Address1}
              readOnly={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.Address1}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridAddress2">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              type="text"
              name="Address2"
              value={formData.Address2}
              onChange={handleInputChange}
              placeholder="Enter your address Line 2"
              maxLength={100}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Row>
        <Row className="mb-form">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>City*</Form.Label>
            <Form.Control
              type="text"
              name="City"
              value={formData.City}
              onChange={handleInputChange}
              placeholder="Enter your city"
              maxLength={50}
              required
              isInvalid={!!errors.City}
              readOnly={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.City}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>State*</Form.Label>
            <Form.Control
              type="text"
              name="State"
              value={formData.State}
              onChange={handleInputChange}
              placeholder="Enter your state"
              required
              isInvalid={!!errors.State}
              readOnly={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.State}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-form">
          <Form.Group as={Col} controlId="formGridZipCode">
            <Form.Label>Zip Code*</Form.Label>
            <Form.Control
              type="text"
              name="ZipCode"
              value={formData.ZipCode}
              onChange={handleInputChange}
              placeholder="Enter your zip code"
              minLength={6}
              maxLength={6}
              required
              isInvalid={!!errors.ZipCode}
              readOnly={isReadOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ZipCode}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCountry">
            <Form.Label>Country*</Form.Label>
            <Form.Control
              as="select"
              name="Country"
              value={formData.Country}
              onChange={handleInputChange}
              required
              isInvalid={!!errors.Country}
              disabled={isReadOnly}
            >
              <option value="">Select</option>
              {countries.map((country) => (
                <option key={country.CountryId} value={country.CountryId}>
                  {country.Name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.Country}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        {/* <button type="submit" className="submitButton" disabled={isFormValid}>
          Update Profile
        </button>
        {location.pathname === "/complete-profile" && (
          <button type="button" className="nextButton" disabled={!isFormValid}>
            Next
          </button>
        )} */}
      </Form>
    </div>
  );
};

export default BasicInfo;
