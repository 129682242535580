import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../sidebar/sidebar";
import BasicInfo from "../../feature/profileFiling/basicInfo";
import KYC from "./Kyc";
import BankAccount from "./bank-account";
import NomineeDetails from "./nominee-details";
import ChangePassword from "./(auth)/changePassword";
import Apin from "./Apin";
import KYCStatusAlert from "../../feature/kycStatusBanner/kycStatusBanner";
import axios from "axios";
import Cookies from "js-cookie";

const MyAccount = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [activeComponent, setActiveComponent] = useState("basicInfo");
  const [kycStatus, setKycStatus] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${baseUrl}/getUserData`, config);
        setKycStatus(response.data.members?.KYCStatus);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [baseUrl]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActiveComponent(hash || "basicInfo");
    };

    window.addEventListener("hashchange", handleHashChange);

    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "basicInfo":
        return <BasicInfo baseUrl={baseUrl} />;
      case "kyc":
        return <KYC baseUrl={baseUrl} />;
      case "bank-account":
        return <BankAccount baseUrl={baseUrl} />;
      case "nominee-details":
        return <NomineeDetails baseUrl={baseUrl} />;
      case "change-password":
        return <ChangePassword baseUrl={baseUrl} />;
      case "apin":
        return <Apin baseUrl={baseUrl} />;

      default:
        return <BasicInfo baseUrl={baseUrl} />;
    }
  };

  return (
    <Container>
      <Row className="mb-form d-flex my-5 py-5">
        <KYCStatusAlert kycStatus={kycStatus} />

        <Col md={3} className="sidebar-static">
          <Sidebar />
        </Col>
        <Col md={9} className="content-scroll">
          {renderActiveComponent()}
        </Col>
      </Row>
    </Container>
  );
};

export default MyAccount;
