import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AmInvestCard from "../../components/cards/amInvestCards";
import { Link } from "react-router-dom";
import "./blogListSection.css";
const BlogListSection = () => {
  const [blogs, setBlogs] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    fetch(`${baseUrl}/blogs`)
      .then((response) => response.json())
      .then((data) => setBlogs(data.blogs))
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);

  return (
    <div className="blogListPage">
      <div className="blogTitleSection">
        <h1 className="pageTitle text-center my-4">Blogs</h1>
      </div>
      <Container className="blogCardListComponent">
        <Row className="gy-4">
          {blogs.map((blog, index) => (
            <Col
              key={blog._id}
              md={4}
              className={`blog-card ${index % 2 !== 0 ? "offset-card" : ""}`}
            >
              <Link className={`d-flex`} to={`/blogs/${blog.slug}`}>
                <AmInvestCard
                  type="blogCard"
                  image={blog.image}
                  title={blog.title}
                  description={blog.description}
                  date={blog.date}
                  readTime={`${blog.readTime}`}
                />
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default BlogListSection;
