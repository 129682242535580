import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import "./kycStatusBanner.css";

const KYCStatusAlert = ({ kycStatus }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const hideTime = localStorage.getItem("kycAlertHideTime");
    if (hideTime && new Date(hideTime) > new Date()) {
      setVisible(false);
    }
  }, []);

  const handleClose = () => {
    if (kycStatus === 0) {
      const hideUntil = new Date();
      hideUntil.setHours(hideUntil.getHours() + 12);
      localStorage.setItem("kycAlertHideTime", hideUntil);
    } else if (kycStatus === 3) {
      localStorage.setItem("kycAlertHideTime", "9999-12-31T23:59:59.999Z");
    }
    setVisible(false);
  };

  const handleOnClick = () => {
    if (kycStatus === 0) {
      window.location.href = "/profile-process#basic-profile";
    }
  };

  if (!visible) {
    return null;
  }

  let message = "";
  let variant = "";

  switch (kycStatus) {
    case 3:
      message =
        "We have received your details for KYC. We will update it soon.";
      variant = "success";
      break;
    case 0:
      message = "Please complete your KYC. Please update your information.";
      variant = "danger";
      break;
    default:
      return null;
  }

  return (
    <Alert
      variant={variant}
      className="kycStatusBanner"
      onClick={handleOnClick}
    >
      <p className="initiatedKycText p-0 m-0">{message}</p>
      <Button
        variant="outline-light"
        onClick={handleClose}
        className="closeButtonKYC"
      >
        X
      </Button>
    </Alert>
  );
};

export default KYCStatusAlert;
