import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import ReactPaginate from "react-paginate";

axios.defaults.withCredentials = true;

function Screener() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedRisks, setSelectedRisks] = useState([]);
  const [selectedFundSizes, setSelectedFundSizes] = useState([]);
  const [selectedExpenseRatios, setSelectedExpenseRatios] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [filteredMutualFunds, setFilteredMutualFunds] = useState([]);
  const [mutualFunds, setMutualFunds] = useState([]);
  const [filteredMutualFundsCount, setFilteredMutualFundsCount] = useState(0);
  const [category, setCategory] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [companySearchQuery, setCompanySearchQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [presignedUrls, setPresignedUrls] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const [fundSizeOptions, setFundSizeOptions] = useState([]);
  const [expenseRatioOptions, setExpenseRatioOptions] = useState([]);
  const [ratingOptions, setRatingOptions] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const selectedCategory = urlParams.get("category");
    const selectedCompany = urlParams.get("company");

    if (selectedCategory) {
      setSelectedCategories([decodeURIComponent(selectedCategory)]);
    }

    if (selectedCompany) {
      setSelectedCompanies([decodeURIComponent(selectedCompany)]);
    }

    const page = urlParams.get("page");
    setCurrentPage(page ? parseInt(page, 10) - 1 : 0);
  }, [location.search]);

  useEffect(() => {
    let filteredFunds = mutualFunds;

    if (selectedCompanies.length > 0) {
      filteredFunds = filteredFunds.filter((fund) =>
        selectedCompanies.includes(fund.amc_id.toString())
      );
    }

    if (selectedCategories.length > 0) {
      filteredFunds = filteredFunds.filter((fund) =>
        selectedCategories.includes(fund.category)
      );
    }

    if (selectedRisks.length > 0) {
      filteredFunds = filteredFunds.filter((fund) =>
        selectedRisks.includes(fund.risk)
      );
    }

    if (selectedFundSizes.length > 0) {
      filteredFunds = filteredFunds.filter((fund) =>
        selectedFundSizes.includes(fund.fundSize)
      );
    }

    if (selectedExpenseRatios.length > 0) {
      filteredFunds = filteredFunds.filter((fund) =>
        selectedExpenseRatios.includes(fund.expenseRatio)
      );
    }

    if (selectedRatings.length > 0) {
      filteredFunds = filteredFunds.filter((fund) =>
        selectedRatings.includes(fund.rating)
      );
    }

    if (searchQuery) {
      filteredFunds = filteredFunds.filter((fund) =>
        fund.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredMutualFunds(filteredFunds);
    setFilteredMutualFundsCount(filteredFunds.length);
  }, [
    selectedCompanies,
    selectedCategories,
    selectedRisks,
    selectedFundSizes,
    selectedExpenseRatios,
    selectedRatings,
    mutualFunds,
    searchQuery,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/mfcompanies`);
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [baseUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/mfcategories`);
        setCategory(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [baseUrl]);

  const Riskoptions = [
    { id: 1, name: "Low Risk" },
    { id: 2, name: "Medium Low Risk" },
    { id: 3, name: "Medium Risk" },
    { id: 4, name: "Medium High Risk" },
    { id: 5, name: "High Risk" },
    { id: 6, name: "Very High Risk" },
  ];

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/mutualfunds`)
      .then((response) => {
        setMutualFunds(response.data.mutualFunds);
        setFilteredMutualFundsCount(response.data.totalMutualFunds);

        const fundSizes = [
          ...new Set(response.data.mutualFunds.map((fund) => fund.fundSize)),
        ];
        const expenseRatios = [
          ...new Set(
            response.data.mutualFunds.map((fund) => fund.expenseRatio)
          ),
        ];
        const ratings = [
          ...new Set(response.data.mutualFunds.map((fund) => fund.rating)),
        ];

        setFundSizeOptions(fundSizes);
        setExpenseRatioOptions(expenseRatios);
        setRatingOptions(ratings);

        setLoading(false);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [baseUrl]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const page = urlParams.get("page");
    setCurrentPage(page ? parseInt(page, 10) - 1 : 0);
  }, [location.search]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    navigate(`${location.pathname}?page=${selected + 1}`);
    window.scrollTo(0, 0);
  };

  const handleCheckboxChange = useCallback((e, setSelectedState) => {
    const value = e.target.value;
    setSelectedState((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  }, []);

  const handleCompanySearchChange = (selectedOption) => {
    if (selectedOption) {
      setCompanySearchQuery(selectedOption.label);
      setSelectedCompanies([selectedOption.value]);
    } else {
      setCompanySearchQuery("");
      setSelectedCompanies([]);
    }
  };

  const clearAllFilters = () => {
    setSelectedCompanies([]);
    setSelectedCategories([]);
    setSelectedRisks([]);
    setSelectedFundSizes([]);
    setSelectedExpenseRatios([]);
    setSelectedRatings([]);
    setCompanySearchQuery("");
    setSearchQuery("");
  };

  useEffect(() => {
    const fetchImagesForCurrentPage = async () => {
      const startIdx = currentPage * itemsPerPage;
      const fundsToDisplay = filteredMutualFunds.slice(
        startIdx,
        startIdx + itemsPerPage
      );

      for (const fund of fundsToDisplay) {
        try {
          const response = await axios.get(`${baseUrl}/fund-logo/${fund.id}`, {
            withCredentials: true,
          });
          setPresignedUrls((prev) => ({
            ...prev,
            [fund.id]: response.data.url,
          }));
        } catch (error) {
          console.error(`Error fetching logo URL for fund ${fund.id}:`, error);
          setPresignedUrls((prev) => ({
            ...prev,
            [fund.id]: "https://img.icons8.com/fluency/48/total-sales-1.png",
          }));
        }
      }
    };

    if (filteredMutualFunds.length > 0) {
      fetchImagesForCurrentPage();
    }
  }, [baseUrl, currentPage, filteredMutualFunds, itemsPerPage]);

  const removeFilter = (filter, setSelectedState, filterType) => {
    if (filterType === "Companies") {
      const companyId = companies.find((c) => c.name === filter)?.id.toString();
      if (companyId) {
        setSelectedState((prev) => prev.filter((id) => id !== companyId));
      }
    } else {
      setSelectedState((prev) => prev.filter((item) => item !== filter));
    }
  };

  const SelectedFilters = ({
    selectedCompanies,
    selectedCategories,
    selectedRisks,
    selectedFundSizes,
    selectedExpenseRatios,
    selectedRatings,
    removeFilter,
    companies,
  }) => {
    const filterItems = [
      {
        title: "Companies",
        items: selectedCompanies.map((id) => {
          const company = companies.find((c) => c.id.toString() === id);
          return company ? company.name : id;
        }),
        setSelectedState: setSelectedCompanies,
      },
      {
        title: "Categories",
        items: selectedCategories,
        setSelectedState: setSelectedCategories,
      },
      {
        title: "Risks",
        items: selectedRisks,
        setSelectedState: setSelectedRisks,
      },
      {
        title: "Fund Sizes",
        items: selectedFundSizes,
        setSelectedState: setSelectedFundSizes,
      },
      {
        title: "Expense Ratios",
        items: selectedExpenseRatios,
        setSelectedState: setSelectedExpenseRatios,
      },
      {
        title: "Ratings",
        items: selectedRatings,
        setSelectedState: setSelectedRatings,
      },
    ];

    return (
      <div className="selected-filters">
        {filterItems.map(
          (filter) =>
            filter.items.length > 0 && (
              <div className="selectedFilterIs" key={filter.title}>
                {filter.items.map((item) => (
                  <span key={item} className="selected-filter-item">
                    {item}{" "}
                    <button
                      className="removeFilter"
                      onClick={() =>
                        removeFilter(
                          item,
                          filter.setSelectedState,
                          filter.title
                        )
                      }
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
            )
        )}
      </div>
    );
  };

  const filteredCompanies = useMemo(() => {
    const result = companies.filter((company) =>
      company?.name?.toLowerCase().includes(companySearchQuery.toLowerCase())
    );
    return result;
  }, [companies, companySearchQuery]);

  const renderMutualFunds = useMemo(() => {
    const startIdx = currentPage * itemsPerPage;
    const fundsToDisplay = filteredMutualFunds.slice(
      startIdx,
      startIdx + itemsPerPage
    );

    return fundsToDisplay.map((fund) => (
      <Link
        to={`/mutualFunds-detail/${fund.id}`}
        className="screener-list"
        key={fund.id}
      >
        <div className="sceener-data">
          <div className="screener-info">
            <div className="d-flex">
              <div>
                <img
                  src={
                    presignedUrls[fund.id] ||
                    "https://img.icons8.com/fluency/48/total-sales-1.png"
                  }
                  alt={fund.name}
                  style={{
                    width: "75px",
                    height: "auto",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div className="d-flex flexCol">
                <span className={`stockName`}>{fund.name}</span>

                <div className="d-flex">
                  <p className="budget">
                    {fund.risk
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </p>
                  <p className="budget">
                    {fund.category.charAt(0).toUpperCase() +
                      fund.category.slice(1)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-data">
          <div className="MY-data rate">
            <span className="value">{fund.return_percentage_6_months}%</span>
            <span>6 Months</span>
          </div>
          <div className="MY-data rate">
            <span className="value">{fund.return_percentage_12_months}%</span>
            <span>1 Year</span>
          </div>
          <div className="MY-data rate">
            <span className="value">{fund.return_percentage_36_months}%</span>
            <span>3 Years</span>
          </div>
        </div>
      </Link>
    ));
  }, [currentPage, filteredMutualFunds, presignedUrls]);

  const SidebarFilter = ({ title, options, selectedOptions, handleChange }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [filterSearchQuery, setFilterSearchQuery] = useState("");

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    const filteredOptions = options.filter((option) =>
      option && option.label
        ? option.label.toLowerCase().includes(filterSearchQuery.toLowerCase())
        : false
    );

    return (
      <div className="filter-section">
        <h4 onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          {title}{" "}
          <span className="accordionCustomArrow">{isOpen ? "▲" : "▼"}</span>
        </h4>
        {isOpen && (
          <>
            <input
              type="text"
              placeholder={`Search ${title}`}
              value={filterSearchQuery}
              onChange={(e) => setFilterSearchQuery(e.target.value)}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            {filteredOptions.map((option) => (
              <div key={option.id} className="filterName">
                <input
                  type="checkbox"
                  value={option.value}
                  checked={selectedOptions.includes(option.value)}
                  onChange={(e) => handleChange(e)}
                />
                <label>{option.label}</label>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };
  return (
    <section className="mutualFundsListPage">
      <div className="filter-sidebar">
        <div
          style={{
            position: "sticky",
            top: "0",
            backgroundColor: "#fff",
            zIndex: "1",
          }}
        >
          <span className="spanText">Filters</span>
          <button onClick={clearAllFilters} className="clearAllButton">
            Clear All
          </button>
        </div>
        <SidebarFilter
          title="AMC"
          options={companies.map((company) => ({
            id: company.id,
            value: company.id.toString(),
            label: company.name,
          }))}
          selectedOptions={selectedCompanies}
          handleChange={(e) => handleCheckboxChange(e, setSelectedCompanies)}
        />
        <SidebarFilter
          title="Category"
          options={category.map((cat) => ({
            id: cat.id,
            value: cat.category,
            label: cat.category,
          }))}
          selectedOptions={selectedCategories}
          handleChange={(e) => handleCheckboxChange(e, setSelectedCategories)}
        />
        <SidebarFilter
          title="Risk"
          options={Riskoptions.map((risk) => ({
            id: risk.id,
            value: risk.name,
            label: risk.name,
          }))}
          selectedOptions={selectedRisks}
          handleChange={(e) => handleCheckboxChange(e, setSelectedRisks)}
        />
        {/* <SidebarFilter
          title="Fund Size"
          options={fundSizeOptions.map((size, index) => ({
            id: index,
            value: size,
            label: size,
          }))}
          selectedOptions={selectedFundSizes}
          handleChange={(e) => handleCheckboxChange(e, setSelectedFundSizes)}
        />
        <SidebarFilter
          title="Expense Ratio"
          options={expenseRatioOptions.map((ratio, index) => ({
            id: index,
            value: ratio,
            label: ratio,
          }))}
          selectedOptions={selectedExpenseRatios}
          handleChange={(e) =>
            handleCheckboxChange(e, setSelectedExpenseRatios)
          }
        />
        <SidebarFilter
          title="Rating"
          options={ratingOptions.map((rating, index) => ({
            id: index,
            value: rating,
            label: rating,
          }))}
          selectedOptions={selectedRatings}
          handleChange={(e) => handleCheckboxChange(e, setSelectedRatings)}
        /> */}
      </div>
      <div className="fundsListAndSearch">
        {" "}
        <div className="search-container mt-3 mb-2">
          <input
            type="text"
            placeholder="Search mutual funds..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <span className="main-title mfCounts">
            {filteredMutualFundsCount === 0
              ? "No Mutual Funds Found"
              : `${filteredMutualFundsCount} Mutual Funds Available`}
          </span>
        </div>
        <div className="funds-list">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "10px",
              }}
            >
              <Button style={{ backgroundColor: "#416052" }} disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
            </div>
          ) : (
            <>
              <SelectedFilters
                selectedCompanies={selectedCompanies}
                selectedCategories={selectedCategories}
                selectedRisks={selectedRisks}
                selectedFundSizes={selectedFundSizes}
                selectedExpenseRatios={selectedExpenseRatios}
                selectedRatings={selectedRatings}
                removeFilter={removeFilter}
                companies={companies}
              />

              {renderMutualFunds}

              <div className="pagination-container">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(filteredMutualFundsCount / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  initialPage={currentPage}
                  forcePage={currentPage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Screener;
