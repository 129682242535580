import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUserImage } from "../context/userImageContext";
import useUserData from "../useEffect/useUserData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Cookies from "js-cookie";

const Sidebar = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { userData } = useUserData();
  const { imageUrl, updateImageUrl } = useUserImage();
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeTab, setActiveTab] = useState("basicInfo");
  const location = useLocation();

  useEffect(() => {
    const handleTabChange = () => {
      const hash = location.hash.substring(1);
      setActiveTab(hash || "basicInfo");
    };
    handleTabChange();
  }, [location]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    window.location.hash = tabName;
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const saveImageToDatabase = async () => {
    try {
      const profilePicture = await uploadFile(selectedImage, "PROFILEPICTURE");

      const dataToSubmit = {
        ProfilePicture: profilePicture,
      };

      const token = Cookies.get("token");
      await axios.post(`${baseUrl}/updateProfilePicture`, dataToSubmit, {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      });

      const presignedUrlResponse = await axios.get(
        `${baseUrl}/generate-presigned-url?Path=${encodeURIComponent(
          profilePicture.split("ambassador.docs/")[1]
        )}`,
        {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        }
      );

      if (presignedUrlResponse.status === 200) {
        const newImageUrl = presignedUrlResponse.data.url;
        updateImageUrl(newImageUrl);
        Cookies.set("profileImageUrl", newImageUrl);
      } else {
        throw new Error("Failed to fetch presigned URL");
      }
    } catch (error) {
      console.error("Error updating profile picture:", error);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      saveImageToDatabase();
    }
  }, [selectedImage]);

  const uploadFile = async (file, fileType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", fileType);
    try {
      const token = Cookies.get("token");

      const response = await fetch(`${baseUrl}/upload-document`, {
        method: "POST",
        body: formData,
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error(error, "error");
    }
  };

  return (
    <div className="sidebar">
      <div className="user-block">
        <div className="user-img">
          <div style={{ position: "relative" }}>
            <label htmlFor="profile-picture-input">
              <img
                className="img-property mx-2"
                title="Click to update profile picture"
                src={
                  selectedImage ? URL.createObjectURL(selectedImage) : imageUrl
                }
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                alt="profile"
              />
              <FontAwesomeIcon className="edit-icon" icon={faEdit} />
            </label>
          </div>
          <input
            id="profile-picture-input"
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </div>
        <div className="user-name">
          {userData?.members?.FirstName} {userData?.members?.LastName}
        </div>
      </div>
      <ul>
        <li
          style={{
            backgroundColor: activeTab === "basicInfo" ? "#416052" : "",
          }}
        >
          <Link
            className="sidebar-link"
            to="#basicInfo"
            onClick={() => handleTabClick("basicInfo")}
            style={{ color: activeTab === "basicInfo" ? "white" : "" }}
          >
            My Profile
          </Link>
        </li>
        <li style={{ backgroundColor: activeTab === "kyc" ? "#416052" : "" }}>
          <Link
            className="sidebar-link"
            to="#kyc"
            onClick={() => handleTabClick("kyc")}
            style={{ color: activeTab === "kyc" ? "white" : "" }}
          >
            KYC Documents
          </Link>
        </li>
        <li
          style={{
            backgroundColor: activeTab === "bank-account" ? "#416052" : "",
          }}
        >
          <Link
            className="sidebar-link"
            to="#bank-account"
            onClick={() => handleTabClick("bank-account")}
            style={{ color: activeTab === "bank-account" ? "white" : "" }}
          >
            Bank Accounts
          </Link>
        </li>
        <li
          style={{
            backgroundColor: activeTab === "nominee-details" ? "#416052" : "",
          }}
        >
          <Link
            className="sidebar-link"
            to="#nominee-details"
            onClick={() => handleTabClick("nominee-details")}
            style={{ color: activeTab === "nominee-details" ? "white" : "" }}
          >
            Nominee Details
          </Link>
        </li>
        <li
          style={{
            backgroundColor: activeTab === "change-password" ? "#416052" : "",
          }}
        >
          <Link
            className="sidebar-link"
            to="#change-password"
            onClick={() => handleTabClick("change-password")}
            style={{ color: activeTab === "change-password" ? "white" : "" }}
          >
            Change Password
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
