import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./calculators.css";

const ReturnCalculator = ({ returnsData }) => {
  const [investmentAmount, setInvestmentAmount] = useState(15000);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("1Y");
  const [mode, setMode] = useState("sip");

  const getReturnPercentage = () => {
    switch (selectedTimePeriod) {
      case "1Y":
        return returnsData?.["DP-Return1Yr"] ?? 0;
      case "3Y":
        return returnsData?.["DP-Return3Yr"] ?? 0;
      case "5Y":
        return returnsData?.["DP-Return5Yr"] ?? 0;
      default:
        return 0;
    }
  };

  const calculateReturns = () => {
    const returnPercentage = getReturnPercentage();
    const totalInvestment =
      mode === "sip"
        ? investmentAmount * 12 * parseInt(selectedTimePeriod)
        : investmentAmount;
    const finalAmount = totalInvestment * (1 + returnPercentage / 100);
    return finalAmount.toFixed(2);
  };

  const totalInvestment =
    mode === "sip"
      ? investmentAmount * 12 * parseInt(selectedTimePeriod)
      : investmentAmount;
  const finalAmount = calculateReturns();
  const returnPercentage = getReturnPercentage();

  return (
    <div className="calculator-container">
      <div className="investment-mode">
        <Button
          variant={mode === "sip" ? "success" : "light"}
          onClick={() => setMode("sip")}
        >
          Monthly SIP
        </Button>
        <Button
          variant={mode === "oneTime" ? "success" : "light"}
          onClick={() => setMode("oneTime")}
        >
          One-Time
        </Button>
      </div>
      <div className="amount-input">
        <label>
          {mode === "sip"
            ? `₹${investmentAmount} per month`
            : `₹${investmentAmount} one-time`}
        </label>
        <input
          type="range"
          min="1000"
          max="100000"
          value={investmentAmount}
          onChange={(e) => setInvestmentAmount(Number(e.target.value))}
        />
      </div>
      <div className="time-period">
        <Button
          variant={selectedTimePeriod === "1Y" ? "success" : "light"}
          onClick={() => setSelectedTimePeriod("1Y")}
        >
          1 year
        </Button>
        <Button
          variant={selectedTimePeriod === "3Y" ? "success" : "light"}
          onClick={() => setSelectedTimePeriod("3Y")}
        >
          3 years
        </Button>
        <Button
          variant={selectedTimePeriod === "5Y" ? "success" : "light"}
          onClick={() => setSelectedTimePeriod("5Y")}
        >
          5 years
        </Button>
      </div>
      <div className="result-display">
        <p>Total investment of ₹{totalInvestment.toLocaleString()}</p>
        <p className={returnPercentage >= 0 ? "positive" : "negative"}>
          Would have become ₹{finalAmount.toLocaleString()} ({returnPercentage}
          %)
        </p>
      </div>
    </div>
  );
};

export default ReturnCalculator;
