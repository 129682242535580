import { useNavigate } from "react-router-dom";
import "./amInvestCards.css";

const AmInvestCard = ({
  type = "blogCard",
  image,
  pillText,
  title,
  description,
  date,
  readTime,
  onClick,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate("/blogs/");
    }
  };

  const getClassNames = () => {
    switch (type) {
      case "blogCard":
        return "blogCard";
      case "fundsCard":
        return "fundsCard";
      default:
        return "defaultCard";
    }
  };

  const truncateDescription = (text, limit) => {
    return text.split(" ").slice(0, limit).join(" ") + "...";
  };

  return (
    <div
      className={`cardContainer ${getClassNames()}`}
      onClick={type === "blogCard" ? handleClick : null}
    >
      <article className="amInvestCard">
        {image && type === "fundsCard" && (
          <img src={image} alt="Card image" className="amInvestCardImgTop" />
        )}
        {image && type === "blogCard" && (
          <img src={image} alt="Card image" className="cardImgTopBlog" />
        )}
        {type === "fundsCard" && (
          <div className="amInvestCardBody">
            <h3 className="amInvestCardTitle">{title}</h3>
            <p className="amInvestCardText fundsCardDescription">
              {description}
            </p>
            {date && readTime && (
              <p className="amInvestCardMetadata">
                <small>
                  {date} • {readTime} min read
                </small>
              </p>
            )}
          </div>
        )}
        {type === "blogCard" && (
          <div className="amInvestCardBodyBlog">
            <h3 className="amInvestCardTitleBlog">{title}</h3>
            <p className="amInvestCardTextBlog">
              {truncateDescription(description, 20)}
            </p>
            {date && readTime && (
              <p className="amInvestCardMetadata">
                <small>
                  {date} • {readTime} min read
                </small>
              </p>
            )}
          </div>
        )}
      </article>
    </div>
  );
};

export default AmInvestCard;
