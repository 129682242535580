import { Container, Row, Col, Dropdown, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Nippon from "../images/screener-icon2.png";
import Action from "../images/action-icon.png";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

// Register necessary components for ChartJS
ChartJS.register(ArcElement, Tooltip, Legend);

const Portfolio = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // State variables
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [completedTransactions, setCompletedTransactions] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalCurrentValue, setTotalCurrentValue] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [totalDayChange, setTotalDayChange] = useState(0);
  const [totalDayReturnPercentage, setTotalDayChangePercentage] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${baseUrl}/transaction_data`, config);
        const transactionsData = response.data.transactions;

        // Separate pending and completed transactions
        const pending = transactionsData.filter(
          (transaction) =>
            transaction.history_status === "pending" ||
            transaction.history_status === "processing"
        );
        const completed = transactionsData.filter(
          (transaction) => transaction.history_status === "completed"
        );

        // Calculate total investment for completed transactions
        const totalInvestmentCompleted = completed.reduce(
          (acc, curr) => acc + curr.transaction_amount,
          0
        );
        setTotalInvestment(totalInvestmentCompleted);

        // Fetch NAV and calculate metrics for completed transactions
        const transactionsWithNavPromises = completed.map(
          async (transaction) => {
            const navResponse = await axios.get(
              `${baseUrl}/nav_history/${transaction.scheme_code}`,
              config
            );
            const latestNav = navResponse.data.latestNav.nav;
            const oneBeforeLatestNav = navResponse.data.oneBeforeLatestNav.nav;

            // Calculate current value based on the latest NAV
            const currentValue = latestNav * transaction.quantity;

            // Calculate Return
            const returnPercentage =
              ((currentValue - transaction.transaction_amount) /
                transaction.transaction_amount) *
              100;

            // Calculate Day Change
            const dayChangePercentage =
              ((latestNav - oneBeforeLatestNav) / oneBeforeLatestNav) * 100;

            return {
              ...transaction,
              latestNav,
              currentValue,
              returnPercentage,
              dayChangePercentage,
            };
          }
        );

        const transactionsWithNav = await Promise.all(
          transactionsWithNavPromises
        );

        // Calculate total metrics for completed transactions
        const totalCurrentValueCompleted = transactionsWithNav.reduce(
          (acc, curr) => acc + curr.currentValue,
          0
        );
        setTotalCurrentValue(totalCurrentValueCompleted);

        const totalReturnCompleted = transactionsWithNav.reduce(
          (acc, curr) => acc + (curr.currentValue - curr.transaction_amount),
          0
        );
        setTotalReturn(totalReturnCompleted);

        const totalDayChangeCompleted = transactionsWithNav.reduce(
          (acc, curr) => acc + curr.dayChangePercentage,
          0
        );
        setTotalDayChange(totalDayChangeCompleted);

        const totalDayChangePercentage =
          totalDayChangeCompleted / completed.length;
        setTotalDayChangePercentage(totalDayChangePercentage);

        // Set pending and completed transactions in separate states
        setPendingTransactions(pending);
        setCompletedTransactions(transactionsWithNav);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [baseUrl]);

  // Calculate return percentage for display
  const returnPercentage =
    ((totalCurrentValue - totalInvestment) / totalInvestment) * 100;

  // Data for actual pie chart
  const pieChartData = {
    labels: ["Invested", "Return"],
    datasets: [
      {
        data: [totalInvestment, totalReturn],
        backgroundColor: ["#36A2EB", "#FF6384"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  // Data for dummy pie chart
  const dummyPieChartData = {
    labels: ["Dummy Invested", "Dummy Return"],
    datasets: [
      {
        data: [100000, 50000],
        backgroundColor: ["#FFCE56", "#4BC0C0"],
        hoverBackgroundColor: ["#FFCE56", "#4BC0C0"],
      },
    ],
  };

  // Options for pie charts
  const pieChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed !== undefined) {
              label += new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(context.parsed);
            }
            return label;
          },
        },
      },
      legend: {
        position: "bottom",
      },
    },
  };

  if (loading) {
    return (
      <Container>
        <Row className="justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Row>
      </Container>
    );
  }

  return (
    <div className={`container my-5 pt-5 `}>
      {/* <KYCNotification /> */}
      <div className="portfolio-area">
        {pendingTransactions.length > 0 && (
          <div className="d-flex justify-content-between mb-4">
            <h2 className="main-title mb-0">
              Pending Transactions ({pendingTransactions.length})
            </h2>
          </div>
        )}

        <div className="portfolio-box">
          {pendingTransactions.map((transaction) => (
            <Row key={transaction.transaction_id}>
              <Col lg={12}>
                <div
                  className="screener-list portfolio-list"
                  style={{ backgroundColor: "#FBECC7" }}
                >
                  <div className="sceener-data">
                    <div className="list-icon">
                      <img src={Nippon} alt="logo" />
                    </div>
                    <div className="screener-info">
                      <p>
                        {transaction.name.length > 40
                          ? transaction.name.substring(0, 40) + "..."
                          : transaction.name}
                      </p>
                      <div className="d-flex">
                        <Link to="" className="budget">
                          {transaction.history_type.toUpperCase()}
                        </Link>
                      </div>
                    </div>
                    <div className="mobile-data">
                      <div className="rate">
                        <span className="value">
                          ₹
                          {transaction.transaction_amount.toLocaleString(
                            "en-IN"
                          )}
                        </span>
                        <span>Invested</span>
                      </div>
                      <div className="MY-data rate">
                        <span className="value">Pending</span>
                        <span>Status</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      </div>
      <div className="portfolio-area">
        <div className="d-flex justify-content-between mb-4">
          <h3 className="main-title mb-0">
            My Portfolio ({completedTransactions.length})
          </h3>
        </div>
        <div className="portfolio-detail">
          <Row>
            <Col lg={3} md={6}>
              <div className="portfolio-block">
                <div className="title">Your Investments</div>
                <div className="value">
                  ₹{totalInvestment.toLocaleString("en-IN")}
                </div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="portfolio-block">
                <div className="title">Current Value</div>
                <div className="value">
                  <span className="text-green">
                    ₹
                    {totalCurrentValue.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="portfolio-block">
                <div className="title">Profit / Loss</div>
                <div className="value">
                  ₹{totalReturn.toFixed(2)}{" "}
                  <span className="text-green small-text">
                    {totalReturn
                      ? ((totalReturn / totalInvestment) * 100).toFixed(2)
                      : "0.00"}
                    %
                  </span>
                </div>
                <div className="icon-stock"></div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="portfolio-block">
                <div className="title">Day Change</div>
                <div className="value">
                  ₹{totalDayChange.toFixed(2)}{" "}
                  <span className="text-green small-text">
                    {totalDayReturnPercentage.toFixed(2)}%
                  </span>
                </div>
                <div className="icon-stock"></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <h3 className="main-title my-5">
        XIRR: <span className="text-green">0%</span>
      </h3>
      <div className="portfolio-box">
        {completedTransactions.map((transaction) => (
          <Row key={transaction.transaction_id}>
            <Col lg={12}>
              <div className="screener-list portfolio-list">
                <div className="sceener-data">
                  <div className="list-icon">
                    <img src={Nippon} alt="logo" />
                  </div>
                  <div className="screener-info">
                    <p>
                      {transaction.name.length > 40
                        ? transaction.name.substring(0, 40) + "..."
                        : transaction.name}
                    </p>
                    <div className="d-flex">
                      <Link to="" className="budget">
                        XIRR: 0%
                      </Link>
                      <Link to="" className="budget">
                        {transaction.history_type.toUpperCase()}
                      </Link>
                    </div>
                  </div>
                  <div className="mobile-data">
                    <div className="rate">
                      <span className="value">
                        ₹
                        {transaction.transaction_amount.toLocaleString("en-IN")}
                      </span>
                      <span>Invested</span>
                    </div>
                    <div className="MY-data rate">
                      <span className="value">
                        {transaction.dayChangePercentage.toFixed(2)}%
                      </span>
                      <span>Day Change</span>
                    </div>
                    <div className="rate">
                      <span className="value">
                        {transaction.returnPercentage?.toFixed(2)}%
                      </span>
                      <span>Return</span>
                    </div>
                    <div className="MY-data rate">
                      <span className="value">
                        ₹
                        {transaction.currentValue.toLocaleString("en-IN", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </span>
                      <span>Current</span>
                    </div>
                    <div className="action">
                      <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                          <img src={Action} alt="logo" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">Invest</Dropdown.Item>
                          <Dropdown.Item href="#">Redeem</Dropdown.Item>
                          <Dropdown.Item href="#">Transactions</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </div>
      {totalInvestment > 0 && totalReturn !== 0 && (
        <div style={{ width: "300px", margin: "auto" }}>
          <Pie data={pieChartData} options={pieChartOptions} />
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            Return: {returnPercentage.toFixed(2)}%{" "}
            {returnPercentage >= 0 ? "increase" : "decrease"}
          </p>
        </div>
      )}
      {/* <div style={{ width: "300px", margin: "auto" }}>
        <Pie data={dummyPieChartData} options={pieChartOptions} />
        <p style={{ textAlign: "center", marginTop: "10px" }}>Dummy Data</p>
      </div> */}
    </div>
  );
};

export default Portfolio;
