const Testimonial = () => {
  return (
    <div id="content">
      <div className="testimonial">
        <blockquote>
          Securing Retirement Savings for a Finance Professional
        </blockquote>
        <p>
          Neha, a finance professional based in Singapore, was looking to secure
          her retirement savings by investing in India. As an OCI, she was
          familiar with the investment landscape in India but found the process
          of navigating the regulations and paperwork overwhelming.
        </p>
        <p>
          Neha discovered Ambassador and was impressed by the platform's focus
          on providing a hassle-free investment experience for NRIs and OCIs.
          She was able to open an NRO account quickly and start investing in a
          diversified portfolio of Indian government securities and mutual
          funds.
        </p>
        <p>
          Ambassador's team of experts guided Neha through the entire process,
          from account setup to investment selection and portfolio management.
          They also provided valuable insights on tax optimization and
          repatriation, ensuring that Neha's investments were structured
          efficiently.
        </p>
        <p>
          Neha now has peace of mind knowing that her retirement savings are
          securely invested in India's stable financial instruments, with the
          added benefit of potential currency appreciation. She is confident
          that her investments with Ambassador will contribute to a comfortable
          retirement.
        </p>
        <div></div>
        <p>Neha &mdash; Singapore</p>
      </div>
    </div>
  );
};

export default Testimonial;
