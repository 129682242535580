import { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import AmInvestAccordion from "../../components/accordions/amInvestaccordion";
import "../../modules/faqSection/faqSection.css";
import { Helmet } from "react-helmet";

const FaqsPage = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch(`${baseUrl}/faqs`);
        if (response.ok) {
          const data = await response.json();
          setFaqData(data.faqs);
        } else {
          console.error("Failed to fetch FAQs");
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  return (
    <section style={{ background: "#ECF3F0", padding: "8rem 0" }}>
      <Helmet>
        <link rel="canonical" href="https://ambassadorinvestments.com/faqs" />
      </Helmet>
      <Container>
        <h3 className="faqSectionHeading">FAQs</h3>
        <AmInvestAccordion type="faq" data={faqData} />
        <div className="text-center mt-4"></div>
      </Container>
    </section>
  );
};

export default FaqsPage;
