import { useState, useEffect } from "react";
import { Container, Col, Row, Nav } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Portfolio from "./myPortfolio";
import KYCStatusAlert from "../../feature/kycStatusBanner/kycStatusBanner";

const trendingfund_names = [
  "Canara Robeco Bluechip Equity Fund",
  "HDFC Top 100 Fund",
  "Mirae Asset Large Cap Fund",
  "Edelweiss Mid Cap Fund",
  "PGIM India Midcap Opportunities Fund",
  "SBI Magnum Midcap Fund",
  "Nippon India Small Cap Fund",
  "SBI Small Cap Fund",
];

const Dashboard = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [userData, setUserData] = useState(null);
  const [kycStatus, setKycStatus] = useState(null);
  const [wishlist, setWishlist] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [trendingFundDetails, setTrendingFundDetails] = useState([]);
  const [activeWishlistTab, setActiveWishlistTab] = useState("funds");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${baseUrl}/getUserData`, config);
        setUserData(response.data);
        setKycStatus(response.data.members?.KYCStatus);

        const memberId = response.data.members?.MemberId;
        if (memberId) {
          await fetchWishlist(memberId);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchWishlist = async (memberId) => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${baseUrl}/wishlist/${memberId}`,
          config
        );
        setWishlist(response.data);
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/mfcategories`);
        setCategories(response.data.slice(0, 4));
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/mfcompanies`);
        setCompanies(response.data.slice(0, 4));
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchUserData();
    fetchCategories();
    fetchCompanies();
  }, [baseUrl]);

  const filterWishlistByType = (type) => {
    return wishlist.filter((item) => item.asset_type === type);
  };

  const renderWishlistItem = (item) => {
    const linkTo =
      item.asset_type === "mutual_fund"
        ? `/mutualFunds-detail/${item.asset_id}`
        : `/angel-investments/${item.asset_id}`;

    return (
      <div key={item.asset_id} className="wishListItem">
        <Link to={linkTo} className="wishListItemLink">
          <div className="wishListItemContent">
            <span>{item.fund_name}</span>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <Container className="dashboardContainer">
      <KYCStatusAlert kycStatus={kycStatus} />

      <div className="d-flex">
        <Col md={8} className="mainContent m-2">
          <h2>Welcome, Ambassador.</h2>
          <div className="dashBoardExploreFunds">
            <h4 className="dashboardSecondaryHeadings">Explore funds</h4>
            <div className="flexWrap">
              {categories.length > 0 ? (
                categories.map((category) => (
                  <Link
                    to={`/mutualFunds?category=${encodeURIComponent(
                      category.category
                    )}`}
                    key={category.id}
                    className="exploreFundLink"
                  >
                    <span className="exploreFundLinkContent">
                      <span>{category.category}</span>
                    </span>
                  </Link>
                ))
              ) : (
                <span>Loading categories...</span>
              )}
            </div>
          </div>

          <div className="dashBoardExploreFunds">
            <h4 className="dashboardSecondaryHeadings">Explore AMC</h4>
            <div className="flexWrap">
              {companies.length > 0 ? (
                companies.map((company) => (
                  <Link
                    to={`/mutualFunds?company=${encodeURIComponent(
                      company.id
                    )}`}
                    key={company.id}
                    className="exploreFundLink"
                  >
                    <span className="exploreFundLinkContent">
                      <span>{company.name}</span>
                    </span>
                  </Link>
                ))
              ) : (
                <span>Loading companies...</span>
              )}
            </div>
          </div>

          <div>
            <h4 className="dashboardSecondaryHeadings">Quick Links</h4>
            <div className="dashBoardQuickLinks">
              <Link to={`/calculators`} className="exploreFundLink">
                <span className="exploreFundLinkContent">
                  <span>
                    <img
                      src="https://img.icons8.com/isometric/32/calculator.png"
                      alt="Calculator"
                    />
                  </span>
                  <span>Explore our calculators</span>
                </span>
              </Link>
              <Link to={`/blogs`} className="exploreFundLink">
                <span className="exploreFundLinkContent">
                  <span>
                    <img
                      src="https://img.icons8.com/arcade/32/signing-a-document.png"
                      alt="blogs"
                    />
                  </span>
                  <span>Blogs</span>
                </span>
              </Link>
              <Link to={`/compare-funds`} className="exploreFundLink">
                <span className="exploreFundLinkContent">
                  <span>
                    <img
                      src="https://img.icons8.com/external-flaticons-flat-flat-icons/32/external-compare-medical-ecommerce-flaticons-flat-flat-icons.png"
                      alt="compare funds"
                    />
                  </span>
                  <span>Compare Funds</span>
                </span>
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="trendingSection m-2">
          <div className="trendingContent">
            <Portfolio />
          </div>
          <div className="myWishList">
            <div className="wishlistHeader">
              <span>Wishlist {wishlist.length} items</span>
            </div>
            <Nav variant="tabs" className="wishlist-tabs">
              <Nav.Item>
                <Nav.Link
                  eventKey="funds"
                  active={activeWishlistTab === "funds"}
                  onClick={() => setActiveWishlistTab("funds")}
                >
                  Funds
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="angel"
                  active={activeWishlistTab === "angel"}
                  onClick={() => setActiveWishlistTab("angel")}
                >
                  Angel Companies
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="wishListContainer">
              <div className="wishListItemHeader">
                <span>Name</span>
              </div>
              {activeWishlistTab === "funds"
                ? filterWishlistByType("mutual_fund").map(renderWishlistItem)
                : filterWishlistByType("angel_company").map(renderWishlistItem)}
              {filterWishlistByType(
                activeWishlistTab === "funds" ? "mutual_fund" : "angel_company"
              ).length === 0 && <p>No items in this category.</p>}
            </div>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default Dashboard;
