import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import KYCNotification from "../notification/kyc-notification";
import Sidebar from "../sidebar/sidebar";
import Cookies from "js-cookie";

function Apin() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [newAPIN, setNewAPIN] = useState("");
  const [confirmNewAPIN, setConfirmNewAPIN] = useState("");

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === "newAPIN" && /^\d*$/.test(value)) setNewAPIN(value);
    if (name === "confirmNewAPIN" && /^\d*$/.test(value))
      setConfirmNewAPIN(value);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newAPIN !== confirmNewAPIN) {
      toast.error("New APIN and confirm APIN do not match.");
      return;
    }
    await changeAPIN();
  };

  const changeAPIN = async () => {
    const token = Cookies.get("token");
    try {
      const response = await fetch(`${baseUrl}/generate-apin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
        body: JSON.stringify({ newAPIN }),
      });

      if (response.ok) {
        toast.success("APIN generated successfully");
      } else {
        const responseData = await response.json();
        toast.error(responseData.message || "Failed to generate APIN");
      }
    } catch (error) {
      // console.error("Error:", error);
      toast.error("Failed to generate APIN");
    }
    // finally {

    //   localStorage.removeItem("token");
    // }
  };

  return (
    <Container>
      <Row className="mb-form">
        <div body className="form-data">
          <Form onSubmit={handleSubmit}>
            <div className="generate-pin-data">
              <Row className="main-title">
                <Col md={12}>
                  <h2>GENERATE APIN</h2>
                </Col>
              </Row>
              <Row className="mb-form">
                <Col md={12}>
                  <Form.Label>New Pin</Form.Label>
                  <Form.Control
                    type="text"
                    name="newAPIN"
                    value={newAPIN}
                    onChange={handleChange}
                    maxLength={4}
                    minLength={4}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-form">
                <Col md={12}>
                  <Form.Label>Confirm New Pin</Form.Label>
                  <Form.Control
                    type="text"
                    name="confirmNewAPIN"
                    value={confirmNewAPIN}
                    onChange={handleChange}
                    maxLength={4}
                    minLength={4}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-form">
                <Col md={12}>
                  <Button
                    type="submit"
                    className="btn btn-block submitButton"
                    // style={{ backgroundColor: "#416052", width: "275px" }}
                  >
                    Generate APIN
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Row>
    </Container>
  );
}

export default Apin;
