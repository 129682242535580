import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ambassadorinvestments.com/about-us"
        />
      </Helmet>

      <div className="container mt-3 py-5">
        <h1 className={`mt-5`}>About Us</h1>
        <p>
          Welcome to Ambassador, your gateway to seamless investment in India!
          We empower overseas Indians to invest back home with ease, just like
          their friends and family residing in India. Our mission is to provide
          NRIs and OCIs with a comprehensive, integrated platform to tap into
          India's thriving economy.
        </p>

        <h3>Our Mission</h3>
        <p>
          At Ambassador, our mission is clear: to empower overseas Indians to
          invest in their motherland with the same ease and efficiency as
          residents in India. We offer a robust platform designed to take the
          complexity out of the financial system, allowing NRIs and OCIs to
          diversify their portfolios and maximize their returns.
        </p>

        <h3>The Challenge</h3>
        <p>
          We recognize the overwhelming challenges faced by overseas Indians—our
          'Ambassadors'—when investing in India. The traditional process is
          bogged down by excessive paperwork, endless phone calls, and countless
          bank visits, often leading to frustration and missed opportunities. We
          are here to change that.
        </p>

        <h3>Why India?</h3>
        <p>
          India is a land brimming with opportunities. With its dynamic economy
          and youthful population, the potential for investment is vast.
          However, the intricate financial system and myriad regulations can be
          daunting for NRIs and OCIs. Ambassador is here to simplify this
          journey.
        </p>

        <h3>Join Us</h3>
        <p>
          Are you an NRI or OCI looking to invest in India? Look no further!
          Ambassador is your one-stop solution to make informed investment
          decisions and achieve your financial dreams. With Ambassador, you can
          confidently say to all your Rishtedars: Yes, you are investing in New
          Age India, keeping you close to your motherland from miles away.
        </p>
      </div>
    </>
  );
};

export default AboutUs;
