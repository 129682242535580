import { Container, Row, Col, Dropdown, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Nippon from "../images/screener-icon2.png";
import Action from "../images/action-icon.png";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Portfolio = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [completedTransactions, setCompletedTransactions] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalCurrentValue, setTotalCurrentValue] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [totalDayChange, setTotalDayChange] = useState(0);
  const [totalDayReturnPercentage, setTotalDayChangePercentage] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${baseUrl}/transaction_data`, config);
        const transactionsData = response.data.transactions;

        const pending = transactionsData.filter(
          (transaction) =>
            transaction.history_status === "pending" ||
            transaction.history_status === "processing"
        );
        const completed = transactionsData.filter(
          (transaction) => transaction.history_status === "completed"
        );

        const totalInvestmentCompleted = completed.reduce(
          (acc, curr) => acc + curr.transaction_amount,
          0
        );
        setTotalInvestment(totalInvestmentCompleted);

        const transactionsWithNavPromises = completed.map(
          async (transaction) => {
            const navResponse = await axios.get(
              `${baseUrl}/nav_history/${transaction.scheme_code}`,
              config
            );
            const latestNav = navResponse.data.latestNav.nav;
            const oneBeforeLatestNav = navResponse.data.oneBeforeLatestNav.nav;

            const currentValue = latestNav * transaction.quantity;
            const returnPercentage =
              ((currentValue - transaction.transaction_amount) /
                transaction.transaction_amount) *
              100;
            const dayChangePercentage =
              ((latestNav - oneBeforeLatestNav) / oneBeforeLatestNav) * 100;

            return {
              ...transaction,
              latestNav,
              currentValue,
              returnPercentage,
              dayChangePercentage,
            };
          }
        );

        const transactionsWithNav = await Promise.all(
          transactionsWithNavPromises
        );

        const totalCurrentValueCompleted = transactionsWithNav.reduce(
          (acc, curr) => acc + curr.currentValue,
          0
        );
        setTotalCurrentValue(totalCurrentValueCompleted);

        const totalReturnCompleted = transactionsWithNav.reduce(
          (acc, curr) => acc + (curr.currentValue - curr.transaction_amount),
          0
        );
        setTotalReturn(totalReturnCompleted);

        const totalDayChangeCompleted = transactionsWithNav.reduce(
          (acc, curr) => acc + curr.dayChangePercentage,
          0
        );
        setTotalDayChange(totalDayChangeCompleted);

        const totalDayChangePercentage =
          totalDayChangeCompleted / completed.length;
        setTotalDayChangePercentage(totalDayChangePercentage);

        setPendingTransactions(pending);
        setCompletedTransactions(transactionsWithNav);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [baseUrl]);

  if (loading) {
    return (
      <Container>
        <Row className="justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Row>
      </Container>
    );
  }

  const TransactionRow = ({ transaction, icon }) => {
    const transactionAmount = transaction.transaction_amount || 0;
    const currentValue = transaction.currentValue || 0;
    const dayChangePercentage =
      transaction.dayChangePercentage?.toFixed(2) || "0.00";
    const returnPercentage = transaction.returnPercentage?.toFixed(2) || "0.00";

    const redirectToPortfolio = () => {
      navigate("/portfolio");
    };
    return (
      <Row key={transaction.transaction_id}>
        <Col
          lg={12}
          onClick={redirectToPortfolio}
          style={{ cursor: "pointer" }}
        >
          <div
            className="screener-list portfolio-list"
            style={{ backgroundColor: icon ? "#FBECC7" : "" }}
          >
            <div className="sceener-data">
              <div className="list-icon">
                <img src={icon ? Nippon : ""} alt="logo" />
              </div>
              <div className="screener-info">
                <p>
                  {transaction.name.length > 40
                    ? transaction.name.substring(0, 40) + "..."
                    : transaction.name}
                </p>
                <div className="d-flex">
                  <Link to="" className="budget">
                    {transaction.history_type.toUpperCase()}
                  </Link>
                </div>
              </div>
              <div className="mobile-data">
                <div className="rate">
                  <span className="value">
                    ₹{transactionAmount.toLocaleString("en-IN")}
                  </span>
                  <span className={`portfolioCardLabel`}>Invested</span>
                </div>
                <div className="MY-data rate">
                  <span className="value">
                    {icon ? "Pending" : `${dayChangePercentage}%`}
                  </span>
                  <span className={`portfolioCardLabel`}>Status</span>
                </div>
                <div className="rate">
                  <span className="value">{returnPercentage}%</span>
                  <span className={`portfolioCardLabel`}>Return</span>
                </div>
                <div className="MY-data rate">
                  <span className="value">
                    ₹
                    {currentValue.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                  <span className={`portfolioCardLabel`}>Current</span>
                </div>
                <div className="action">
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <img src={Action} alt="logo" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">Invest</Dropdown.Item>
                      <Dropdown.Item href="#">Redeem</Dropdown.Item>
                      <Dropdown.Item href="#">Transactions</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div className={`mt-5`}>
      <div className="portfolio-area">
        {pendingTransactions.length > 0 && (
          <div className="d-flex justify-content-between mb-4">
            <h2 className="main-title mb-0">
              Pending Transactions ({pendingTransactions.length})
            </h2>
          </div>
        )}

        <div className="portfolio-box">
          {pendingTransactions.map((transaction) => (
            <TransactionRow
              key={transaction.transaction_id}
              transaction={transaction}
              icon={Nippon}
            />
          ))}
        </div>
      </div>
      <div className="portfolio-area">
        <div className="d-flex justify-content-between mb-3">
          <h4 className="main-title mb-0 portfolioTitle">My Portfolio</h4>
        </div>
        <div className="portfolio-detail">
          <Row>
            <Col md={6}>
              <div className="portfolio-block">
                <div className="title">Your Investments</div>
                <div className="value">
                  ₹{totalInvestment.toLocaleString("en-IN")}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="portfolio-block">
                <div className="title">Current Value</div>
                <div className="value">
                  <span className="text-green">
                    ₹
                    {totalCurrentValue.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="portfolio-block">
                <div className="title">Profit / Loss</div>
                <div className="value">
                  ₹{totalReturn.toFixed(2)}{" "}
                  <span className="text-green small-text">
                    {totalReturn
                      ? ((totalReturn / totalInvestment) * 100).toFixed(2)
                      : "0.00"}
                    %
                  </span>
                </div>
                <div className="icon-stock"></div>
              </div>
            </Col>
            <Col md={6}>
              <div className="portfolio-block">
                <div className="title">Day Change</div>
                <div className="value">
                  ₹{totalDayChange.toFixed(2)}{" "}
                  <span className="text-green small-text">
                    {totalDayReturnPercentage.toFixed(2)}%
                  </span>
                </div>
                <div className="icon-stock"></div>
              </div>
            </Col>
          </Row>{" "}
        </div>
      </div>
      <div className="portfolio-box">
        {completedTransactions.map((transaction) => (
          <TransactionRow
            key={transaction.transaction_id}
            transaction={transaction}
          />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
