import React from "react";
import "./communityBanner.css";

const WhatsAppCommunityBanner = () => {
  const whatsappLink = "https://wa.me/message/RAYSLDDHSRUXE1";

  return (
    <div className="banner">
      <div className="banner-content">
        <div className="profile-images">
          {/* {[...Array(7)].map((_, index) => (
            <div key={index} className="profile-image">
              <img
                src={`/api/placeholder/48/48`}
                alt={`Community member ${index + 1}`}
              />
            </div>
          ))} */}
        </div>
        <div className="banner-text">
          <h3>Join our Whatsapp community of NRIs/OCIs like you</h3>
        </div>
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className="join-button"
        >
          Join Community
        </a>
      </div>
    </div>
  );
};

export default WhatsAppCommunityBanner;
