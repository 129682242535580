import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ambassadorinvestments.com/terms&conditions"
        />
      </Helmet>
      <Container>
        <h1>Terms and Conditions</h1>
        <p>
          <strong />
        </p>
        <p>
          <strong>
            1. USER REGISTRATION <br />
          </strong>
        </p>
        <p>
          &#x27A4; I hereby agree and give consent for opening of investment
          account facility with Ambassador Fintech Pvt Ltd , an AMFI registered
          distributor for undertaking online mutual fund transactions and other
          investment products such as Fixed Deposits, Market Linked Securities,
          Private Securities, etc. on website or mobile application ( jointly
          referred to as www.ambassadorinvestments.com ) <br />
        </p>
        <p>
          &#x27A4; I hereby give my consent to be contacted on contact number
          and email id provided by me for providing me information on the
          product and services of Ambassador Fintech Pvt Ltd.
        </p>
        <p>
          <strong>
            2. BANK DETAILS <br />
          </strong>
        </p>
        <p>
          &#x27A4; I hereby declare that the detail of the bank provided on the
          platform belongs to me and the primary account holder of the account
          is me and any refunds/redemption should be credited to the
          aforementioned account which is provided by me. <br />
        </p>
        <p>
          &#x27A4; I hereby declare that the Debit Card and/or Net banking
          account and/or ACH mandate used to make the transaction are linked to
          the bank account of which I am the primary account holder. <br />
        </p>
        <p>
          &#x27A4; I authorize Ambassador Fintech Pvt Ltd and/or its authorized
          service provider(s) to - execute the NACH/Direct Debit mandate,
          subsequent NACH/Direct debit instructions related to my investments
          and electronic money transfer instructions through online payment
          gateway(s)- carry out all mutual fund subscription/ redemption/
          non-financial instructions which I provide through the website
          www.ambassadorinvestments.com using the Email and Password (log-in
          credentials) associated with my Investment Account, and/or through any
          other mode, including on paper; and communicate with the AMC on my
          behalf for financial and non-financial transactions and to receive
          details of my investment from the AMC.
        </p>
        <p>
          <strong>
            3. KYC INFORMATION <br />
          </strong>
        </p>
        <p>
          &#x27A4; I hereby allow Ambassador Fintech Pvt Ltd to utilize my
          information such as PAN, photo, name, father's name, Date of Birth,
          gender, marital status, proof of address, address and signature etc.
          for sending it to KYC Registration Agency (KRA) as part of Know Your
          Customer (KYC) norm &amp; Asset Management Companies (AMC) as part of
          my application to AMCs. This can be used for the purpose of validation
          and to comply with the legal and regulatory requirements. I hereby
          accept that my address will be updated at the AMC as per my KYC
          address.
        </p>
        <p>
          &#x27A4; I hereby accept that the signature and other details
          available in the CVL-KRA records/system may be fetched/used for
          signature verification by AMC/their R&amp;T for processing any
          requests made by me through www.ambassadorinvestments.com and in the
          event of such signature not being available or legible in the CVL KRA
          records/system, I understand and agree that the AMC shall be entitled
          to carry out further checks to validate the authenticity of the
          request and/or to reject/disallow any transaction in connection
          herewith.
        </p>
        <p>
          <strong>4. TERMS OF TRANSACTIONS FOR MUTUAL FUNDS</strong>
        </p>
        <p>
          &#x27A4; I acknowledge that investment products do not pertain to
          Ambassador Fintech Pvt Ltd and the company is not in the business of
          providing investment advice
        </p>
        <p>
          &#x27A4; I acknowledge that Ambassador Fintech Pvt Ltd will forward my
          transaction to AMC after completing "Know Your Client" (KYC) process.
          In the event where KYC process is not completed by me to the
          satisfaction of the AMC, I hereby authorize the AMC, to redeem the
          funds invested in the Scheme, in favour of the applicant, at the
          applicable NAV prevailing on the date of such redemption and undertake
          such other action with such funds that may be required by the law.
        </p>
        <p>
          &#x27A4; I understand that Ambassador Fintech Pvt Ltd will process my
          instructions on a best effort basis. I understand that my instructions
          will be processed the same business day if received before the cut-off
          time(s) specified by Ambassador Fintech Pvt Ltd, Else my instructions
          will be processed on the next business day. Ambassador Fintech Pvt Ltd
          may specify cut-off time(s) earlier than the time(s) specified by the
          AMC/ SEBI. I will not dispute and/or hold Ambassador Fintech Pvt Ltd
          responsible for any loss, notional or otherwise, incurred because of{" "}
          <br />
        </p>
        <p>
          1. Delays either at the Bank, Registrar and Transfer Agency or AMC.
        </p>
        <p>
          2. Rejection of my instructions by the Bank, Registrar and Transfer
          Agency or AMC.
        </p>
        <p>
          3. Processing of instructions authenticated by my Login Credentials.
        </p>
        <p>
          4. Non-availability or non-accessibility of the Website, telephone(s),
          or office(s) of Ambassador Fintech Pvt Ltd in case of circumstances
          beyond Ambassador Fintech Pvt Ltd.’s control.
        </p>
        <p>
          &#x27A4; I confirm that the ARN holder (Ambassador Fintech Pvt Ltd)
          has disclosed to me all the commissions &nbsp; (trail commission or
          any other mode), payable to them for the different competing Schemes
          of various Mutual Funds amongst which the Scheme is being recommended
          to me. I confirm that details provided by me are true and correct.
        </p>
        <p>
          &#x27A4; I understand that the ARN holder (Ambassador Fintech Pvt Ltd)
          would help me maintain asset allocation in line with the risk profile.
          I will not dispute and/or hold Ambassador Fintech Pvt Ltd responsible
          for any loss, notional or otherwise, incurred because of suggested
          investment and withdrawal amounts in different mutual fund schemes. I
          also understand ARN holder would do this on a best effort basis.
        </p>
        <p>
          &#x27A4; I acknowledge if I have placed a redemption (full or partial)
          request from a channel other ARN holder (Ambassador Fintech Pvt Ltd)
          then the available withdrawal amount on www.ambassadorinvestments.com
          may not be in sync. I acknowledge that under such circumstances
          redemption order may fail or be partially fulfilled.
        </p>
        <p>
          &#x27A4; I understand that after my withdrawal request is processed by
          Ambassador Fintech Pvt Ltd the money may take up to 1 business day
          (for debt funds) and 3 business days (for equity funds) to be credited
          to my bank account. I confirm that the bank account details displayed
          at the time of redemption request are correct and I am the primary
          account holder of the bank account displayed. I authorize AMC to use
          this bank account to redeem my amount.
        </p>
        <p>
          &#x27A4; I understand that there may be early exit charges as per SID
          / SAI / KIM of the scheme(s) chosen on the platform.
        </p>
        <p>
          <strong>5. Switch Transaction</strong>
        </p>
        <p>
          &#x27A4; When investing in any of the Smart Switch portfolios, the
          user gives Ambassador Fintech Pvt Ltd a permission to process switch
          transactions as per the desired asset allocation and switch duration.
          All such switch transactions across the AMCs will be processed on a
          pre-decided date every month as per the first investment date in the
          portfolio. Switch transactions will stop automatically once the end
          asset allocation is achieved at the portfolio level. In case of any
          changes in strategy or if you'd like to pause pre-decided switch
          transactions, then please get in touch with your wealth manager or
          email us on support@ambassadorinvestments.com
        </p>
        <p>
          <strong>6. UNDERTAKING BY USER</strong>
        </p>
        <p>
          &#x27A4; I understand Mutual Fund and market linked investments are
          subject to market risks, read all scheme-related documents carefully.
          Having read and understood platform, I hereby apply for units of the
          scheme. I have read and understood the terms, conditions, details,
          rules and regulations governing the scheme hereby declare that the
          amount invested in the scheme is through legitimate source only and
          does not involve designed for the purpose of the contravention of any
          Act, Rules, Regulations, Notifications or Directives of the provisions
          of the Income Tax Act, Anti Money Laundering Laws, Anti-Corruption
          Laws or any other applicable laws enacted by the Government of India
          from time to time. I have not received nor have been induced by any
          rebate or gifts, directly or indirectly in making this investment. I
          confirm that the funds invested in the Scheme, legally belongs to me.
        </p>
        <p>
          &#x27A4; I hereby accept that for any transactions that are submitted
          offline i.e. with wet signatures, the signature available in my KYC
          records would be used for signature verification. I agree and
          understand that I shall be solely liable to inform the Distributor/AMC
          in case of any change in my KYC information including change in
          signature etc. and in the event of my signature not being updated,
          available or legible in KYC records, the Distributor/AMC would be
          within its rights to carry out further checks to validate the
          authenticity of the request or reject any such offline request based
          on existing KYC information.
        </p>
        <p>
          &#x27A4; I shall execute and deliver to Ambassador Fintech Pvt Ltd
          from time to time such other documents as may be required for updation
          of records if any. I further agree that any false/misleading
          information given by me or suppression of any material fact will
          render my account liable for termination.
        </p>
        <p>
          <strong>8. ACKNOWLEDGEMENT</strong>
        </p>
        <p>
          &#x27A4; I acknowledge that I have read the Terms and Conditions which
          are applicable for investment account facility on Ambassador Fintech
          Pvt Ltd and that I have read and understood the said Terms and
          Conditions and that a copy of the Terms and Conditions is available on
          www.ambassadorinvestments.com and I hereby severally agree and
          undertake to abide by these Terms and Conditions. I further agree to
          accept the updated Terms and Conditions as may be notified from time
          to time on www.ambassadorinvestments.com in before executing the
          transactions.
        </p>
        <p>
          &#x27A4; I understand and agree that, pursuant to the Terms and
          Conditions, I hereby authorise Ambassador Fintech Pvt Ltd to execute
          my instructions for purchase/redemption/switch/ sell or other
          transaction/activities in respect of online mutual fund transactions
          on my behalf, as may be instructed by me from time to time on
          www.ambassadorinvestments.com
        </p>
        <p>
          &#x27A4; I hereby declare that the details furnished through the
          process are true and correct to the best of my knowledge and belief,
          and I undertake to inform you of any changes therein, immediately. In
          case any of the above information is found to be false or untrue or
          misleading or misrepresenting, I am aware that I may be held liable
          for it and my account may become liable for termination.
        </p>
        <p>
          &#x27A4; The laws of India govern this Agreement. All disputes are
          subject, firstly, to mediation, and then by Arbitration by a sole
          arbitrator appointed by Ambassador Fintech Pvt Ltd. The venue of
          arbitration will be Ahmedabad, Gujarat.
        </p>
        <p>
          &#x27A4; I declare that I am not politically exposed, neither a
          relative of politically exposed person. My primary source of income is
          salary or business income. I hereby declare that the information
          provided is true and correct to the best of my knowledge. In the event
          of any change in the information provided, I will notify Ambassador
          Fintech Pvt Ltd (www.ambassadorinvestments.com) immediately.
        </p>
      </Container>
    </>
  );
};

export default TermsAndConditions;
