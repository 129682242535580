import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb, Row, Col } from "react-bootstrap";
import "./blogsPage.css";

const BlogPage = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { blogName } = useParams();
  const [blog, setBlog] = useState(null);
  const [headings, setHeadings] = useState([]);
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    if (blogName) {
      fetch(`${baseUrl}/blogs/${blogName}`)
        .then((response) => response.json())
        .then((data) => {
          setBlog(data);
          setKeywords(data.keywords || []);
          extractHeadings(data.content);
        })
        .catch((error) => console.error("Error fetching blog:", error));
    }
  }, [blogName]);

  const extractHeadings = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const headingElements = doc.querySelectorAll("h1, h2, h3, h4, h5, h6");
    const extractedHeadings = Array.from(headingElements).map(
      (heading, index) => ({
        id: `heading-${index}`,
        text: heading.textContent,
        level: heading.tagName.toLowerCase(),
      })
    );
    setHeadings(extractedHeadings);
  };

  if (!blog) {
    return <div>Loading...</div>;
  }

  const addIdToHeadings = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    Array.from(doc.querySelectorAll("h1, h2, h3, h4, h5, h6")).forEach(
      (heading, index) => {
        heading.id = `heading-${index}`;
      }
    );
    return doc.body.innerHTML;
  };

  const contentWithIds = addIdToHeadings(blog.content);

  return (
    <div className="blog-container">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/blogs">Blogs</Breadcrumb.Item>
        <Breadcrumb.Item active>{blog.title}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="blog-content-wrapper">
        <div className="blog-main">
          <div className={`blogDetails`}>
            <h1 className="blog-title">{blog.title}</h1>
            <Row className={`mb-4`}>
              <Col>
                <Col>Published on: {blog.date}</Col>
                {/* Display date directly as string */}
              </Col>
              <Col>Read time: {blog.readTime} mins</Col>
            </Row>
            <div className="keywords">
              {keywords.length > 0
                ? keywords.map((keyword, index) => (
                    <span key={index} className="newLabelTag">
                      {keyword}
                    </span>
                  ))
                : null}
            </div>

            <img className="blog-image my-3" src={blog.image} alt="Blog" />
          </div>
          <div
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: contentWithIds }}
          ></div>
        </div>
        <div className="blog-index">
          <h3>Contents</h3>
          <ul>
            {headings.map((heading) => (
              <li key={heading.id} className={`point-${heading.level}`}>
                <a href={`#${heading.id}`} className={`contentPoints`}>
                  {heading.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
