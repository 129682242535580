import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { toast } from "react-toastify";
import validator from "validator";
import Alert from "react-bootstrap/Alert";
import { useAuth } from "../../context/authContext.js";
import "react-toastify/dist/ReactToastify.css";
import "./authStyles.css";
import Loader from "../../../feature/loader/loader.js";
import { Helmet } from "react-helmet";
import { BiHide, BiShow } from "react-icons/bi";

axios.defaults.withCredentials = true;

function SigninPage({ switchMode, onSignIn }) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
    rememberMe: false,
  });

  const [formValid, setFormValid] = useState(false);
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    createAndSendUserSessionData();
  }, []);

  useEffect(() => {
    const { Email, Password } = formData;
    const isValid = Email.trim() !== "" && Password.trim() !== "";
    setFormValid(isValid && !formErrors.Email && !formErrors.Password);
  }, [formData, formErrors]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "Email" && !validator.isEmail(value)) {
      setFormErrors((prev) => ({ ...prev, Email: "Invalid email address" }));
    } else {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleResendEmail = async () => {
    try {
      await axios.post(`${baseUrl}/resendemail`, { Email: formData.Email });
      setEmailNotVerified(false);
      setResendEmail(true);
      toast.success("Verification email resent successfully");
    } catch (error) {
      toast.error("Failed to resend email");
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValid) {
      try {
        setIsLoading(true); // Show loader
        const combinedData = { ...formData, ...sessionsformData };
        const res = await axios.post(`${baseUrl}/signin`, combinedData);
        if (res.data.success) {
          const token = res.data.token;
          login(token);
          toast.success("Login successful!");
          // Check KYC status
          const kycResponse = await axios.get(`${baseUrl}/getUserData`, {
            headers: { WRGSJGEUWCQN58EGV92D: `Bearer ${token}` },
          });
          const kycStatus = kycResponse.data.members?.KYCStatus || 0;

          if (kycStatus === 1 || kycStatus === 3) {
            navigate("/dashboard");
          } else {
            navigate("/profile-process#kyc-check");
          }
        } else {
          const errors = res.data.errors;
          if (errors && errors.length > 0) {
            errors.forEach((error) => {
              toast.error(error);
              setEmailNotVerified(false);
              setResendEmail(false);
            });
            if (errors.includes("Email not verified")) {
              setEmailNotVerified(true);
            }
          } else {
            toast.error(
              "An error occurred while processing your request. Please try again later"
            );
          }
        }
      } catch (err) {
        console.error("Error in handleSubmit:", err);
        toast.error(
          "An error occurred while processing your request. Please try again later"
        );
      } finally {
        setIsLoading(false); // Hide loader
      }
    }
  };

  const [sessionsformData, setSessionsFormData] = useState({
    status: "",
    platform: "",
    deviceName: "",
    locationIp: "",
  });

  const formatDeviceName = () => {
    const userAgent = navigator.userAgent;
    const matches = userAgent.match(/\(([^)]+)\)/);
    return matches ? matches[1] : "Unknown";
  };

  const getIPAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      if (response.ok) {
        const data = await response.json();
        return data.ip;
      } else {
        console.error("Failed to fetch IP address, using default IP");
        return "0.0.0.0"; // Default IP address
      }
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "0.0.0.0"; // Default IP address
    }
  };
  const isAppEnvironment = () => {
    return typeof window.MyAppBridge !== "undefined";
  };

  const createAndSendUserSessionData = async () => {
    const status = "active";
    const platform = isAppEnvironment() ? "app" : "web";
    const deviceName = formatDeviceName();
    const locationIp = await getIPAddress();

    setSessionsFormData({
      status,
      platform,
      deviceName,
      locationIp,
    });
  };

  const handleLoginWithGoogle = async () => {
    try {
      await createAndSendUserSessionData();
      const { status, platform, deviceName, locationIp } = sessionsformData;
      const redirectUrl = `${baseUrl}/auth/google?status=${status}&platform=${platform}&deviceName=${deviceName}&locationIp=${locationIp}`;

      const newWindow = window.open(
        redirectUrl,
        "GoogleLogin",
        "width=600,height=600"
      );

      const receiveMessage = (event) => {
        if (event.origin !== baseUrl) return;
        if (event.data.success) {
          // Handle successful login here
          newWindow.close();
          toast.success("Login successful!");
        } else {
          toast.error("Google login failed.");
        }
        window.removeEventListener("message", receiveMessage);
      };

      window.addEventListener("message", receiveMessage);
    } catch (error) {
      console.error("Error in handleLogin:", error);
    }
  };

  const handleRegisterClick = () => {
    if (location.pathname === "/sign-in") {
      navigate("/onboarding");
    } else {
      switchMode("onboarding");
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ambassadorinvestments.com/sign-in"
        />
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Container className="mt-5 pt-5">
          <h2 className="text-sucess text-center mb-3 mt-3">
            Welcome to Ambassador
          </h2>
          <Row className="justify-content-center">
            <Col md={6} body className="formComponent">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    className="required"
                    required
                    name="Email"
                    value={formData.Email}
                    onChange={handleInputChange}
                    isinvalid={!!formErrors.Email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.Email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password*</Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder=""
                      className="required"
                      required
                      name="Password"
                      value={formData.Password}
                      onChange={handleInputChange}
                      maxLength={50}
                    />
                    <span
                      className="psw-icon"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <BiShow size={30} />
                      ) : (
                        <BiHide size={30} />
                      )}
                    </span>
                  </div>
                </Form.Group>

                {emailNotVerified && (
                  <Alert variant="danger">
                    Your email address hasn't been verified yet. Please{" "}
                    <strong>
                      <u>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleResendEmail}
                        >
                          click here
                        </span>
                      </u>
                    </strong>{" "}
                    to resend email.
                  </Alert>
                )}
                {resendEmail && (
                  <Alert variant="success">Email sent successfully!</Alert>
                )}
                <Row>
                  <Col md={12}>
                    <div className="row mb-4">
                      <div className="col d-flex justify-content-center">
                        <div className="form-check"></div>
                      </div>
                      <div className="col">
                        <Link to="/forgot_password" className="fgPass">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="submitButton d-block"
                      disabled={!formValid}
                    >
                      Login
                    </button>
                  </Col>
                </Row>
                <Row>
                  <div className="text-center">
                    <br />
                    <p>
                      Not a member?{" "}
                      <span
                        className="register-link"
                        onClick={handleRegisterClick}
                      >
                        Register
                      </span>
                    </p>
                    <p>or sign in with:</p>

                    <Col md={12} className="d-flex justify-content-center">
                      <div
                        className={`googleButton my-3`}
                        onClick={handleLoginWithGoogle}
                      >
                        <span>
                          <img
                            style={{ width: "24px", height: "24px" }}
                            src="/images/googleIcon.svg"
                            alt="login with google"
                          />
                          Sign in with Google
                        </span>
                      </div>
                    </Col>
                  </div>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
export default SigninPage;
