import { useState, useEffect } from "react";
import { Container, Row, Col, Form, Spinner, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

function NomineeDetails() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [usernomineedata, setUsernomineedata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nomineeForms, setNomineeForms] = useState([]);

  useEffect(() => {
    const fetchNomineeData = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${baseUrl}/getNomineeData`, config);
        setUsernomineedata(response.data.nomineeDetails);
        if (response.data.nomineeDetails.length > 0) {
          setNomineeForms(response.data.nomineeDetails);
        } else {
          setNomineeForms([
            { Name: "", Relation: "", DateOfBirth: "", Percentage: "" },
          ]);
        }
        setLoading(false);
      } catch (error) {
        console.error("API Error:", error);
        // localStorage.removeItem("token");
      }
    };
    fetchNomineeData();
  }, [baseUrl, navigate]);

  return (
    <Container>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <Button style={{ backgroundColor: "#416052" }} disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <div>
          <Row className="mb-form">
            <div body className="form-data">
              {nomineeForms.length === 0 ? (
                <h2 className="main-title">No Nominee data found.</h2>
              ) : (
                <Form>
                  {nomineeForms.map((nominee, index) => (
                    <div key={index} body className="form-data">
                      <h3>NOMINEE {index + 1} DETAILS</h3>
                      <Row className="mb-form">
                        <Col md={6}>
                          <Form.Label>Nominee Name*</Form.Label>
                          <Form.Control
                            type="text"
                            name="Name"
                            value={nominee.Name}
                            placeholder="Enter Nominee Name"
                            minLength={5}
                            maxLength={50}
                            required
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Label>Relation*</Form.Label>
                          <Form.Control
                            as="select"
                            name="Relation"
                            value={nominee.Relation}
                            required
                            disabled
                          >
                            <option value="">Select Relation</option>
                            <option value="Husband">Husband</option>
                            <option value="Wife">Wife</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Other">Other</option>
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row className="mb-form">
                        <Col md={6}>
                          <Form.Label>Date Of Birth*</Form.Label>
                          <Form.Control
                            type="date"
                            name="DateOfBirth"
                            value={nominee.DateOfBirth}
                            required
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Label>Percentage*</Form.Label>
                          <Form.Control
                            type="text"
                            name="Percentage"
                            value={nominee.Percentage}
                            placeholder="Enter Percentage"
                            required
                            readOnly
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Form>
              )}
            </div>
          </Row>
        </div>
      )}
    </Container>
  );
}

export default NomineeDetails;
