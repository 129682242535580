// src/data/calculatorsData.js
const calculatorsData = [
  {
    title: "SIP",
    description:
      "Calculate how much you need to save or how much you will accumulate with your SIP",
    image: "./images/growMoney.webp",
  },
  {
    title: "Lumpsum",
    description:
      "Calculate returns for lumpsum investments to achieve your financial goals",
    image: "./images/growMoney.webp",
  },
  {
    title: "ROI",
    description: "Calculate your returns %",
    image: "./images/growMoney.webp",
  },
];

export default calculatorsData;
