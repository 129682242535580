import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container footer">
        {/* <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="text-white">
                Designed for Indians living around the world
              </h3>
              <span className="subtitle">Coming soon at your fingertips</span>
              <div className="playstore-link">
                <a
                  href={() => false}
                  className="market-btn google-btn"
                  role="button"
                >
                  <img src="./images/google-play-img.png" alt="" />
                </a>
                <a
                  href={() => false}
                  className="market-btn apple-btn"
                  role="button"
                >
                  <img src="./images/app-store-img.png" alt="" />
                </a>
              </div>
            </div>
          </div> */}
        <div className="row">
          <div className="col-md-4">
            <div className="footer-info">
              <div className="footer-logo">
                <img src="../images/footer-logo.png" alt="logo" />
              </div>
              <p>Ambassador Fintech Pvt Ltd</p>
              <p>AMFI Mutual Fund Distributor - 280241</p>
              <p>support@ambassadorinvestments.com</p>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <h4>Company</h4>
                <ul className="footer-links">
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/blogs">Blog</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <h4>Links</h4>
                <ul className="footer-links">
                  <li>
                    <a href="/calculators">Calculator</a>
                  </li>
                  <li>
                    <Link to="/terms&conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/user-policy">User Policy</Link>
                  </li>
                  {/* <li>
                    <a href={() => false}>Cookies</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="row">
            <div className="col-md-12">
              <div>
                <small>
                  Standard Disclaimer Mutual Fund: Mutual Fund investments are
                  subject to market risks, read all scheme related documents
                  carefully.
                </small>
              </div>
              <div>
                <small>
                  Investment Disclaimer Securities Market: Investments in
                  securities market are subject to market risks, read all the
                  related documents carefully before investing
                </small>
              </div>
              <br></br>
              <div>
                <small>Ahmedabad, Gujarat , India.</small>
              </div>
              {/* <img
                style={{ width: "48px" }}
                src="../images/fancyVentures.png"
              /> */}
            </div>
            <div className="col-md-6">
              <p className="copyright">
                Copyrights {currentYear} Ambassador all rights reserved.
              </p>
            </div>
            <div className="col-md-6">
              <ul className="social-links">
                <li>
                  <Link
                    to={`https://www.linkedin.com/company/ambasaador/`}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      style={{ color: "white" }}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={`https://www.instagram.com/ambassador.investments?igsh=NnVheXMxeTFncGtm`}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      style={{ color: "white" }}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={`https://x.com/Ambassador_inv?t=e4TdEjPlHSQ239eOzp0NGw&s=09`}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      style={{ color: "white" }}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
