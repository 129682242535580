import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [EmailNotVerified, setEmailNotVerified] = useState(false);
  const [ResendEmail, setResendEmail] = useState(false);

  const [formData, setFormData] = useState({
    Email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSendEmail = async () => {
    const Email = formData.Email;
    try {
      await axios.post(`${baseUrl}/forgotPassword`, { Email });
      toast.success("Email sent successfully");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("You have logged in with Google");
          return;
        }

        if (error.response.status === 403) {
          setEmailNotVerified(true);
          toast.error("Email not verified.");
        } else if (error.response.status === 404) {
          toast.error("This email is not registered with us.");
        } else {
          toast.error("Failed to resend email.");
          console.error("Error:", error);
        }
      } else {
        toast.error("Failed to resend email.");
        console.error("Error:", error);
      }
    }
  };

  const handleResendEmail = async () => {
    const Email = formData.Email;
    try {
      await axios.post(`${baseUrl}/forgotPassword`, { Email });
      setEmailNotVerified(false);
      setResendEmail(true);
    } catch (error) {
      toast.error("Failed to resend email");
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ambassadorinvestments.com/forgot_password"
        />
      </Helmet>
      <Container
        style={{
          height: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row className="justify-content-center mt-5">
          <Col>
            <Card body className="form-data login shadow">
              <Form>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Enter you Email Address*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    className="required"
                    required
                    name="Email"
                    value={formData.Email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                {EmailNotVerified ? (
                  <Alert variant="danger">
                    Your email address hasn't been verified yet. Please{" "}
                    <strong>
                      <u>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleResendEmail}
                        >
                          click here
                        </span>
                      </u>
                    </strong>{" "}
                    to Re-send email .
                  </Alert>
                ) : (
                  ""
                )}
                {ResendEmail ? (
                  <Alert variant="success">Email sent successfully!</Alert>
                ) : (
                  ""
                )}

                <Link
                  type="Submit"
                  onClick={handleSendEmail}
                  className="btn-green d-block"
                >
                  Request
                </Link>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
