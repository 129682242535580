import { Row, Col } from "react-bootstrap";
import "./calculatorSection.css";

const CalculatorSection = () => {
  return (
    <section className="container calculatorSection">
      <Row className={`calculatorRow`}>
        <Col>
          <h3>Calculate Brokerage & Margin easily</h3>
        </Col>
        <Col className={`calculatorCol`}>
          <a href="/calculators/sip" className="calculatorButton arrowRightSvg">
            SIP Calculator{" "}
            <svg width="13px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </a>
          <a
            href="/calculators/lumpsum"
            className="calculatorButton arrowRightSvg"
          >
            Lumpsum Calculator{" "}
            <svg width="13px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </a>
          {/* <button className="calculatorButton arrowRightSvg">
            Margin Calculator &nbsp;{" "}
            <svg width="13px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </button> */}
        </Col>
        <Col className="exploreButton arrowRightSvg">
          <a href="/calculators" className="cta">
            <span>Explore our calculators</span>
            <svg width="13px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </a>
        </Col>
      </Row>
    </section>
  );
};

export default CalculatorSection;
