import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthModal from "../authModule/authModal";
import userImg from "../../components/images/avatar-1.png";
import { useUserImage } from "../../components/context/userImageContext";
import { useAuth } from "../../components/context/authContext";
import OfferBanner from "../../components/offerBanner/offerBanner";
import "./navBar.css";
import Cookies from "js-cookie";

const Navbar = () => {
  const { imageUrl, updateImageUrl } = useUserImage();
  const { isLoggedIn, logout } = useAuth();
  const [loading, setLoading] = useState(true);
  const [navbarState, setNavbarState] = useState({
    isOpen: false,
    selectedNav: "",
    isVisible: true,
    isShadowVisible: false,
    prevScrollPos: window.scrollY,
  });
  const [showModal, setShowModal] = useState(false);
  const [authMode, setAuthMode] = useState("signin");
  const [intendedRoute, setIntendedRoute] = useState("/");
  const previousGeneratedUrl = useRef(imageUrl);
  const navigate = useNavigate();
  const location = useLocation();
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const tokenFromUrl = new URLSearchParams(window.location.search).get(
      "token"
    );
    if (tokenFromUrl) {
      Cookies.set("token", tokenFromUrl);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    const token = Cookies.get("token");
    if (!token) {
      Cookies.remove("token");
    }
    setLoading(false);

    const cookieImageUrl = Cookies.get("profileImageUrl");
    if (cookieImageUrl) {
      updateImageUrl(cookieImageUrl);
    }
  }, [updateImageUrl]);

  useEffect(() => {
    if (isLoggedIn && imageUrl && imageUrl !== previousGeneratedUrl.current) {
      previousGeneratedUrl.current = imageUrl;
    }
  }, [imageUrl, isLoggedIn]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setNavbarState((prevState) => ({
        ...prevState,
        isVisible:
          prevState.prevScrollPos > currentScrollPos || currentScrollPos < 50,
        isShadowVisible:
          currentScrollPos > 50 && prevState.prevScrollPos > currentScrollPos,
        prevScrollPos: currentScrollPos,
      }));
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleModalToggle = (mode, route = "/") => {
    setAuthMode(mode);
    setIntendedRoute(route);
    setShowModal(!showModal);
    setNavbarState((prevState) => ({ ...prevState, isOpen: false }));
  };

  const handleSignInSuccess = () => {
    setShowModal(false);
    navigate(intendedRoute);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleNavbarClick = (navItem) => {
    setNavbarState((prevState) => ({
      ...prevState,
      selectedNav: prevState.selectedNav === navItem ? "" : navItem,
      isOpen: false,
    }));
  };

  const handleHamburgerClick = () => {
    setNavbarState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const handleHomeClick = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
    handleNavbarClick("");
  };

  if (loading) {
    return null;
  }

  return (
    <div>
      {showBanner && <OfferBanner onClose={() => setShowBanner(false)} />}
      <nav
        className={`navbar ${navbarState.isVisible ? "visible" : "hidden"} 
                      ${navbarState.isShadowVisible ? "bottomShadow" : ""} 
                      ${!showBanner ? "without-banner" : ""}`}
      >
        <div className="container main-navbar">
          <Link to="/" className="logo" onClick={handleHomeClick}>
            <img src="../images/logo.png" alt="Logo" />
          </Link>
          <div className="nav-items">
            <Link
              to={isLoggedIn ? "/dashboard" : "/"}
              className={
                location.pathname === (isLoggedIn ? "/dashboard" : "/")
                  ? "active"
                  : ""
              }
              onClick={handleHomeClick}
            >
              Home
            </Link>
            <Link
              to="/mutualFunds"
              className={location.pathname === "/mutualFunds" ? "active" : ""}
            >
              Mutual Funds
            </Link>
            <Link
              to="/angel-investments"
              className={
                location.pathname === "/angel-investments" ? "active" : ""
              }
            >
              Angel Investments
            </Link>
            <Link
              to="/tax-services"
              className={location.pathname === "/tax-services" ? "active" : ""}
            >
              Tax Services
            </Link>
            <button
              onClick={() => handleNavbarClick("company")}
              className={navbarState.selectedNav === "company" ? "active" : ""}
            >
              Company
            </button>
            {isLoggedIn ? (
              <div className="user-menu">
                <img
                  src={imageUrl || userImg}
                  alt="Profile"
                  className="profile-image"
                />
                <div className="dropdown-content">
                  <Link to="/my-account">My Account</Link>
                  <Link to="/portfolio">My Portfolio</Link>
                  <button onClick={handleLogout}>Logout</button>
                </div>
              </div>
            ) : (
              <button
                style={{
                  background: "#375042",
                  border: "none",
                  borderRadius: ".25rem",
                  color: "#fff ",
                  fontSize: "1.2rem",
                  fontWeight: "700",
                  padding: ".5rem 1rem",
                  transition: "all .3s ease",
                }}
                className="getStarted"
                onClick={() => handleModalToggle("onboarding")}
              >
                Get Started
              </button>
            )}
          </div>
          <button className="hamburger" onClick={handleHamburgerClick}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        {navbarState.selectedNav === "company" && (
          <div className="sub-navbar">
            <Link
              to="/blogs"
              className={location.pathname === "/blogs" ? "active" : ""}
            >
              Blog
            </Link>
            <Link
              to="/successStories"
              className={
                location.pathname === "/successStories" ? "active" : ""
              }
            >
              Success Stories
            </Link>
            {/* <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSehgNLVLB4i7j9kr9pRMdWMdMuX30OfTq2HSrpadVZJt0Wkdg/viewform"
              className={location.pathname === "/nri-tax" ? "active" : ""}
            >
              NRI Tax
            </Link> */}
            <Link
              to="/about-us"
              className={location.pathname === "/about-us" ? "active" : ""}
            >
              About Us
            </Link>
            <Link
              to="/calculators"
              className={location.pathname === "/calculators" ? "active" : ""}
            >
              Calculator
            </Link>
          </div>
        )}
      </nav>

      {navbarState.isOpen && (
        <div className="mobile-menu open">
          <button className="close-menu" onClick={handleHamburgerClick}>
            ×
          </button>
          <ul>
            <li>
              <Link
                to={isLoggedIn ? "/dashboard" : "/"}
                onClick={() => {
                  handleHomeClick();
                  handleHamburgerClick();
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link to="/mutualFunds" onClick={handleHamburgerClick}>
                Mutual Funds
              </Link>
            </li>
            <li>
              <Link to="/angel-investments" onClick={handleHamburgerClick}>
                Investments
              </Link>
            </li>
            <li>
              <Link to="/tax-services" onClick={handleHamburgerClick}>
                Tax Services
              </Link>
            </li>
            <li>
              <span>Company</span>
              <ul>
                <li>
                  <Link to="/blogs" onClick={handleHamburgerClick}>
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="/successStories" onClick={handleHamburgerClick}>
                    Success Stories
                  </Link>
                </li>
                <li>
                  <Link to="/nri-tax" onClick={handleHamburgerClick}>
                    NRI Tax
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" onClick={handleHamburgerClick}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/calculators" onClick={handleHamburgerClick}>
                    Calculator
                  </Link>
                </li>
              </ul>
            </li>
            {isLoggedIn ? (
              <>
                <li>
                  <Link to="/my-account" onClick={handleHamburgerClick}>
                    My Account
                  </Link>
                </li>
                <li>
                  <Link to="/portfolio" onClick={handleHamburgerClick}>
                    My Portfolio
                  </Link>
                </li>
                <li>
                  <button
                    onClick={() => {
                      handleLogout();
                      handleHamburgerClick();
                    }}
                  >
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <li>
                <button
                  className="getStarted"
                  onClick={() => {
                    handleModalToggle("onboarding");
                    handleHamburgerClick();
                  }}
                >
                  Get Started
                </button>
              </li>
            )}
          </ul>
        </div>
      )}

      <AuthModal
        show={showModal}
        onHide={() => setShowModal(false)}
        mode={authMode}
        onSignInSuccess={handleSignInSuccess}
      />
    </div>
  );
};

export default Navbar;
